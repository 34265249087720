import { Grid, Box, Typography, TextField, FormControl, Radio, OutlinedInput, InputLabel, RadioGroup, InputAdornment, Button } from '@mui/material'
import logoImage from "../../../images/eastwestLogo.svg"
import { useState } from 'react';
import Footer from '../footer';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../../HttpRequest';
import { setResetMethod, setResetChannel } from '../../../features/forgotPassword';
import { useDispatch } from 'react-redux';
import AuthHeader from './header';


const ForgotPassword = () => {
    const [selectedValue, setSelectedValue] = useState('');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const dispatch = useDispatch();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'forgot-password-options',
        inputProps: { 'aria-label': item },
    });
    const validateEmail = (email) => {
        let newEMail = email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(newEMail);
    };

    const handleSubmit = () => {
        let isValid = true;

        if (selectedValue === 'email') {
            if (email === "") {
                setErrorShow({ state: true, message: "Email is required!" });
                isValid = false;
                return;
            }
            if (!validateEmail) {
                setErrorShow({ state: true, message: "Please enter a valid email!" });
                isValid = false;
                return;
            }
        }
        if (selectedValue === 'phone') {
            if (phone === "") {
                setErrorShow({ state: true, message: "Phone Number is required!" });
                isValid = false;
                return;
            }
        }
        if (isValid) {
            let payload = {
                phoneNumber: "+254" + phone
            }
            if (selectedValue === 'email') {
                payload = { userEmail: email }
            }
            HttpRequest({
                method: 'POST',
                url: '/posForgotPinVersion2',
                body: payload
            }).then((response) => {
                if (response.response.Status === "SUCCESS") {
                    dispatch(setResetMethod(selectedValue));
                    dispatch(setResetChannel(selectedValue === 'email' ? email : phone));
                    setSuccessShow({ state: true, message: `Password reset successful. Please check your ${selectedValue}.` });
                    setTimeout(() => {
                        navigate('/password/reset')
                    }, 3000);
                } else {
                    const message = response?.response?.message;
                    setErrorShow({ state: true, message: message })
                }

            }).catch((error) => {
                setErrorShow({ state: true, message: 'Password reset failed. Please try again!' });
            })
        } else {
            setErrorShow({ state: true, message: 'Password reset failed. Please try again!' });
        }

    }

    return (
        <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 64px - 64px)' }}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <AuthHeader heading={'Forgot Password?'} text={'Select an option to Reset your Password. We will send a security code to the email address/Phone Number, please make sure it is correct.'} />
                <Box sx={{ maxWidth: "350px", marginLeft: 'auto', marginRight: "auto", }} component={'div'} display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Grid container spacing={2}  >
                        <Grid item lg={12}>
                            <FormControl>
                                <RadioGroup row aria-labelledby="forgot-password-options-label" defaultValue="email" name="forgot-password-options" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Radio value="phone"   {...controlProps('phone')} label="Phone Number" sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />
                                    <Typography sx={{ margin: '2px 0 2px 4px', fontSize: '14px', textAlign: 'left', color:headingsColor }}>Phone Number</Typography>
                                    <Radio value="email"   {...controlProps('email')} label="Email Address" sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />
                                    <Typography sx={{ margin: '2px 0 2px 4px', fontSize: '14px', textAlign: 'left', color: headingsColor }}>Email Address</Typography>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {selectedValue === 'email' && <FormControl>
                        <TextField value={email} onChange={(e) => setEmail(e.target.value)} required id='email' label='Email Address' sx={{ width: '350px', height: '48px', margin: '0px 0 10px', borderRadius: '5px', border: '1px #bfbfbf', backgroundColor: '#fff' }} inputProps={{ style: { color: headingsColor, fontSize: '12px', fontWeight: 'normal', textAlign: 'left' } }} InputLabelProps={{ style: { color: headingsColor, fontSize: '14px', fontWeight: 'normal', textAlign: 'left' } }} />
                    </FormControl>}
                    {selectedValue === 'phone' && <FormControl sx={{ width: '350px', height: '55px', margin: '0px 0 10px 18px', borderRadius: '5px', border: 'solid 1px #bfbfbf', backgroundColor: '#fff', borderRadius: '5px', border: 'solid 1px #bfbfbf', backgroundColor: '#fff' }}>
                        <InputLabel htmlFor="phone">Phone Number</InputLabel>
                        <OutlinedInput value={phone} onChange={(e) => setPhone(e.target.value)}
                            id="phone"
                            startAdornment={<InputAdornment position="start">+254</InputAdornment>}
                            label="Phone Number"
                        />
                    </FormControl>}
                    {selectedValue !== '' && <Box>
                        <Button onClick={() => handleSubmit()} variant='contained' sx={{ width: '313px', height: '50px', margin: '31px 18px 14px 19px', padding: '15px 13px 15px 18px', objectFit: 'contain', borderRadius: '5px', backgroundColor: primaryColor, fontSize: '14px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: primaryColor } }}>Request Code</Button>
                    </Box>}
                    <Box sx={{ display: 'flex', mt: '20px',cursor:'pointer' }} onClick={() => navigate('/login')}>
                        <ArrowBack sx={{ color: bodyColor }} />
                        <Typography sx={{ margin: '2px 0 2px 10px', fontSize: '14px', fontWeight: 'normal', color: bodyColor, cursor: 'pointer' }}>Back to Login</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}


export default ForgotPassword;