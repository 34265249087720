import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomSearchInput from '../common/CustomSearchInput';
import CustomSelect from '../common/customSelectInput';
import { HttpRequest } from '../HttpRequest';
import imagePlaceholder from "../../images/img-holder-icn.svg";
import editIcon from '../../images/edit-icn.svg';
import deleteIcon from '../../images/delete-icn.svg';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import CurrencyFormatter from '../common/currencyFormatter';
import { ContentCopy } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {setMaxNoOfItems, setProductIsPackage, setAdditionalImages, setAdditionalServicesData, setFeaturedImage, setPricingData, setProductCategory, setProductDescription, setProductName, setProductTypeData, setTagsData, setVariations, setThumbnailImage } from '../../features/productSlice';
import { SuccessAlert } from '../common/snackbar/success';
import { ErrorAlert } from '../common/snackbar/error';
import secureLocalStorage from 'react-secure-storage';

const status = [
  { id: 1, label: 'All', value: 'all' },
  { id: 2, label: 'In Stock', value: 'in_stock' },
  { id: 3, label: 'Running Low', value: 'running_low' },
  { id: 4, label: 'Preorder', value: 'preorder' },
  { id: 5, label: 'Out of Stock', value: 'out_of_stock' }
];

const renderCategoryColumns = (row) => {
  return (
    <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
      {row.productCategory}
    </Box>
  );
};

const Products = () => {
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const handleOpenDeleteCategory = () => { setOpenDeleteCategory(true); };
  const handleCloseDeleteCategory = () => { setOpenDeleteCategory(false); };
  const navigate = useNavigate();
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
  const businessNumber = secureLocalStorage.getItem("businessId");
  const [searchCategory, setSearchCategory] = useState('');
  const dispatch = useDispatch();
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })


  const columns = [
    {
      field: 'index', headerName: 'Index', flex: 0,
      renderHeader: () => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> No. </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params?.row?.index}
        </Box>
      )
    },
    {
      field: 'productName', headerName: 'Product Name', flex: 2,
      renderHeader: () => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Product Name </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", width: '100%', display: 'flex', alignItems: 'center', gap: 1, py: 2 }}
          onClick={() => navigate(`/zed_ecommerce/products/${params.row._id}/edit`)}
        >
          <Box component={'img'} src={params.row.featuredImage || imagePlaceholder} sx={{ width: '30px', height: '30px', objectFit: 'contain' }} />
          <Typography>{params.row.productName}</Typography>
        </Box>
      )
    },
    {
      field: 'price', headerName: 'Price', flex: 0,
      renderHeader: () => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Price </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}
          onClick={() => navigate(`/zed_ecommerce/products/${params.row._id}/edit`)}
        >
          {CurrencyFormatter(params.row.pricing[0]?.discountedPrice ?? params.row.productPrice, 'KES')}
        </Box>
      )
    },
    {
      field: 'categories', headerName: 'Categories', flex: 1,
      renderHeader: () => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Categories </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/zed_ecommerce/products/${params.row._id}/edit`)}>
          {renderCategoryColumns(params.row)}
        </Box>
      )
    },
    {
      field: 'tags', headerName: 'Tags', flex: 2,
      renderHeader: () => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Tags </Box>
      ),
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }} onClick={() => navigate(`/zed_ecommerce/products/${params.row._id}/edit`)}>
          {params.row.tags.map(tag => tag.tagName).join(', ')}
        </Box>
      )
    },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderHeader: () => (
        <Box sx={{ color: "#032541", fontSize: "17px", fontWeight: "700", py: 2 }}>
          Actions
        </Box>
      ),
      renderCell: (params) => (
        <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
          <Button onClick={(event) => { event.stopPropagation();clearExistingProductDetails(); navigate(`/zed_ecommerce/products/${params.row._id}/edit`); }} style={{ textDecoration: 'none' }}>
            <img src={editIcon} alt='' style={{ width: 20, height: 20, margin: ' 0 21px 0 0', objectFit: 'contain' }} />
          </Button>
          <Button onClick={() => handleCopy(params?.row?._id) } style={{ textDecoration: 'none' }}>
            <ContentCopy />
          </Button>
          <Button onClick={(event) => { event.stopPropagation(); handleOpenDeleteCategory(); }} style={{ textDecoration: 'none' }}>
            <img src={deleteIcon} alt='' style={{ width: 20, height: 20, margin: ' 0 21px 0 0', objectFit: 'contain' }} />
          </Button>
        </Box>
      )
    }
  ];
  const clearExistingProductDetails = () => {
    dispatch(setProductTypeData(''));
    dispatch(setProductCategory(''));
    dispatch(setProductName(''));
    dispatch(setVariations([]));
    dispatch(setTagsData([]));
    dispatch(setProductDescription())
    dispatch(setAdditionalServicesData([]));
    dispatch(setFeaturedImage(''));
    dispatch(setAdditionalImages([]));
    dispatch(setProductIsPackage(false));
    dispatch(setMaxNoOfItems(0));
}
  const handleCopy = (productId) => {
    fetchProductDetails(productId);
  }
  const fetchProductDetails = (productId) => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: "GET",
      url: `/api/v1/ecommerce/get_product/${productId}?businessNumber=${businessNumber}`,
    }).then((data) => {
      if (data.status === 200) {
        const productDetails = data?.response?.data;
        dispatch(setProductTypeData(productDetails?.productType));
        dispatch(setProductName(productDetails?.productName));
        dispatch(setProductCategory(productDetails?.productCategory));
        dispatch(setProductDescription(productDetails?.productDescription));
        dispatch(setVariations(productDetails?.variations));
        dispatch(setFeaturedImage(productDetails?.featuredImage));
        dispatch(setThumbnailImage(productDetails?.thumbnail));
        dispatch(setAdditionalImages(productDetails?.additionalImages));
        dispatch(setPricingData(productDetails?.pricing));
        dispatch(setTagsData(productDetails?.tags));
        dispatch(setAdditionalServicesData(productDetails?.additionalServices));
        navigate('/zed_ecommerce/addProducts');
      } else {
        setErrorShow({ state: true, message:'Error duplicating the product' });
      }
    }).catch((error) => {
    });
  };
  const getCategories = () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}`
    }).then((data) => {
      const newdata = data.response.data.map((item) => ({
        label: item.categoryName,
        value: item.categoryName,
      }));
      setCategories(newdata);
    }).catch((error) => {
    });
  };

  const getProducts = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&page=${pageState.page}&limit=${dataGridPageSize}&search=${search.trim()}&categoryName=${searchCategory.trim()}`
    }).then((data) => {
      if (data.status === 200) {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          total: data.response.count,
          data: data.response.data.map((row, index) => ({
            ...row,
            index: index + 1 + (pageState.page - 1) * dataGridPageSize
          }))
        }));
      }
    }).catch((error) => {
      setPageState((old) => ({ ...old, isLoading: false }));
    });
  };

  useEffect(() => {
    getCategories();
    getProducts();
  }, [dataGridPageSize, pageState.page, businessNumber, search, searchCategory]);

  return (
    <div>
      <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ flexDirection: "column" }}>
            <Typography variant='h4' sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>Products</Typography>
            <Typography variant='h4' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>View and manage your product categories.</Typography>
          </Box>
          <Box style={{ marginRight: "15px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", width: "125px", height: "40px", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => {clearExistingProductDetails();navigate('/zed_ecommerce/addProducts')}}>Add Product</Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex',alignItems:'center' }}>
          <Box sx={{ marginLeft: "10px" }}>
            <CustomSearchInput placeholder={"Search ...."} name="search" value={search} onInput={(e) => setSearch(e.target.value)} />
          </Box>
          <Box sx={{ marginLeft: "10px",mt:'20px' }}>
            <CustomSelect value={searchCategory} options={categories} placeholder={"All Categories"} sm={"xl"} onChange={(e) => setSearchCategory(e.target.value)} />
          </Box>
        </Box>
        <Box>
          <DataGrid
            sx={{ minHeight: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
            rowCount={pageState.total}
            pagination
            loading={pageState.isLoading}
            page={pageState.page - 1}
            pageSizeOptions={[1, 10, 20, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: dataGridPageSize,
                  page: pageState.page - 1,
                },
              },
            }}
            pageSize={dataGridPageSize}
            onPaginationModelChange={(data) => {
              const { pageSize, page } = data;
              setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
              setPageSize(pageSize);
            }}
            paginationMode="server"
            columns={columns}
            rows={pageState.data}
            getRowId={(row) => row._id}
          />
        </Box>
      </div>
    </div>
  );
};

export default Products;
