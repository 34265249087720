import { Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, Modal, Paper, Radio, RadioGroup, Typography, useMediaQuery } from "@mui/material"
import { HttpRequest } from "../HttpRequest";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import mpesaIcon from "../../images/mpesa.png";
import cardIcon from "../../images/card.png";
import VoucherImage from "../../images/product-main-img.png"
import { currencyconverter } from "../../utils/dateFormatter";
import DeleteIcon from '@mui/icons-material/Delete';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../common/snackbar/error";
import { SuccessAlert } from "../common/snackbar/success";
import loadingIcon from "../../images/loading.svg";
import { useTheme } from '@mui/material/styles';


const base_url = process.env.REACT_APP_WEB_SOCKET_URL;


const orderProcessingModal = {
    borderRadius: "12px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "260px", height: "176px", p: { xs: '49px 50px 80px', sm: '49px 50px 80px', md: '91px 101px 90px', lg: '91px 101px 90px' }, border: '1px #707070', m: { lg: '36px 227px 7px 51px' }, boxShadow: ' 3px 0 20px 0 rgba(0, 0, 0, 0.16)', textAlign: 'center'
}

const EvoucherCheckout = ({ voucherData, setCheckout, setVoucherData }) => {
    const [mpesaStatus, setMpesaStatus] = useState(false);
    const [payCardStatus, setPayCardStatus] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [mpesaPhoneNumberError, setMpesaPhoneNumberError] = useState(false);
    const [mobileMoneyNumberError, setMobileMoneyNumberError] = useState(false);
    const [bankPaybillStatus, setBankPaybillStatus] = useState(false);
    const [mobileMoneyNumber, setMobileMoneyNumber] = useState('');
    const [mpesaPhoneNumber, setMpesaPhoneNumber] = useState('');
    const [buttonStatus, setButtonStatus] = useState(true);
    const [openOrderProcessing, setOpenOrderProcessing] = useState(false);
    const handleOpenOrderProcessing = () => { setOpenOrderProcessing(true) }
    const [orderCreated, setOrderCreated] = useState(false);
    const [orderCreatedDetails, setOrderCreatedDetails] = useState({});
    const theme = useTheme();
    const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [startDate, setStartDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("");

    const navigate = useNavigate();

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const checkPaymentStatus = async () => {
        await HttpRequest({
            method: 'GET',
            url: `/api/get_payment_methods_with_status`
        }).then((data) => {
            let banks = data?.response?.data?.find(method => method?.name === "Banks") ?? []
            let kcbBanks = banks?.paymentOptions?.find(method => method?.name === "KCB") ?? []
            const mpesaStatus = data.response?.data?.find(method => method.name === "Mpesa")?.status ?? false;
            const cardStatus = data.response?.data.find(method => method.name === "Card")?.status ?? false;
            const paybillStatus = kcbBanks?.kcb?.find(method => method?.name === "Mobile Money")?.status ?? false

            setMpesaStatus(mpesaStatus)
            setBankPaybillStatus(paybillStatus)
            setPayCardStatus(cardStatus)
        }).catch((error) => {
        });
    }
    useEffect(() => {
        checkPaymentStatus();
    }, [])

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const getCustomerProfile = () => {
        let customerId = secureLocalStorage.getItem("customerId") ?? secureLocalStorage.getItem("userId")
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${customerId}`
        }).then((data) => {
            let customerData = data?.response?.data;
            setFirstName(customerData?.firstName);
            setLastName(customerData?.lastName);
            setEmail(customerData?.email);
            setPhone(customerData?.phone);

        }).catch((error) => {
        });
    }
    useEffect(() => {
        getCustomerProfile();
    }, [])

    const validateField = (field, setError, isValidFunc) => {
        const isValid = field !== '' && isValidFunc(field);
        setError(!isValid);
        return isValid;
    };

    const isValidMpesaPhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^254\d{9}$/;
        return phoneNumberRegex.test(phoneNumber);
    };

    const deleteVoucher = () => {
        setCheckout(false);
        setVoucherData(null);
    }

    const validateForm = () => {
        const validationResults = {
            paymentMethod: validateField(paymentMethod, setPaymentMethodError, () => true),
            mpesaPhoneNumber: paymentMethod === 'mpesa' ? validateField(mpesaPhoneNumber, setMpesaPhoneNumberError, isValidMpesaPhoneNumber) : true,
            mobileMoneyNumber: paymentMethod === 'kcbpaybill' ? validateField(mobileMoneyNumber, setMobileMoneyNumberError, () => true) : true
        };
        const isFormValid = Object.values(validationResults).every(result => result);
        return isFormValid;
    };

    const fetchBusinessInfo = async () => {
        HttpRequest({
            method: 'POST',
            url: `/api/get_business_info`
        }).then((data) => {
            console.log(data,'data==');
            let voucherData = data?.response?.data;
            setStartDate(voucherData?.voucherStartDate);
            setExpiryDate(voucherData?.voucherExpiryDate);
        }).catch((error) => {
        });
    }

    useEffect(() => {
        fetchBusinessInfo();
    }, []);

    const voucherPayload = {
        expiryDate: expiryDate,
        phoneNumber: mpesaPhoneNumber || mobileMoneyNumber,
        recipients: [
            {
                amount: voucherData?.selectedVoucher?.voucherAmount,
                email: email,
                name: firstName + ' ' + lastName,
                phone: phone,
            }
        ],
       customerId: secureLocalStorage.getItem('customerId'),
       startDate: startDate,
       campaignId:voucherData?.voucherDetails?._id
    }

    const payButtonStatus = () => {
        setButtonStatus(true);
        if (voucherPayload?.campaignId !== '' && voucherPayload.expiryDate !== '' && paymentMethod !== '' && (mpesaPhoneNumber !== null || mobileMoneyNumber !== null)) {
            setButtonStatus(false);
        }
    };
    useEffect(() => {
        payButtonStatus();
    }, [voucherPayload?.expiryDate, paymentMethod, mpesaPhoneNumber, mobileMoneyNumber]);
    const handleSubmit = () => {
        const customerId = secureLocalStorage.getItem('customerId');
        if (!customerId) {
            navigate('/login');
        }
        let payload = voucherPayload
        const isValid = validateForm();

        if (isValid) {
            if (orderCreated) {
                sendStkPush(orderCreatedDetails);
                setOpenOrderProcessing(true);
            } else {
                HttpRequest({
                    method: 'POST',
                    url: `/api/v1/evoucher/createEvoucher`,
                    body: payload
                }).then((data) => {
                    if (data?.status === 200) {
                        setOpenOrderProcessing(true);
                        setOrderCreated(true);
                        setOrderCreatedDetails(data?.response?.data)
                        sendStkPush(data?.response?.data);
                    } else {
                        setErrorShow({ state: true, message: 'Failed to purchase voucher. Please try again.' });
                    }
                }).catch((error) => {
                    setErrorShow({ state: true, message: error?.message });
                });
            }

        }
    };

    const sendStkPush = (orderDetails) => {
        const payload = {
            amount: orderDetails.amount,
            orderID: orderDetails.batchNumber,
            businessID: secureLocalStorage.getItem('businessId'),
            type: "evoucher",
            phone: paymentMethod === 'mpesa' ? mpesaPhoneNumber : mobileMoneyNumber,
            paymentChanel: 'Web',
        }

        HttpRequest({
            method: 'POST',
            url: paymentMethod === 'mpesa' ? `/api/pushstk` : paymentMethod === 'kcbpaybill' ? `/api/v1/payments/initiate_kcb_stk_push` : paymentMethod === 'card' ? `/api/v1/payments/card/pay` : '',
            body: payload
        }).then((data) => {
            if (data.status === 200) {
                console.log(data, 'data to listen')
                let orderId
                const paymentLink = data?.response?.data?.data?.invoiceInformation?.paymentLink;
                if (paymentMethod === 'mpesa' || paymentMethod === 'kcbpaybill') {
                    orderId = data?.response.data?.requestReferenceId;
                }
                if (paymentMethod === 'card') {
                    orderId = data?.response?.data?.data.invoiceInformation?.invoiceNumber;
                }
                if (paymentLink) {
                    const cacheBustedLink = `${paymentLink}?t=${new Date().getTime()}`;
                    setTimeout(() => {
                        window.open(cacheBustedLink);
                    }, 3000);

                }

                const socket = new WebSocket(base_url + '/notification/' + orderId);
                const fallback = setTimeout(() => {
                    setErrorShow({ state: true, message: 'Payment notification timed out.' });
                    socket.close();
                }, 10000);

                socket.addEventListener('message', function (event) {

                    const data = JSON.parse(event.data);
                    clearTimeout(fallback);
                    if (data?.status === "OK") {
                        setSuccessShow({ state: true, message: data?.message });
                    } else if (data?.status === "SUCCESS") {
                        setOpenOrderProcessing(false);
                        setSuccessShow({ state: true, message: data?.statusMessage });
                        setTimeout(() => {
                            setCheckout(false);
                            setVoucherData(null)
                            navigate(`/evouchers`)
                        }, 3000);
                    } else {
                        setErrorShow({ state: true, message: data.statusMessage });
                        socket.close();
                        setOpenOrderProcessing(false);
                    }
                    // clearTimeout();
                });
            } else {
                setErrorShow({ state: true, message: 'Failed to process voucher payments.' });
            }
        }).catch((error) => {

        });
    }
    return (
        <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item xs={12} display={'flex'} flexDirection={'column'} width={'100%'}>
                <Typography sx={{ margin: '21px 121px 16px 0px', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: headingsColor }}>
                    Evoucher purchase summary
                </Typography>
            </Grid>
            <Grid item xs={12} width={'100%'} display={'flex'} flexDirection={'column'}>
                {voucherData &&
                    <Paper sx={{ width: '100%', display: "flex", py: 3, flexDirection:"row", flex:1 }}>
                        <Box component={'div'} sx={{ display: 'flex', flex:1, flexDirection: 'row', alignItems: 'center ', gap:'10px', justifyContent: 'space-between', width: '100%', pr:'10px' }}>
                            <Box component={'div'} sx={{display: 'flex', alignItems: 'center', width: '100%' , height:'100%'}} flexDirection={'column'}>
                                <Box component={'img'} src={VoucherImage} sx={{ width: '100%', height: '100px',objectFit: 'contain' }} />
                            </Box>
                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: headingsColor }}>
                                    Evoucher
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: headingsColor }}>
                                    {currencyconverter(voucherData?.selectedVoucher?.voucherAmount)}
                                </Typography>
                            </Box>
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: "row", justifyContent: 'flex-end', gap: 5 }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#000000' }}>
                                    Name
                                </Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#707070' }}>
                                    {firstName} {lastName}
                                </Typography>
                            </Box>
                            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', cursor: "pointer", width: '100%',}}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: headingsColor }}>
                                    <DeleteIcon onClick={deleteVoucher} sx={{ fontSize: '24px', color: '#ef0b0a' }} />
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>}
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={12} lg={6} xl={6} md={12}>
                    <Grid item xs={12}>
                        <Typography sx={{ margin: '21px 121px 16px 0px', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: headingsColor }}>
                            Payment  Method
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: mpesaStatus === true ? 'block' : 'none' }}>
                        <FormControl fullWidth>
                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                <Box display={{ display: 'flex', }}>
                                    <FormControlLabel value="mpesa" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: primaryColor, '&.Mui-checked': { color: primaryColor, } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: bodyColor, fontSize: '14px' }} />
                                    <img src={mpesaIcon} alt="" style={{ width: '63px', height: '23px', margin: '16px 306px 16px 15px', objectFit: 'contain' }} />
                                </Box>
                            </RadioGroup>
                            {paymentMethod === 'mpesa' && <Typography sx={{ margin: '10px 0 0 15px', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: bodyColor, textWrap: 'wrap' }}>You will be redirected to Mpesa to complete the payment.</Typography>}

                            {paymentMethod === 'mpesa' && <FormHelperText sx={{ color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                        </FormControl>
                        {paymentMethod === 'mpesa' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '270px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                            <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mpesaPhoneNumberError ? 'solid 1px red' : `solid 1px ${bodyColor}`, borderRadius: '5px' }} value={mpesaPhoneNumber} onChange={mpesaPhoneNumber => { setMpesaPhoneNumber(mpesaPhoneNumber) }} />
                            <FormHelperText sx={{ color: 'red', display: mpesaPhoneNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 15px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: bankPaybillStatus === true ? 'block' : 'none' }}>
                        <FormControl fullWidth>
                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                <Box display={{ display: 'flex', }}>
                                    <FormControlLabel value="kcbpaybill" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: primaryColor, '&.Mui-checked': { color: primaryColor, } }} />} label='Mobile Money' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: bodyColor, fontSize: '14px' }} />
                                </Box>
                            </RadioGroup>
                            {paymentMethod === 'kcbpaybill' && <Typography sx={{ margin: '10px 0 0 15px', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: bodyColor, textWrap: 'wrap' }}>STK push will be sent to your phone. Kindly input your M-Pesa pin.</Typography>}
                            {paymentMethod === 'kcbpaybill' && <FormHelperText sx={{ color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                        </FormControl>
                        {paymentMethod === 'kcbpaybill' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '300px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                            <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mobileMoneyNumberError ? 'solid 1px red' : `solid 1px ${bodyColor}`, borderRadius: '5px' }} value={mobileMoneyNumber} onChange={mobileMoneyNumber => { setMobileMoneyNumber(mobileMoneyNumber) }} />
                            <FormHelperText sx={{ color: 'red', display: mobileMoneyNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 15px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: payCardStatus === true ? 'block' : 'none' }}>
                        <FormControl fullWidth>
                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
                                    <FormControlLabel value="card" control={<Radio sx={{ color: primaryColor, '&.Mui-checked': { color: primaryColor } }} />} sx={{ margin: '0', color: bodyColor, fontSize: '14px', }} />
                                    <img src={cardIcon} alt="Card Icon" style={{ width: '40px', height: '40px', objectFit: 'contain', margin: '0 8px 0 10px' }} />
                                    <Typography sx={{ fontSize: '16px', color: bodyColor, whiteSpace: 'nowrap' }}>
                                        Card
                                    </Typography>
                                </Box>
                            </RadioGroup>
                            {paymentMethod === 'card' && <Typography sx={{ margin: '10px 0 0 15px', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: bodyColor, textWrap: 'wrap' }}>You will be redirected to a payment link.</Typography>}
                            {paymentMethod === 'card' && <FormHelperText sx={{ color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'end' }}>
                        <Button  variant='outlined' sx={{ justifyContent: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', padding: '15px 16.9px 15px 15px', objectFit: 'contain', borderRadius: '5px', border: `solid 1px ${bodyColor}`, color: bodyColor, '&:hover': { border: `solid 1px ${bodyColor}`, textAlign: 'center' } }}>
                            <Typography sx={{ textWrap: 'nowrap', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: bodyColor }}>Back</Typography>
                        </Button>
                        <Button onClick={()=>handleSubmit()}  variant='contained' sx={{ justifyContent: 'center', textAlign: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', margin: '0 0 0 20px', padding: '15px 16.9px 15px 15px', objectFit: 'contain', opacity: buttonStatus === true ? 0.5 : 1, borderRadius: '5px', backgroundColor: primaryColor, '&:hover': { backgroundColor: primaryColor } }}  disabled={buttonStatus}>
                            <Typography  sx={{ textWrap: 'nowrap', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Pay Now</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={6} md={12}>
                    <Paper sx={{ display:'flex', width: '100%', marginTop: 8, ml:isMuiMobile ? 2:2, height: '100px', py: 4, }}>
                        <Box component={'div'} sx={{ width: '100%' , display:'flex' , flexDirection:'column' , px:2 }}>
                            <Typography sx={{ borderBottom: "2px dotted  #bfbfbf" }}>
                                Total
                            </Typography>
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#707070' }}>Subtotal</Typography>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#000000' }}>  {currencyconverter(voucherData?.selectedVoucher?.voucherAmount)}</Typography>
                            </Box>
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#707070' }}>Total (tax incl.)</Typography>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#02b3ad' }}>  {currencyconverter(voucherData?.selectedVoucher?.voucherAmount)}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Modal open={openOrderProcessing} onClose={handleOpenOrderProcessing} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={orderProcessingModal}>
                    <img src={loadingIcon} alt=" " style={{ width: '100%', maxWidth: '125px', height: '100%', maxHeight: '125px', margin: '0 33px 9px 32px', objectFit: 'contain', animation: 'App-logo-spin 2s linear infinite', }} />
                    <Typography sx={{ width: '190px', height: '41px', margin: '9px 33px 100px', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: headingsColor }}>
                        {paymentMethod === 'card' ? 'You will be redirected to a payment link.' : 'Please check your phone for STK prompt.'}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    )
}

export default EvoucherCheckout