import { Grid, Box, Typography, TextField, FormControl, styled, Button, InputAdornment, IconButton, InputLabel, OutlinedInput, Checkbox, FormHelperText } from '@mui/material'
import logoImage from "../../../images/eastwestLogo.svg"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import facebookIcon from '../../../images/facebook-icn.svg';
import googleIcon from '../../../images/google-icn.svg';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../footer';
import PhoneInput from 'react-phone-input-2';
import { invalid } from 'moment';
import { HttpRequest } from '../../HttpRequest';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import { useDispatch, useSelector } from 'react-redux';
import { logInUser } from '../../../features/userSlice';
import secureLocalStorage from 'react-secure-storage';
import AuthHeader from './header';

const label = { inputProps: { 'aria-label': 'Checkbox Terms & Conditions' } };

const Register = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const [phoneNoError, setPhoneNoError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const stateCart = useSelector((state) => state.cart);
    let cart = stateCart?.cart;


    const handleClickShowPassword = () => {
        setShowPassword(true);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignupSubmit = () => {
        let isValid = true;
        setLoading(true);
        setPhoneNoError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setPasswordError(false);
        setConfirmPasswordError(false);
        setUsernameError(false);

        if (phoneNo === "") {
            setPhoneNoError(true);
            isValid = false;
        }
        if (firstName === "") {
            setFirstNameError(true);
            isValid = false;
        }
        if (lastName === "") {
            setLastNameError(true);
            isValid = false;
        }
        if (email === "") {
            setEmailError(true);
            isValid = false;
        }
        if (!validateEmail(email)) {
            setEmailError(true);
            isValid = false;
        }
        if (password === "") {
            setPasswordError(true);
            isValid = false;
            setPasswordErrorMessage("Password is required")
        }
        if (password.length < 5) {
            setPasswordError(true);
            isValid = false;
            setPasswordErrorMessage("Password must be at least 5 characters.")
        }
        if (confirmPassword === "") {
            setConfirmPasswordError(true);
            isValid = false;
            setConfirmPasswordErrorMessage("Confirm password is required")
        }
        if (password !== confirmPassword) {
            setPasswordError(true);
            setConfirmPasswordError(true);
            isValid = false;
            setPasswordErrorMessage("Passwords do not match.");
            setConfirmPasswordErrorMessage("Passwords do not match");
        }

        let businessId = secureLocalStorage.getItem("businessId");
        if (!businessId) {
            setErrorShow({ state: true, message: 'Registration failed. Please try again.' });
        } else {
            if (isValid) {
                const username = firstName + lastName;
                const payload = {
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email.trim(),
                    "phone": phoneNo,
                    "userName": username,
                    "password": password,
                    "businessId": businessId
                }
                HttpRequest({
                    method: 'POST',
                    url: `/api/v1/ecommerce/zedcustomeradd`,
                    body: payload
                }).then((data) => {
                    if (data.response.Status === "Success") {
                        setSuccessShow({ state: true, message: 'Registration Successful.' });
        
                        let responseData = data?.response?.data?.data;
                        secureLocalStorage.setItem("token", data?.response?.token);
                        secureLocalStorage.setItem("group", "Customer");
                        secureLocalStorage.setItem("zedAccountingId", responseData?.zedAccountingId);
                        secureLocalStorage.setItem("email", responseData?.email);
                        secureLocalStorage.setItem("state", responseData?.state);
                       
                        secureLocalStorage.setItem("username", responseData?.lastName);
                        secureLocalStorage.setItem("businessName", responseData?.businessName);
                      
                        secureLocalStorage.setItem("userId", responseData?.userId);
                        secureLocalStorage.setItem("BranchID", responseData?.branchId);
                        secureLocalStorage.setItem("customerId", responseData?._id);
                        secureLocalStorage.setItem("businessCategory", responseData?.businessCategory);
                        secureLocalStorage.setItem('localCurrency', responseData?.localCurrency)
                        secureLocalStorage.setItem('customerClassification', responseData?.customerClassification)
                        secureLocalStorage.setItem("affiliatedPartner", responseData?.partnerName)
                        dispatch(logInUser({ isLoggedIn: true }));
                        setTimeout(() => {
                            if (cart?.length > 0) {
                                navigate('/checkout')
                            } else {
                                navigate('/')
                            }
                        }, 3000);

                    } else {
                        const message = data?.response?.message
                        setErrorShow({ state: true, message: message });
                    }
                }).catch((error) => {
                    setErrorShow({ state: true, message: 'Registration failed. Please try again.' });
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    }
    const validateEmail = (email) => {
        let newEMail = email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(newEMail);
    };
    return (
        <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 64px - 64px)' }} row>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <AuthHeader heading={'Register!'} text={'Enjoy a wide range of afforable products delivered at your convenience.'} />
                <Box sx={{ width: "100%", maxWidth: "700px", marginLeft: 'auto', marginRight: "auto", }} component={'div'} display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Grid container spacing={2} width={'100%'}>
                        <Grid item width={'100%'}>
                            <Grid container width={'100%'} spacing={2} display={'flex'}>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{ display: 'flex', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", }}>
                                        <TextField error={firstNameError} helperText={firstNameError ? 'First Name is required' : ''} value={firstName} onChange={e => setFirstName(e.target.value)} required id='first_name' label='First Name' sx={{
                                            width: '100%', height: '55px', borderRadius: '5px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{ display: 'flex', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", }}>
                                        <TextField error={lastNameError} helperText={lastNameError ? 'Last Name is required' : ''} value={lastName} onChange={e => setLastName(e.target.value)} required id='last_name' label='Last Name' sx={{
                                            width: '100%', height: '55px', borderRadius: '5px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', borderRadius: '5px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={'100%'}>
                            <Grid container width={'100%'} spacing={2} display={'flex'}>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{ display: 'flex', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", }}>
                                        {email && !validateEmail(email) && (
                                            <FormHelperText error>Enter a valid email</FormHelperText>
                                        )}
                                        <TextField error={emailError} helperText={emailError ? 'Please enter a valid email!' : ''} value={email} onChange={e => setEmail(e.target.value)} required id='email' label='Email Address' sx={{
                                            width: '100%', height: '55px', borderRadius: '5px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: bodyColor,
                                            },
                                        },
                                        '& .MuiInputBase-input': {
                                            color: bodyColor,
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: bodyColor,
                                            opacity: 1,
                                        },
                                    }}>
                                        <PhoneInput country={'ke'} inputStyle={{
                                            width: '100%', height: '55px', borderRadius: '5px', backgroundColor: '#fff', border: phoneNoError ? 'solid 1px red' : `solid 1px ${bodyColor}`, '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }} value={phoneNo} onChange={phoneNo => { setPhoneNo(phoneNo) }} />
                                        <FormHelperText sx={{ color: 'red', display: phoneNoError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={'100%'}>
                            <Grid container width={'100%'} spacing={2} display={'flex'}>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: bodyColor,
                                            },
                                        },
                                        '& .MuiInputBase-input': {
                                            color: bodyColor,
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: bodyColor,
                                            opacity: 1,
                                        },
                                    }}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <OutlinedInput error={passwordError} onChange={e => setPassword(e.target.value)} value={password} placeholder="Enter your Password" sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }}
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <FormHelperText sx={{ color: 'red', display: passwordError ? '' : 'none' }}>{passwordErrorMessage}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6} md={6}>
                                    <FormControl sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: bodyColor,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: bodyColor,
                                            },
                                        },
                                        '& .MuiInputBase-input': {
                                            color: bodyColor,
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: bodyColor,
                                            opacity: 1,
                                        },
                                    }}>
                                        <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                                        <OutlinedInput error={confirmPasswordError} onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} placeholder="Confirm Password" sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: bodyColor,
                                                },
                                            },
                                            '& .MuiInputBase-input': {
                                                color: bodyColor,
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: bodyColor,
                                                opacity: 1,
                                            },
                                        }}
                                            id="confirm-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Confirm Password"
                                        />
                                        <FormHelperText sx={{ color: 'red', display: confirmPasswordError ? '' : 'none' }}>{confirmPasswordErrorMessage}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} lg={12} md={6} width={'100%'} display={'flex'} justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'normal', display:'flex',justifyContent:'center', textAlign: 'center', color: '#898989' }}>
                            Or sign up with
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} width={'100%'}>
                        <Grid container spacing={2} display={'flex'}>
                            <Grid item xs={6} lg={6} md={6} display={'flex'} justifyContent={'center'}>
                                <Button variant='outlined' sx={{ width: '150px', height: '50px', objectFit: 'contain', borderRadius: '10px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                    <img src={facebookIcon} alt='' style={{ width: '25px', height: '24.8px', margin: '0 5px 0 0', objectFit: 'contain' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#343434', textTransform: 'capitalize' }}>Facebook</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={6} md={6} display={'flex'}>
                                <Button variant='outlined' sx={{ width: '150px', height: '50px', objectFit: 'contain', borderRadius: '10px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                    <img src={googleIcon} alt='' style={{ width: '25px', height: '24.8px', margin: '0 5px 0 0', objectFit: 'contain' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#343434', textTransform: 'capitalize' }}>Google</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                        <Grid item width={'100%'}>
                            <Grid container width={'100%'} spacing={2} display={'flex'}>
                                <Grid item xs={12} lg={12} md={6}>
                                    <Typography sx={{ margin: '2px 0 2px 10px', fontSize: '16px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'center', color: bodyColor }}>
                                        <Checkbox {...label} sx={{
                                            width: '18px', height: '18px', color: primaryColor,
                                            '&.Mui-checked': {
                                                color: primaryColor,
                                            },
                                        }} defaultChecked /> I agree to the <Link style={{ fontWeight: 'bold', textDecoration: 'underline', color: headingsColor }}>Terms and Conditions.</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item width={'100%'}>
                            <Grid container width={'100%'} spacing={2} display={'flex'}>
                                <Grid item xs={12} lg={12} md={6} sx={{ textAlign: 'center', }}>
                                    <Button variant='contained' sx={{ width: '313px', height: '50px', margin: '5px 18px 14px 19px', padding: '15px 13px 15px 13px', objectFit: 'contain', borderRadius: '5px', backgroundColor: primaryColor, fontSize: '16px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: primaryColor } }} onClick={loading ? '' : handleSignupSubmit}>Sign Up</Button>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', color: bodyColor }}>
                                        I have an account? <Link to='/login' style={{ textDecoration: 'none', color: primaryColor }}>Log In </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}


export default Register;