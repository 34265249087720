import { Box, Typography } from "@mui/material";
import logoImage from "../../../images/eastwestLogo.svg"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TextIcon from "../../common/TextIcon";

const AuthHeader = ({ heading, text }) => {
    const navigate = useNavigate();
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const { logo } = useSelector((state) => state.template);


    return (
        <Box display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Box sx={{ maxWidth: '99.1px', cursor: "pointer" }} onClick={() => navigate('/')}>
                {logo?.logoType === 'logo' ?
                    <img onClick={() => navigate('/')} src={logo?.logoPath ?? logoImage} alt='' style={{ width: '159px', height: '98.5px', margin: { lg: '79px 64px 30.5px 64px', sm: '' }, objectFit: 'contain', cursor: 'pointer' }} />
                    :
                    <TextIcon text={logo?.logoText} width={'100%'} height={'100%'} backgroundColor={'#fff'} textColor={primaryColor} />
                }
            </Box>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2, letterSpacing: 'normal', textAlign: 'left', color: primaryColor }}>
                {heading}
            </Typography>
            <Typography sx={{ width: '300px', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: bodyColor }}>
                {text}
            </Typography>
        </Box>
    );
}

export default AuthHeader;