import { Box, Button, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import "./index.css";
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle, Delete, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle, Save, ShoppingCart } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { decrementQuantity, incrementInputQuantity, incrementQuantity, removeItem, setPackage } from '../../features/cartSlice';
import CurrencyFormatter from '../common/currencyFormatter';
import { Component, useEffect, useState } from 'react';
import capitalizeFirstLetter from '../common/capitalize';
import useBestPackage from '../common/packages';
import PreorderTypes from '../common/PreorderTypes';
import { SuccessAlert } from '../common/snackbar/success';
import { ErrorAlert } from '../common/snackbar/error';

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stateCart = useSelector((state) => state.cart);
    let cart = stateCart.cart;
    let selectedPackage = stateCart?.package;
    const totalPrice = cart.reduce((total, item) => {
        const { price, quantity } = item;
        const itemTotalPrice = price * quantity;
        return total + itemTotalPrice;
    }, 0);
    const totalPackagePrice = selectedPackage?.price ?? 0;
    const customizationAmount = cart.reduce((total, item) => {
        const { customizationAmount, quantity } = item;
        const itemTotalPrice = customizationAmount * quantity;
        return total + itemTotalPrice;
    }, 0);

    const [showAll, setShowAll] = useState({});
    const [showAllCustomizations, setShowAllCustomizations] = useState({});
    const [showTextField, setShowTextField] = useState(false);
    const { fetchAndDetermineBestPackage } = useBestPackage();
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const template = useSelector((state) => state.template);
    const preOrderTypes = PreorderTypes();

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const handleToggleTextField = () => {
        setShowTextField(!showTextField);
    };

    const handleToggleShowAll = (itemId) => {
        setShowAll((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };
    const handleToggleShowAllCustomizations = (itemId) => {
        setShowAllCustomizations((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    }

    const { pathname } = useLocation();
    
    const assignPackage = async (quantity=1) => {
        let selectedPackage = await fetchAndDetermineBestPackage(quantity);

        if (selectedPackage !== null) {
            let packageQuantity = selectedPackage?.availableStock;

            if (packageQuantity <= 0) {
                setErrorShow({ state: true, message: "An error occurred while selecting a packaging." });
                return false;
            }

            const packagePayload = {
                id: selectedPackage._id,
                image: selectedPackage.featuredImage,
                name: selectedPackage.productName,
                price: selectedPackage.pricing[0]?.discountedPrice,
                quantity: 1,
                variationId: selectedPackage.pricing[0]?._id,
                availableStock: selectedPackage?.pricing[0]?.availableStock
            };

            dispatch(setPackage(packagePayload));
            return true;
        } else {
            setErrorShow({ state: true, message: "No suitable package found." });
            return false;
        }
    };

    const handleIncrementQuantity = async (item) => {
        const existingItem = cart.find(
            (product) => product.id === item.id && product.variationId === item.variationId
        );

        const packageAssigned = await assignPackage(1);
        if (packageAssigned) { 
            if (existingItem && existingItem.quantity + 1 <= existingItem.remainingStock) {
                dispatch(incrementQuantity(item.id));  // Increment the quantity if conditions are met
            } else {
                setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
            }
        }
    }
    const handleDecrementQuantity = async (item) => {
        dispatch(decrementQuantity(item?.id))
    }
    const handleQuantityInput = async (value, item) => {
        const existingItem = cart.find(
            (product) => product.id === item.id && product.variationId === item.variationId
        );
        const packageAssigned = await assignPackage(value);
        if (packageAssigned) { 
            if (existingItem && value < existingItem.remainingStock) {
                dispatch(incrementInputQuantity({ id: item.id, value }));
            } else {
                setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
            }
        }
    }
    const handleRemoveItem = async (item) => {
        dispatch(removeItem({ itemId: item?.id, variationId: item?.variationId }));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', mt: '20px' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: headingsColor, textAlign: 'left', textWrap: 'nowrap', margin: '0 15px 0 0' }}>
                            Shopping Cart
                        </Typography>
                        {cart.length > 0 &&
                            <Typography sx={{ fontSize: '14px', textAlign: 'left', color: bodyColor, textWrap: 'wrap' }}>
                                There {cart.length > 1 ? 'are' : 'is'} {cart.length} item{cart.length > 1 && 's'} in your cart
                            </Typography>}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {cart.length > 0 && <Button onClick={() => { navigate('/') }} variant='outlined' sx={{ width: '100%', maxWidth: '191px', height: '100%', maxHeight: '48px', padding: '29.5px 15.5px 18.5px 20px', m: '0 8px 0px 0px', borderRadius: '4px', border: `solid 1px ${primaryColor}`, backgroundColor: '#fff', '& .badge-container': { position: 'absolute', top: '5px', right: '140px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: `solid 1px ${primaryColor}` } }}>
                            <ShoppingCart sx={{ color: primaryColor, marginRight: '-2px' }} />
                            <div className="badge-container">
                                <Typography component="sup" style={{ color: primaryColor, fontWeight: 'bold', padding: '10px 0px', fontSize: '14px' }}>{cart.length}</Typography>
                            </div>
                            <Typography sx={{ textWrap: 'nowrap', textTransform: 'capitalize', fontSize: '14px', margin: '0px 0 6px 20px', textAlign: 'center', color: primaryColor }}>Continue Shopping</Typography>
                        </Button>}
                        {cart.length > 0 && <Button onClick={() => { navigate('/checkout') }} variant='contained' sx={{ width: "100%", maxWidth: '125px', height: '48px', padding: '15px 16.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', backgroundColor: primaryColor, '&:hover': { backgroundColor: primaryColor } }}>
                            <ShoppingCart sx={{ color: '#fff' }} />
                            <Typography sx={{ textWrap: 'nowrap', width: '68px', height: '20px', margin: ' 0 0 0 10.1px', fontSize: '13px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Checkout</Typography>
                        </Button>}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {cart.length === 0 ?
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'normal', color: bodyColor, lineHeight: 'normal', textWrap: 'nowrap' }}>
                            Your cart is empty!
                        </Typography>
                    </Grid>
                    :
                    <Grid container item xs={12} sx={{ alignItems: 'start', }}>
                        {cart?.map((item) => (
                            <Grid container item xs={12} sx={{ width: '100%', maxWidth: '1034px', minHeight: '100px', height: 'auto', padding: '28px 24px 27px', borderRadius: '15px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: 'flex', alignItems: 'start', margin: '0px 23px 15px 0px' }}>
                                <Grid item xs={6} sm={6} md={6} lg={1} xl={1} sx={{ m: { md: '0 35px 0px 0' } }}>
                                    <Box sx={{ display: 'flex', overflow: 'hidden', maxWidth: '100px' }}>
                                        <img src={item?.image} alt='' style={{ width: '100%', maxWidth: '100px', height: '100%', maxHeight: '95px', objectFit: 'contain' }} />
                                        {item?.isPreOrder && <Box sx={preOrderTypes[template?.preOrderBadgeType]}>
                                            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'wrap', fontSize: '12px' }}>Pre-Order</Typography>
                                        </Box>}
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ width: '100%', maxWidth: '141px' }}>
                                    <Typography sx={{ margin: '0 0 4px', fontSize: '14px', fontWeight: '600', textAlign: 'left', color: headingsColor, textWrap: 'wrap', width: '100%', maxWidth: '141px' }}>
                                        {item?.name}
                                    </Typography>
                                    <Typography sx={{ margin: '10px 10px 0 0', fontSize: '14px', fontWeight: '500', textAlign: 'left', color: bodyColor }}>
                                        {CurrencyFormatter(item?.price)}
                                    </Typography>
                                    <Typography sx={{ margin: '12px 0 12px', fontSize: '14px', fontWeight: '550', textAlign: 'left', color: headingsColor, textWrap: 'wrap', width: '100%', maxWidth: '141px' }}>
                                        Customization Charges:
                                    </Typography>
                                    <Typography sx={{ margin: '10px 10px 0 0', fontSize: '14px', fontWeight: '500', textAlign: 'left', color: bodyColor }}>
                                        {CurrencyFormatter(item?.customizationAmount ?? 0)}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ width: '100%', maxWidth: '141px', }} >
                                    <Grid item xs={12}>
                                        {(showAll[item.id] ? Object.entries(item?.variations) : Object.entries(item?.variations).slice(0, 2)).map(([key, value]) => (
                                            <Box sx={{ display: 'flex', textTransform: 'capitalize' }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: headingsColor, mr: '2px', }}>{capitalizeFirstLetter(key)}: </Typography>
                                                <Typography sx={{ margin: '0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor, textTransform: 'capitalize' }}>{capitalizeFirstLetter(value)}</Typography>
                                            </Box>
                                        ))}
                                        <Typography onClick={() => handleToggleShowAll(item.id)} sx={{ color: bodyColor, fontSize: '12px', textTransform: 'capitalize' }}>
                                            {showAll[item.id] ? (
                                                <Box sx={{ display: 'flex' }}>
                                                    Hide Details <KeyboardArrowUp sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: 'flex' }}>
                                                    More Details <KeyboardArrowDown sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                </Box>
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ margin: { md: '0 35px 0px 0' } }} >
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: headingsColor, textWrap: 'nowrap' }}>Customization:</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                                            {(showAllCustomizations[item.id] ? item?.additionalServices : item?.additionalServices.slice(0, 2)).map((services) => (
                                                <Box key={services.id} sx={{ margin: '0', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>
                                                    {(showAllCustomizations[item.id] ? services?.items : services?.items?.slice(0, 1)).map((serviceItem) => (
                                                        <Box key={serviceItem.id} sx={{ display: 'flex' }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: headingsColor, mr: '2px' }}>
                                                                {serviceItem?.itemName}:
                                                            </Typography>
                                                            <Typography sx={{ margin: '0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor }}>
                                                                {serviceItem?.isCustomizableValue ?? 'None'}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ))}
                                            {item?.additionalServices?.length > 2 && (
                                                <Typography onClick={() => handleToggleShowAllCustomizations(item.id)} sx={{ color: bodyColor, fontSize: '12px', textTransform: 'capitalize', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                    {showAllCustomizations[item.id] ? (
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            Hide Details <KeyboardArrowUp sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            More Details <KeyboardArrowDown sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                        </Box>
                                                    )}
                                                </Typography>
                                            )}
                                            {item?.additionalServices.length === 0 && <Typography sx={{ margin: '0', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: bodyColor }}>
                                                None
                                            </Typography>}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '30px', alignItems: 'center' }}>
                                    <Typography sx={{ borderRadius: '3px', backgroundColor: '#fff' }}>
                                        <RemoveCircle onClick={() => handleDecrementQuantity(item)} sx={{ color: bodyColor, width: '30px', height: '30px', objectFit: 'contain' }} />
                                    </Typography>
                                    <TextField onChange={(e) => handleQuantityInput(e.target.value, item)} value={item?.quantity} inputProps={{ style: { textAlign: 'center', color: bodyColor, fontSize: '14px', height: '15px', width: '100%', borderRadius: '5px', margin: '0.5px 10px 0', maxWidth: '16px' } }} variant='outlined' />
                                    <Typography sx={{ borderRadius: '3px', backgroundColor: '#fff' }}>
                                        <AddCircle onClick={() => handleIncrementQuantity(item)} sx={{ color: primaryColor, width: '30px', height: '30px', objectFit: 'contain' }} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={6} lg={1} xl={1} sx={{ margin: '36px 3px' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'left', color: headingsColor }}>
                                        {CurrencyFormatter((item?.quantity * item?.price) + (item?.quantity * item?.customizationAmount))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} sm={2} md={6} lg={1} xl={1} sx={{ textAlign: 'right' }}>
                                    <Delete onClick={() => handleRemoveItem(item)} sx={{ color: '#ef0b0b', marginTop: '35px', width: '30px', height: "30px" }} />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container item xs={12} sx={{ backgroundColor: '#fff', justifyContent: 'end', margin: '0px 23px 15px 0px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ height: '100%', padding: '30px 31px 30px 32px', borderRadius: '22px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', }}>
                                <Box>
                                    <Box sx={{ display: 'flex', margin: '4px 7px 3px 0', cursor: 'pointer' }} onClick={handleToggleTextField}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'left', color: headingsColor }}>
                                            Have a coupon/promo code?
                                        </Typography>
                                        {showTextField ? (
                                            <KeyboardArrowUp sx={{ margin: '0 0 0 7px', width: '30px', color: bodyColor }} />
                                        ) : (
                                            <KeyboardArrowDown sx={{ margin: '0 0 0 7px', width: '30px', color: bodyColor }} />
                                        )}
                                    </Box>
                                    {showTextField && (
                                        <TextField
                                            name="Coupon"
                                            value=""
                                            onChange={() => { }}
                                            placeholder="Apply Coupon"
                                            variant="outlined"
                                            sx={{
                                                textAlign: 'center', height: '48px', '& .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor }, mt: 2,
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: primaryColor,
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: primaryColor,
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: primaryColor,
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    color: bodyColor,
                                                },
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: bodyColor,
                                                    opacity: 1,
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <button
                                                            style={{
                                                                border: 'none',
                                                                backgroundColor: primaryColor,
                                                                height: '48px',
                                                                margin: '0px',
                                                                width: '100px',
                                                                borderRadius: '4px',
                                                                padding: '14px 10px 15px 18px',
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '14px', textAlign: 'left', color: '#fff' }}>Apply</Typography>
                                                        </button>
                                                    </InputAdornment>
                                                ),
                                                style: { height: '48px', padding: '0', maxWidth: '330px', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#89afad' } },
                                            }}
                                        />
                                    )}
                                </Box>
                                <Divider flexItem sx={{ width: '100%', height: '1px', backgroundColor: bodyColor, margin: '44px 0px' }} />
                                <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: bodyColor }}>Subtotal:</Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 500, textAlign: 'right', color: headingsColor }}>{CurrencyFormatter(totalPrice)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: bodyColor }}>Customization:</Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 500, textAlign: 'right', color: headingsColor }}>{CurrencyFormatter(customizationAmount)}</Typography>
                                </Box>
                                {totalPackagePrice > 0 && <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: bodyColor }}>Packaging Fee:</Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 500, textAlign: 'right', color: headingsColor }}>{CurrencyFormatter(totalPackagePrice)}</Typography>
                                </Box>}
                                <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: bodyColor }}>Total (tax incl.):</Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 500, textAlign: 'right', color: primaryColor }}>{CurrencyFormatter(totalPrice + customizationAmount + totalPackagePrice ?? 0)}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'end', justifyContent: 'center', mr: { xs: '5%', sm: '5%', md: '10%', lg: '20%', xl: '20%' } }}>
                            {/* <Button variant='outlined' sx={{ width: '100%', height: '100%', maxWidth: '131px', maxHheight: '50px', margin: '0 10px 0 0', padding: '15px 16.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', border: 'solid 1px #707070', '&:hover': { border: 'solid 1px #707070' } }}>
                                    <Save sx={{ color: '0 3.7px 0 3.7px', color: '#707070' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: ' 0 0 0 10.1px',fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#707070' }}>Save Cart</Typography>
                                </Button> */}
                            <Button onClick={() => { navigate('/checkout') }} variant='contained' sx={{ width: '131px', height: '50px', margin: '10px 0 0 0', padding: '15px 6.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', backgroundColor: primaryColor, '&:hover': { backgroundColor: primaryColor } }}>
                                <ShoppingCart sx={{ color: '0 3.7px 0 3.7px', color: '#fff' }} />
                                <Typography sx={{ textWrap: 'nowrap', margin: ' 0 0 0 10.1px', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Checkout</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
}

export default Cart;