import {Grid, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import React from "react";

// SnackBar Settings
const Alert = React.forwardRef(function Alert(props, ref) {return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;});

const SuccessAlert = (props) => {

	const verticalLocation = props.vertical
	const horizontalLocation = props.horizontal
	const handleErrorNotificationClose = props.onClose
	const errorNotificationOpen = props.open
	const message = props.message

	return (
		<Grid container>
			<Grid item>
				{/* Missing Quantity Notification*/}
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar anchorOrigin={{ vertical : verticalLocation, horizontal : horizontalLocation }} open={errorNotificationOpen} autoHideDuration={7000} onClose={handleErrorNotificationClose}>
						<Alert onClose={handleErrorNotificationClose} severity="success" sx={{ width: '100%' }}>
							{message}
						</Alert>
					</Snackbar>
				</Stack>
			</Grid>
		</Grid>
	)
}

export {SuccessAlert}
