import { Box, Grid, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import imagePlaceholder from '../../../images/img-placeholder.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CurrencyFormatter from "../../common/currencyFormatter";
import { useSelector } from "react-redux";
import PreorderTypes from "../../common/PreorderTypes";
import { useEffect, useState } from "react";

const ProductItem = ({ product, image, discountedPrice, normalPrice, productId }) => {
    const navigate = useNavigate();
    const primaryColor = 'var(--primary)';
    const headingColor = 'var(--headings)';
    const quaternaryColor = 'var(--quaternary)';
    const template = useSelector((state) => state.template);
    const preOrderTypes = PreorderTypes();
    const [isSoldOut, setIsSoldOut] = useState(false);

    const checkPreorder = (pricingObject) => {
        const now = new Date();

        const hasValidPreOrder = pricingObject?.some(item =>
            item.isPreOrderEnabled && new Date(item.preOrderdateEnd) > now && item?.preOrderStockLeft > 0
        );
        return hasValidPreOrder;
    };

    const checkIsSoldOut = (pricingObject) => {
        if (pricingObject?.length > 0) {
            const firstItemSoldOut = pricingObject[0].availableStock === 0;
            setIsSoldOut(firstItemSoldOut);
        }
    };
    
        
    useEffect(() => {
        checkIsSoldOut(product?.pricing);
    }, [product?.pricing]);

    return (
        <Box onClick={() => { navigate(`/product/${productId}`) }} key={productId} sx={{ textDecoration: 'none', width: '251px', backgroundColor: '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', padding: { xs: '20px 20px 26px 26px', sm: '20px 0px 0px 26px' }, height: '339px', cursor: 'pointer', margin: '17px 10px 24px 10px', borderRadius: '15px', overflow: 'hidden', position: 'relative' }}>
            {checkPreorder(product?.pricing) &&
                <Box sx={preOrderTypes[template?.preOrderBadgeType]}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'wrap' }}>Pre-Order Now</Typography>
                </Box>}
            {!checkPreorder(product?.pricing) && isSoldOut &&
                <Box sx={preOrderTypes['outOfStock']}>
                    <Typography sx={{ fontSize: '12px', fontWeight:500, color: '#fff', textWrap: 'wrap', }}>Sold Out</Typography>
                </Box>}
            <Box sx={{ textWrap: 'nowrap', width: '200px', height: '200px', margin: '0 5px 16px 0', borderRadius: '15px', backgroundColor: '#fff', alignItems: 'center', textAlign: 'center', margin: '5px 5px 16px 10px', justifyContent: 'center', display: 'flex', position: 'relative' }}>
                <LazyLoadImage src={image} alt="product" effect="blur" placeholderSrc={imagePlaceholder} style={{ maxWidth: '200px', maxHeight: '200px', height: '100%', width: '100%', objectFit: 'contain', }} />
            </Box>
            <Box sx={{ cursor: 'pointer', width: '200px', height: '67px', margin: '16px 5px 0 0' }}>
                <Typography style={{ fontSize: '14px', color: headingColor, textAlign: 'left', height: '100%', maxHeight: '41px', overflow: 'hidden', margin: '0 0 10px' }}>{product.productName}</Typography>
                <Box>
                    {discountedPrice && discountedPrice < normalPrice && <Typography style={{ margin: '0 26px 0 0', fontSize: '9px', textDecoration: 'line-through', color: '#ef0b0b', textAlign: 'left' }}>{CurrencyFormatter(normalPrice)}</Typography>}
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{ flexDirection: 'column', fontSize: '13px', fontWeight: 'bold', color: headingColor, margin: '0px 104px 0 0' }}>{CurrencyFormatter(discountedPrice)} </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }}>
                            <RemoveRedEyeIcon style={{ fontSize: "20px", color: primaryColor, marginRight: "3px" }} />
                            <Typography style={{ fontSize: "10px", color: quaternaryColor, textWrap: "nowrap" }}>View Details</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default ProductItem;
