import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import BusinessRoutes from "./BusinessRoutes";

const CustomerPrivateRoutes = () => {
  const { isLoggedIn } = useSelector((store) => store.user)
  const group = secureLocalStorage.getItem("group");
  return (
    <>
      {isLoggedIn ? (
        <>
          {group === "Customer" ? (
            <BusinessRoutes isNotAuthRoute={true}/>
          ) : (
            <Navigate to="/login" />
          )}
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default CustomerPrivateRoutes;
