import { Box, Grid, Typography } from "@mui/material"
import CustomSearchInput from "../common/CustomSearchInput"
import { useEffect, useState } from "react";
import CustomSelect from "../common/customSelectInput";
import { CustomizedDate } from "../common/customDate";
import { startOfToday, startOfYesterday, endOfToday, endOfYesterday, subDays, startOfMonth, endOfMonth, subMonths, format, formatDate } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import DateFormatter from "../common/dateFormatter";
import { TabPanel } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import CurrencyFormatter from "../common/currencyFormatter";

const getLastMonthRange = (date) => {
    const previousMonth = subMonths(date, 1);
    const start = startOfMonth(previousMonth);
    const end = endOfMonth(previousMonth);
    return { start, end };
};

const PurchasedVocuhers = () => {
    const [search, setSearch] = useState('');
    const [range, setRange] = useState('thisMonth');
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const navigate = useNavigate();
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue] = newqueryParamValue.split("?")


    const ranges = [
        { id: 1, label: 'Select Date', value: '' },
        { id: 2, label: 'Today', value: 'today' },
        { id: 3, label: 'Yesterday', value: 'yesterday' },
        { id: 4, label: 'Last 7 Days', value: 'oneWeek' },
        { id: 5, label: 'This Month', value: 'thisMonth' },
        { id: 6, label: 'Last Month', value: 'lastMonth' },
        { id: 7, label: 'Custom Range', value: 'customDate' },
    ];

    const columns = [
        {
            field: 'voucherNumber', headerName: 'Voucher Number', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Voucher Number</Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }} > {params?.row?.voucherNumber}  </Box>)
            }
        },
         
        {
            field: 'name', headerName: 'Recipient Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Recipient Name</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.name}  </Box>) }
        },
       
        {
            field: 'phone', headerName: 'Phone', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Phone</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.phone}  </Box>) }
        },
        {
            field: 'createdAt', headerName: 'Date Purchased', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Date Purchased</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{DateFormatter(params?.row?.createdAt)}  </Box>) }
        },
        {
            field: 'campaignName', headerName: 'Campaign Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Campaign Name</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.campaignName ?? 'N/A'}  </Box>) }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Amount</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.amount, 'KES')}</Box>) }
        },
        {
            field: 'redeemed', headerName: 'Redeemed', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Redeemed</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#17ae7b", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.redeemed, 'KES')}</Box>) }
        },
        {
            field: 'balance', headerName: 'Balance', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Balance</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#f79009", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.balance)}</Box>) }
        }
       
    ];
    const handleRowClick = (event, rowId) => {
        event.stopPropagation();
        navigate(`/zed_ecommerce/evoucher/${rowId}`)
    }
    const sanitizeSearchQuery = (query) => {
        return query.replace(/\D/g, '');
    };

    const getPurchasedList = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        let status = 'All';
        const url = `/api/v1/evoucher/getEvouchers?pageNumber=${pageState.page}&pageLimit=${pageState.pageSize}&status=${status}&searchValue=${sanitizeSearchQuery(search)}&startDate=${from ?? ''}&endDate=${to ?? ''}&type=&paymentStatus=PAID`;
        HttpRequest({
          method: 'GET',
          url: url,
        }).then((data) => {
          setPageState({
            ...pageState,
            data: data?.response?.data,
            total: data?.response?.count,
            isLoading: false
          });
        //   getDataToExport(data?.response?.count, status, search, paymentStatus, from, to, deliveryMethod, orderType);
        }).catch((error) => {
        });
      };
      useEffect(() => {
        getPurchasedList();
      }, [pageState.page, pageState.pageSize, search, from, to , queryParamValue]);
       

    useEffect(() => {
        const updateDates = (range) => {
            const today = new Date();
            let fromDate = null;
            let toDate = null;

            switch (range) {
                case 'today':
                    fromDate = format(startOfToday(), 'MM/dd/yyyy');
                    toDate = format(endOfToday(), 'MM/dd/yyyy');
                    break;
                case 'yesterday':
                    fromDate = format(startOfYesterday(), 'MM/dd/yyyy');
                    toDate = format(endOfYesterday(), 'MM/dd/yyyy');
                    break;
                case 'oneWeek':
                    fromDate = format(subDays(today, 7), 'MM/dd/yyyy');
                    toDate = format(endOfToday(), 'MM/dd/yyyy');
                    break;
                case 'thisMonth':
                    fromDate = format(startOfMonth(today), 'MM/dd/yyyy');
                    toDate = format(endOfMonth(today), 'MM/dd/yyyy');
                    break;
                case 'lastMonth':
                    ({ start: fromDate, end: toDate } = getLastMonthRange(today));
                    fromDate = format(fromDate, 'MM/dd/yyyy');
                    toDate = format(toDate, 'MM/dd/yyyy');
                    break;
                default:
                    fromDate = null;
                    toDate = null;
                    break;
            }

            setFrom(fromDate);
            setTo(toDate);
        };

        updateDates(range);
    }, [range]);


    return (
        <Grid container display={'flex'} width={'100%'} direction={'column'} pt={10}>
            <Grid item width={'100%'}>
                <Grid container display={'flex'} flexDirection={'row'}>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "25px", fontStyle: "normal", fontWeight: "600", }}>
                        Evouchers Purchased
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sx={{ display: 'flex' , justifyContent:'space-between', alignItems:"center" }} lg={12}>
                <CustomSearchInput placeholder="Search" name="search" value={search} onChange={(event) => setSearch(event.target.value)} />
                <Box component={'div'} sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {range === 'customDate' ? '' :
                        <CustomSelect
                            value={range}
                            options={ranges}
                            sm={"sm"}
                            onChange={(e) => setRange(e.target.value)}
                        />}
                    {range === 'customDate' && (
                        <CustomizedDate setDateFrom={setFrom} setDateTo={setTo} style={{ display: "flex", alignItems: "center" }} />
                    )}
                </Box>
                
            </Grid>

            <Grid item sx={{ display: 'flex', width: '100%' }}>
                <DataGrid
                    sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowCount={pageState.total}
                    pagination
                    loading={pageState.isLoading}
                    page={pageState.page - 1}
                    pageSizeOptions={[1, 10, 20, 50, 100]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: dataGridPageSize,
                                page: pageState.page - 1,
                            },
                        },
                    }}
                    pageSize={dataGridPageSize}
                    onPaginationModelChange={(data) => {
                        const { pageSize, page } = data;
                        setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                        setPageSize(pageSize)
                    }}
                    paginationMode="server"
                    columns={columns}
                    rows={pageState.data}
                    getRowId={(row) => row?._id}
                />
               </Grid>


        </Grid>
    )
}

export default PurchasedVocuhers