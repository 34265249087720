import React from 'react';

const TextIcon = ({ text = 'A', width = 100, height = 100, backgroundColor = '#02B3AD', textColor = '#fff',fontFamily='Poppins' }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <rect width="100" height="100" fill={backgroundColor} />
    <text x="50%" y="50%" fontSize="50" textAnchor="middle" stroke={textColor} strokeWidth="1px" dy=".3em" fill={textColor} fontFamily={fontFamily} >
      {text} 
    </text>
  </svg>
);

export default TextIcon;
