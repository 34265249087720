import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material"
import { useState } from "react";
import { HttpRequest } from "../../HttpRequest";
import secureLocalStorage from "react-secure-storage";
import { SuccessAlert } from "../../common/snackbar/success";
import { ErrorAlert } from "../../common/snackbar/error";


const ProfileDetails = ({ customerDetails }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const email = secureLocalStorage.getItem('email');
    const [editPassword, setEditPassword] = useState(false);


    const handleClickShowPassword = () => {
        setShowPassword(true);
    };
    const handleClickHidePassword = () => {
        setShowPassword(false);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = () => {
        if (currentPassword === '') {
            setErrorShow({ state: true, message: 'Current password is required!' });
            return;
        }
        if (password === '') {
            setErrorShow({ state: true, message: 'New password is required!' });
            return;
        }
        if (password.length < 4) {
            setErrorShow({ state: true, message: 'Password must be at least 4 characters!' });
            return;
        }
        if (passwordConfirm === '') {
            setErrorShow({ state: true, message: 'Confirm password is required!' });
            return;
        }
        if (passwordConfirm.length < 4) {
            setErrorShow({ state: true, message: 'Confirm Password must be at least 4 characters!' });
            return;
        }
        if (password !== passwordConfirm) {
            setErrorShow({ state: true, message: 'Passwords do not match!' });
            return;
        }

        let payload = {
            userPin: currentPassword,
            userNewPin: password,
            type: 'ecommerce',
            userEmail: email
        };

        HttpRequest({
            method: 'PUT',
            url: '/api/resetPinVersion2',
            body: payload
        }).then((response) => {
            if (response.response.Status === "SUCCESS") {
                setSuccessShow({ state: true, message: `Password changed successful.` });
                setEditPassword(false);
                setCurrentPassword('');
                setPassword('');
                setPasswordConfirm('');
            } else {
                setErrorShow({ state: true, message: response.response.message })
            }

        }).catch((error) => {
            setErrorShow({ state: true, message: 'Password reset failed. Please try again!' });
        })
    }

    return (
        <Grid container width={'100%'} spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Typography sx={{ margin: '0 16px 26px 0', fontSize: '16px', fontWeight: 600, color: '#000' }}>Personal Data</Typography>
                <Box sx={{ width: '100%', maxWidth: '300px', height: '100%', maxHeight: '308px', margin: '26px 0 0' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ margin: '0 12px 38px 0', fontSize: '14px', fontWeight: 500, color: '#000' }}>Name: </Typography>
                        <Typography sx={{ margin: '0 0px 38px 0', fontSize: '14px', color: '#898989' }}>{customerDetails?.firstName} {customerDetails?.lastName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ margin: '0 12px 38px 0', fontSize: '14px', fontWeight: 500, color: '#000', textWrap: 'nowrap' }}>Email Address: </Typography>
                        <Typography sx={{ margin: '0 0px 38px 0', fontSize: '14px', color: '#898989' }}>{customerDetails?.email}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ margin: '0 12px 38px 0', fontSize: '14px', fontWeight: 500, color: '#000', textWrap: 'nowrap' }}>Phone: </Typography>
                        <Typography sx={{ margin: '0 0px 38px 0', fontSize: '14px', color: '#898989' }}>{customerDetails?.phone}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ margin: '0 12px 38px 0', fontSize: '14px', fontWeight: 500, color: '#000', textWrap: 'nowrap' }}>Password: </Typography>
                        <Typography sx={{ margin: '0 0px 38px 0', fontSize: '14px', color: '#898989' }}>********</Typography>
                        <Edit onClick={()=>setEditPassword(!editPassword)} sx={{ width: '25px', height: '25px', margin: '0px 0 3px 64px', objectFit: 'contain',cursor:'pointer' }} />
                    </Box>
                    {editPassword && <Box>
                        <Typography sx={{ margin: '11px 14px 10px 0', fontSize: '16px', fontWeight: 500, color: '#000' }}>Change Password</Typography>
                        <Box>
                            <FormControl sx={{ width: '100%', maxWidth: '320px', height: '55px', margin: '0 0 10px 0px', borderRadius: '5px', backgroundColor: '#fff', borderRadius: '5px', backgroundColor: '#fff' }}>
                                <InputLabel htmlFor="current-password" sx={{ fontSize: '14px', color: '#cacaca', fontWeight: 'normal' }}>Current Password</InputLabel>
                                <OutlinedInput autoComplete='off' autoFocus='off' label="Current Password" onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} placeholder="Enter your current Password" id="current-password" type={showPassword ? 'text' : 'password'} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={showPassword ? handleClickHidePassword : handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>} />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl sx={{ width: '100%', maxWidth: '320px', height: '55px', margin: '0 0 10px 0px', borderRadius: '5px', backgroundColor: '#fff', borderRadius: '5px', backgroundColor: '#fff' }}>
                                <InputLabel htmlFor="password" sx={{ fontSize: '14px', color: '#cacaca', fontWeight: 'normal' }}>New Password</InputLabel>
                                <OutlinedInput autoComplete='off' label="New Password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter your Password" id="password" type={showPassword ? 'text' : 'password'} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={showPassword ? handleClickHidePassword : handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>} />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl sx={{ width: '100%', maxWidth: '320px', height: '55px', margin: '0 0 10px 0px', borderRadius: '5px', backgroundColor: '#fff', borderRadius: '5px', backgroundColor: '#fff' }}>
                                <InputLabel htmlFor="confirm-password" sx={{ fontSize: '14px', color: '#cacaca', fontWeight: 'normal' }}>Confirm Password</InputLabel>
                                <OutlinedInput autoComplete='off' label="Confirm Password" onChange={(e) => setPasswordConfirm(e.target.value)} value={passwordConfirm} placeholder="Confirm your Password" id="password-confirm" type={showPassword ? 'text' : 'password'} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>} />
                            </FormControl>
                        </Box>
                        <Box>
                            <Button onClick={handleSubmit} variant='contained' sx={{ textWrap: 'nowrap', width: '150px', height: '50px', margin: '11px 18px 14px 0px', padding: '15px 13px 15px 13px', objectFit: 'contain', opacity: currentPassword !== '' && password !== '' && passwordConfirm !== '' ? 1 : '0.5', borderRadius: '5px', backgroundColor: '#02b3ad', fontSize: '14px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: '#02b3ad' } }}>Change Password</Button>
                        </Box>
                    </Box>}
                    <Typography sx={{ margin: '10px 1px 0 0', fontSize: '14px', fontWeight: 500, color: '#ef0b0b', textWrap: 'nowrap' }}>Delete Account </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export { ProfileDetails };