import React from 'react';
import { Circle } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const BreadCrumbs = ({ links = [] }) => {
    return (
        <Breadcrumbs sx={{ margin: '10px 3px 16px 0' }} separator={<Circle sx={{ width: '10px', height: '10px', margin: '7px 10px 6px', color: '#e1e5e8' }} />} aria-label="breadcrumb">
            <Link to="/zed_ecommerce/dashboard" style={{ fontSize: '14px', lineHeight: '2.36', color: '#707070', textDecoration: 'none' }}> Dashboard </Link>
            {Array?.isArray(links) && links?.map((link, i) => (
                <Link key={i} to={link?.link} style={{ fontSize: '14px', lineHeight: '2.36', margin: '0 10px 0 0', color: link?.link === '' ? '#dc3545' : '#707070', textDecoration: 'none' }} >
                    {link?.label}
                </Link>
            ))}
        </Breadcrumbs>
    );
};

export default BreadCrumbs;
