import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { AccountCircle, DashboardCustomize, DonutLarge, ExpandLess, ExpandMore, FlipToBackOutlined, ListAlt, } from "@mui/icons-material";
import inventoryIcon from "../../images/inventory.svg";
import settingsIcon from "../../images/settings.svg";
import reportsIcon from "../../images/report-icon.svg";
import tagsIcon from "../../images/tags.svg";
import variationsIcon from "../../images/variations.svg";
import deliveryIcon from "../../images/deliveryIcon.svg";
import fastDeliveryIcon from "../../images/fastdelivery.svg";
import EvoucherImg from "../../images/evoucher.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
export default function SidebarList(props) {
    const open = props?.open;

    const [inventoryOpen, setInventoryOpen] = useState(false);
    const [deliveryOpen, setDeliveryOpen] = useState(false);
    const [configurationsOpen,setConfigurationsOpen] = useState(false);
    const [evoucherOpen, setEvoucherOpen] = useState(false);

    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    const zedUrl = process.env.REACT_APP_ZED_URL;
    const {ecommerceUrlEnabled,additionalServicesEnabled, packageFeeEnabled ,evoucherEnabled } = useSelector((store) => store.configs);

    return (
        <>
            <List sx={{ paddingTop: 2, backgroundColor: '#032541', height: '100%' }}>
                <ListItem key="dashboard" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/dashboard")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <DashboardCustomize sx={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Dashboard"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/dashboard" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="orders" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/orders")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <ListAlt sx={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Orders"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/orders" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="preorders" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/preOrders")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <FlipToBackOutlined sx={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Pre-Orders"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/preOrders" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="variations" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/variations")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={variationsIcon} alt="variationsIcon" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Variations"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/variations" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="tags" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/tags")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={tagsIcon} alt="tagsIcon" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Tags"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/tags" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="delivery" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} onClick={() => setDeliveryOpen(!deliveryOpen)}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={deliveryOpen ? deliveryIcon : fastDeliveryIcon} alt="deliveryIcon" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Delivery Method"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/shippingRegions" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                        {deliveryOpen ? (
                            <ExpandLess sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        ) : (
                            <ExpandMore sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        )}
                    </ListItemButton>
                    <Collapse in={deliveryOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/shippingRegions")}>
                                <ListItemText disableTypography primary={"Shipping Regions"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/shippingRegions" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/pickups")}>
                                <ListItemText disableTypography primary={"Pick ups"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/pickups" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>

                    </Collapse>
                </ListItem>
                <ListItem key="inventory" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} onClick={() => setInventoryOpen(!inventoryOpen)}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={inventoryIcon} alt="Inventory" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Inventory"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/inventory" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                        {inventoryOpen ? (
                            <ExpandLess sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        ) : (
                            <ExpandMore sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        )}
                    </ListItemButton>
                    <Collapse in={inventoryOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/categories")}>
                                <ListItemText disableTypography primary={"Categories"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/categories" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/products")}>
                                <ListItemText disableTypography primary={"Products"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/products" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                        {additionalServicesEnabled && <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/additionalServices")}>
                                <ListItemText disableTypography primary={"Additional Services"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/additionalServices" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>}
                    </Collapse>
                </ListItem>

                <ListItem key="customers" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/customers")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <AccountCircle sx={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Customers"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/customers" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                {evoucherEnabled &&
                <ListItem key="evoucher" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} onClick={() => setEvoucherOpen(!evoucherOpen)}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={EvoucherImg} alt="Evouchers" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Evouchers"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/evouchercompaings" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                        {evoucherOpen ? (
                            <ExpandLess sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        ) : (
                            <ExpandMore sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        )}
                    </ListItemButton>
                    <Collapse in={evoucherOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/evouchercompaings")}>
                                <ListItemText disableTypography primary={"Campaigns"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/evouchercompaings" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/evoucherpurchased")}>
                                <ListItemText disableTypography primary={"Purchased"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/evoucherpurchased" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </ListItem>}
            
                <ListItem key="reports" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => navigate("/zed_ecommerce/reports")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={reportsIcon} alt="Reports" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Reports"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/reports" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
                <ListItem key="configurations" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, }} onClick={() => setConfigurationsOpen(!configurationsOpen)}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <img src={settingsIcon} alt="Configurations" style={{ objectFit: 'contain' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Configurations"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/inventory" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                        {configurationsOpen ? (
                            <ExpandLess sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        ) : (
                            <ExpandMore sx={{ display: open ? "inherit" : "none", color: "#fff" }} />
                        )}
                    </ListItemButton>
                    <Collapse in={configurationsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/configurations")}>
                                <ListItemText disableTypography primary={"E-commerce"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/configurations" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>
                        {ecommerceUrlEnabled && <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 9 }} onClick={() => navigate("/zed_ecommerce/customizeStore")}>
                                <ListItemText disableTypography primary={"Store Appearance"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: currentPath === "/zed_ecommerce/customizeStore" ? "#04f2fc" : "#fff", opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </List>}
                    </Collapse>
                </ListItem>
                <ListItem key="switch" disablePadding sx={{ display: "block" }}>
                    <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 }} onClick={() => window.location.href = zedUrl}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                            <DonutLarge style={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={"Switch to Zed"} sx={{ fontFamily: "Poppins", fontSize: 12, fontWeight: "normal", fontStyle: "normal", textAlign: "left", color: "#fff", opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List >
        </>
    );
}
