import secureLocalStorage from "react-secure-storage";

const base_url = process.env.REACT_APP_BASE_URL;


const HttpRequest = async ({ method, url, body }) => {
    let token = secureLocalStorage.getItem('token');

    let finalUrl = `${base_url}${url}`
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
    if (token) {
        headers['X-Authorization'] = token;
    }

    return fetch(finalUrl, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined
    }).then((res) => {
        const status = res.status;
        const json = res.json();
        return Promise.all([status, json]);
    }).then(([status, data]) => {
        return { status, response: data };
    }).catch((error) => {
        
    });
};

export { HttpRequest };
