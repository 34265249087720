import React from 'react'
import { Box, Typography } from '@mui/material'
import "./index.css"
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ShoppingCartCheckoutOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import * as Icons from '@mui/icons-material';
const TopNavBar = () => {
    const template = useSelector((state) => state.template);
    const topNavBar = template?.topBar;
    const LeftSectionIcon = Icons[topNavBar?.leftSectionIcon ?? 'AddShoppingCart'];
    const MiddleSectionIcon = Icons[topNavBar?.middleSectionIcon ?? 'Notifications'];
    const socials = template?.socialMedia;
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const primaryColor = 'var(--primary)';
    const TikTokIcon = () => {
        return (
            <svg fill={primaryColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%" height="100%">
                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
            </svg>
        );
    };
    return (
        <Box className="topNav" sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
            <Box className="container">
                <Box className="topNavContent">
                    <Box className="topNav__left">
                        {LeftSectionIcon && <LeftSectionIcon sx={{ color: primaryColor }} />}
                        <Typography sx={{ fontSize: '14px' }}>{topNavBar?.leftSectionText}</Typography>
                    </Box>
                    <Box className="topNav__center">
                        {MiddleSectionIcon && <MiddleSectionIcon style={{ fontSize: "22px", color: primaryColor }} />}
                        <Typography sx={{ fontSize: '14px' }}>{topNavBar?.middleSectionText}</Typography>
                    </Box>
                    <Box className="topNav__right">
                        <Box className="topNav__right__social" sx={{ display: 'flex' }}>
                            {socials?.instagram !== '' && <InstagramIcon sx={{ fontSize: "22px", marginLeft: "20px", cursor: 'pointer', color: primaryColor }} onClick={() => { openInNewTab(socials?.instagram) }} />}
                            {socials?.facebook !== '' && <FacebookIcon sx={{ fontSize: "22px", marginLeft: "20px", cursor: 'pointer', color: primaryColor }} onClick={() => { openInNewTab(socials?.facebook) }} />}
                            {socials?.tikTok !== '' && <div style={{ width: "20px", fontSize: "22px", marginLeft: "20px", cursor: 'pointer', height: "25px" }} onClick={() => { openInNewTab(socials?.tikTok) }}>
                                <TikTokIcon style={{ fontSize: "22px", marginLeft: "20px", cursor: 'pointer', maxWidth: "40px", color: primaryColor }} />
                            </div>}
                            {socials?.x !== '' && <Icons.Twitter sx={{ fontSize: "22px", marginLeft: "20px", cursor: 'pointer', color: primaryColor }} onClick={() => { openInNewTab(socials?.x) }} />}
                            {socials?.linkedIn !== '' && <Icons.LinkedIn sx={{ fontSize: "22px", marginLeft: "20px", cursor: 'pointer', color: primaryColor }} onClick={() => { openInNewTab(socials?.linkedIn) }} />}
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default TopNavBar