import React, { useEffect, useState } from 'react';
import { Box, Checkbox, CircularProgress, Typography, ToggleButton, ToggleButtonGroup, Grid } from '@mui/material';
import Slider from '@mui/material/Slider';
import Header from '../header';
import Footer from '../footer';
import imgPlaceholder from '../../../images/img-placeholder.svg';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../../HttpRequest';
import { Check, KeyboardArrowDown, KeyboardArrowUp, SearchOff } from '@mui/icons-material';
import CurrencyFormatter from '../../common/currencyFormatter';
import capitalizeFirstLetter from '../../common/capitalize';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imagePlaceholder from '../../../images/img-holder-icn.svg';
import secureLocalStorage from 'react-secure-storage';
import noImagePlaceholder from '../../../images/No-Image-Placeholder.png';
import ProductItem from './productItem';

const AllproductsPage = () => {

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState({});
    const [selectedTags, setSelectedTags] = useState([]);
    const navigate = useNavigate();
    const [businessVariations, setBusinessVariations] = useState([]);
    const [businessTags, setBusinessTags] = useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = useState(10);
    const [minimum, setMinimum] = useState(0);
    const [maximum, setMaximum] = useState(0);
    const [showAll, setShowAll] = useState(false);

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const [showAllVariations, setShowAllVariations] = useState({});

    const handleToggleShowAllVariations = (variantId) => {
        setShowAllVariations(prevState => ({
            ...prevState,
            [variantId]: !prevState[variantId]
        }));
    };

    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };

    const currentUrl = window.location.href;
    const params = new URLSearchParams(new URL(currentUrl).search);
    const q = params.get('q');
    const categorySearch = params?.get('categoryName');

    const handleTagChange = (value) => {
        setSelectedTags(prevState => [...prevState, value]);
    };
    const handleToggleChange = (value, groupName) => {
        setSelectedVariant(prevState => ({
            ...prevState,
            [groupName]: value
        }));
    };

    const handleCheckboxChange = (value, groupName) => {
        setSelectedVariant(prevState => ({
            ...prevState,
            [groupName.toLowerCase()]: prevState[groupName.toLowerCase()] ? [...prevState[groupName.toLowerCase()], value] : [value]
        }));
    };


    const marks = [
        { value: minimum, label: '0' },
        { value: maximum, label: `${maximum}` }
    ];

    const [val, setVal] = useState([minimum, maximum]);
    const [priceRange, setPriceRange] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const handleChange = (event, newValue) => {
        setVal(newValue);
        setPriceRange(newValue);
    };

    const getScrollPosition = (e) => {
        let scrollableDiv = e.target;
        let scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
        let scrollHeight = scrollableDiv.scrollHeight;
        if (scrollPosition >= scrollHeight - 1 && !loading && products.length < totalCount) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const getValues = () => {
        setLoading(true);
        const businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get-max-minpricesinbs?businessNumber=${businessNumber}`,
        }).then((data) => {
            setMinimum(data?.response[0]?.minPrice);
            setMaximum(data?.response[0]?.maxPrice);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    }
    const getProducts = () => {
        setLoading(true)
        const businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&price=${priceRange[0]}&upperPrice=${priceRange[1]}&tags=${selectedTags.join(',')}&variationValue=${objectToString(selectedVariant)}&limit=${limit}&page=${page}${q ? "&search=" + q : ''}&categoryName=${categorySearch ?? ''}`,
        }).then(async (data) => {
            if (data?.status === 200 || data?.status === 201) {
                setLoading(false)
                let updatedData = await Promise.all(data.response.data.map(async (item) => {
                    const normalPrice = item?.pricing && item.pricing.length > 0 ? item.pricing[0].normalPrice : item?.productPrice;
                    const discountedPrice = item?.pricing && item.pricing.length > 0 ? item.pricing[0].discountedPrice : item?.productPrice;

                    const imageUrl = item.featuredImage ?? noImagePlaceholder;
                    const isValidImageUrl = imageUrl && imageUrl.trim() !== '';

                    return {
                        id: item?._id,
                        productName: item?.productName,
                        image: isValidImageUrl ? imageUrl : noImagePlaceholder,
                        normalPrice: normalPrice,
                       discountedPrice: discountedPrice,
                        pricing: item?.pricing
                    };
                }));
                setTotalCount(data?.response?.count);
                setProducts(prevProducts => page === 1 ? updatedData : [...prevProducts, ...updatedData]);

            } else {
                setLoading(false)
            }

        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    }
    const getVariations = () => {
        setLoading(true);
        const businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_business_variations?businessNumber=${businessNumber}`,
        }).then(async (data) => {
            setBusinessVariations(data.response.data);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    }
    const getTags = () => {
        setLoading(true);
        const businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/business/getTag?businessNumber=${businessNumber}`,
        }).then(async (data) => {
            setBusinessTags(data.response.data);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    }
    const objectToString = (obj) => {
        return Object.values(obj).join(',');
    };

    useEffect(() => {
        getProducts();
    }, [val, selectedTags, selectedVariant, page, q, priceRange, categorySearch])
    useEffect(() => {
        getVariations();
        getTags();
        getValues();
    }, [])
    const displayedTags = showAll ? businessTags : businessTags.slice(0, 3);

    const checkPreorder = (pricingObject) => {
        const now = new Date();

        const hasValidPreOrder = pricingObject?.some(item =>
            item.isPreOrderEnabled && new Date(item.preOrderdateEnd) > now && item?.preOrderStockLeft > 0
        );
        return hasValidPreOrder;
    };

    return (
        <Grid container spacing={2} columns={16} sx={{direction:'row',justifyContent:'space-between'}}>
            <Grid item xs={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ color: headingsColor, fontSize: "16px", fontWeight: "700" }}>{q ? q : 'All Products'} </Typography>
                <Typography sx={{ fontSize: '14px', color:bodyColor, fontWeight: 'normal' }}>
                    Showing {totalCount < 10 || (page * limit) > totalCount ? totalCount : page * limit} of {totalCount} Products
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, mb: '2%' }}>
                <Box sx={{margin:'22.5px 12.8px 23.5px 0px'}}>
                    <Box className="filter__title" sx={{ color: bodyColor, fontWeight: "900", fontSize: "16px" }}>Filters</Box>
                    {businessTags?.length > 0 &&
                        <Box >
                            <Box className="filter-brand">
                                <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "20px" }}> Tags </Typography>
                                <Box sx={{ display: "flex", marginTop: "5px", flexDirection: "column", justifyContent: 'flex-start', textAlign: 'left', pl: 0, ml: 0 }}>
                                    {displayedTags?.map((tag, index) => (
                                        <Box sx={{ display: "flex", alignItems: "start", justifyContent: 'flex-start' }}>
                                            <Checkbox style={{ color: bodyColor }} value={tag.tagName} onChange={(event) => handleTagChange(event.target.value)} />
                                            <Typography variant="h6" sx={{ color: bodyColor, fontSize: "14px", fontWeight: "normal", margin: '10px 0 5px 5px' }}>
                                                {capitalizeFirstLetter(tag.tagName)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                                {businessTags.length > 5 && (
                                    <Typography onClick={handleToggleShowAll} sx={{ color: bodyColor, fontSize: '14px', textTransform: 'capitalize', cursor: 'pointer' }}>
                                        {showAll ? (
                                            <Box sx={{ display: 'flex' }}>
                                                Show Less <KeyboardArrowUp sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: 'flex' }}>
                                                Show All <KeyboardArrowDown sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                                            </Box>
                                        )}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    }
                    <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "10px", mb: '10px' }}>Price Range</Typography>
                    <Box className="filter__price" sx={{ borderRadius: "4px", border: "solid 1px #cacaca", padding: "13.5px 9px 10.5px 9.5px", width: "100%", maxWidth: "300px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "129px" }}>
                        <Typography variant="h6" sx={{ color: bodyColor, fontSize: "9px" }}>Minimum price is KES {minimum} </Typography>
                        <div style={{}}>
                            <Slider marks={marks} step={1} value={val} valueLabelDisplay="none" min={minimum} max={maximum} onChange={handleChange} sx={{ '& .MuiSlider-track': { backgroundColor: primaryColor, color: primaryColor }, '& .MuiSlider-thumb': { backgroundColor: primaryColor }, '& .MuiSlider-markLabel': { color: primaryColor, display: "none" } }} />
                            <Typography variant="h6" sx={{ color: bodyColor, fontSize: "9px" }}>Range: KSh{val[0]}.00 -  KSh{val[1]}.00 </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="cancel-button" onClick={() => setVal([minimum, maximum])}>Cancel</span>
                            <span className="apply-button" onClick={() => setVal(val)}>Apply</span>
                        </div>
                    </Box>

                    {businessVariations?.map((variant) => (
                        <Box>
                            {variant.variationName.toLowerCase() === "color" ?
                                <Box className="filter-color">
                                    <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "20px" }}> Color </Typography>
                                    <Box style={{ display: "flex", marginTop: "10px" }}>
                                        {variant?.variationValues?.map((item, index) => (
                                            <ToggleButtonGroup exclusive value={selectedVariant?.color} key={index} style={{ display: "flex", alignItems: "center" }} onChange={(e, value) => handleToggleChange(value, "color")}>
                                                <ToggleButton style={{ width: "28px", height: "28px", backgroundColor: item, borderRadius: "3px", margin: "16.5px 8px 0 0" }} value={item} aria-label={item}>
                                                    {selectedVariant?.color?.toLowerCase() === item?.toLowerCase() && <Check sx={{ color: item.toLowerCase() === 'white' ? '#000' : '#fff', border: item.toLowerCase() === 'white' ? '1px solid #000' : '1px solid #fff' }} />}
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        ))}
                                    </Box>
                                </Box>
                                :
                                variant.variationName.toLowerCase() === "gender" ?
                                    <Box className="filter-gender">
                                        <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "20px" }}> Gender </Typography>
                                        <Box style={{ display: "flex", marginTop: "20px", textWrap: 'wrap' }}>
                                            {variant?.variationValues?.map((item, index) => (
                                                <ToggleButtonGroup exclusive key={index} value={selectedVariant?.gender} onChange={(e, value) => handleToggleChange(value, "gender")}>
                                                    <ToggleButton color='primary' sx={{ padding: "10.5px 20.2px 9.5px 20.8px", borderRadius: "3px", color: bodyColor, marginRight: "10px", fontWeight: 'normal', border: `solid 1px ${bodyColor}`, width: "auto", height: "auto", maxWidth: '115px', "&.Mui-selected": { color: "#fff", backgroundColor: primaryColor, "&:hover": { color: "#fff", backgroundColor: bodyColor } }, fontSize: '14px', textTransform: 'capitalize' }} value={item} aria-label={item}>{item.toLowerCase()}</ToggleButton>
                                                </ToggleButtonGroup>
                                            ))}
                                        </Box>
                                    </Box>
                                    :
                                    variant.variationName.toLowerCase() === "size" ?
                                        <Box>
                                            <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "20px" }}> Size </Typography>
                                            <Box style={{ display: "flex", marginTop: "20px", flexWrap: 'wrap', width: '100%', maxWidth: '400px' }}>
                                                {variant?.variationValues?.map((item, index) => (
                                                    <ToggleButtonGroup exclusive key={index} value={selectedVariant?.size} onChange={(e, value) => handleToggleChange(value, "size")}>
                                                        <ToggleButton sx={{ backgroundColor: '#fff', padding: "10.5px 20.2px 9.5px 20.8px", borderRadius: "3px", color: bodyColor, fontWeight: "normal", margin: "10px 10px 10px 0px", border: `solid 1px ${bodyColor}`, width: "auto", height: "auto", maxWidth: '115px', "&.Mui-selected": { color: "#fff", backgroundColor: primaryColor, "&:hover": { color: "#fff", backgroundColor: bodyColor } }, textTransform: 'capitalize' }} value={item} aria-label={item}>{item}</ToggleButton>
                                                    </ToggleButtonGroup>
                                                ))}
                                            </Box>
                                        </Box>
                                        :
                                        variant.variationName.toLowerCase() !== "default" ? (
                                            <Box className="filter-brand">
                                                <Typography variant="h6" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", paddingTop: "20px" }}> {variant.variationName} </Typography>
                                                <Box style={{ display: "flex", marginTop: "10px", flexDirection: "column" }}>
                                                    {variant?.variationValues?.slice(0, showAllVariations[variant._id] ? variant.variationValues.length : 3).map((item, index) => (
                                                        <Box key={index} style={{ display: "flex", alignItems: "left" }}>
                                                            <Checkbox
                                                                style={{ color: bodyColor }}
                                                                checked={selectedVariant?.variant?.variationName.toLowerCase()?.includes(item)}
                                                                value={item}
                                                                onChange={(event) => handleCheckboxChange(event.target.value, variant.variationName)}
                                                            />
                                                            <Typography variant="h6" sx={{ color: bodyColor, fontSize: "14px", fontWeight: "normal", margin: '5px 0 5px 10px' }}>
                                                                {item}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                                    {variant?.variationValues.length > 2 && (
                                                        <Typography onClick={() => handleToggleShowAllVariations(variant._id)} sx={{ color: bodyColor, fontSize: '14px', textTransform: 'capitalize' }}>
                                                            {showAllVariations[variant._id] ? 'Show Less' : 'Show All'}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        ) : null}
                        </Box>
                    ))}
                </Box>
            </Grid>
            <Grid onScroll={getScrollPosition}  container xs={12} sm={12} md={9} item style={{ height: "100%", maxHeight: '1065px', overflow: "scroll", scrollbarWidth: 'none' }}>
                {products?.map((product) => ( 
                    <Grid item xs={12} sm={6} md={4}>
                        <ProductItem product={product} image={product?.thumbailImagePath ?? product?.image} discountedPrice={product?.discountedPrice} normalPrice={product?.normalPrice} productId={product?.id} />
                    </Grid>
                ))}
                {(q || categorySearch) && !loading && products?.length === 0 && <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Box sx={{ width: '60%', textDecoration: 'none', backgroundColor: '#fff', padding: '20px 25px 17px 0px', height: '100%', }}>
                        <SearchOff sx={{ width: '80px', height: '80px', color: primaryColor }} />
                        <Typography sx={{ ml: '15px',color:bodyColor }}> There are no results for "{q ?? categorySearch}"</Typography>
                    </Box>
                </Grid>}
                {loading && <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', textDecoration: 'none', backgroundColor: '#fff', padding: '20px 25px 17px 26px', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex' }}>
                        <CircularProgress disableShrink /> <Typography sx={{ ml: '15px',color:bodyColor }}>  Loading...</Typography>
                    </Box>
                </Grid>}
            </Grid>
        </Grid>
    );
}

export default AllproductsPage;
