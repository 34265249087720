export const getOrderStatusColor = (orderStatus = 'Pending', deliveryMethod = 'ship') => {
    const statuses = {
        ship: {
            Received: '#707070',
            Processing: '#032541',
            ReadyToShip: '#032541',
            Shipped: '#000000',
            Delivered: '#17ae7b',
            Pending: '#dc3545',
        },
        pickup: {
            Received: '#707070',
            Processing: '#032541',
            ReadyToShip: '#032541',
            Shipped: '#000000',
            Delivered: '#17ae7b',
            Pending: '#dc3545',
        }
    };

    const deliveryStatuses = statuses[deliveryMethod];
    return deliveryStatuses?.[orderStatus] || '#dc3545'; // Return default color if not found
};
