import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        package:[],
        voucherPayload:[],
        checkoutVoucher:false,
    },
    reducers: {
        addToCart: (state, action) => {
            const { id, variationId, quantity = 1 } = action.payload;
            const existingItem = state.cart.find(
                (item) => item.id === id && item.variationId === variationId
            );
            if (existingItem) {
                existingItem.quantity += quantity;
            } else {
                state.cart.push({ ...action.payload, quantity });
            }
        },
        incrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            item.quantity++;
        },
        incrementInputQuantity: (state, action) => {
            const item = state.cart.find((product) => product.id === action.payload.id);
            if (item) {
                item.quantity += action.payload.value;
            }
        },
        decrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
        },
        removeItem: (state, action) => {
            const { itemId, variationId } = action.payload;
            const removeItem = state.cart.filter((item) => item.id !== itemId || item.variationId !== variationId);
            state.cart = removeItem;
        },        
        clearCart: (state) => {
            state.cart = [];
        },
        setPackage: (state, action) => {
           state.package=action.payload;
        },
        setVoucherPayload: (state, action) => {
            state.voucherPayload=action.payload;
        },
        setCheckoutVoucher: (state, action) => {
            state.checkoutVoucher=action.payload;
        },
    },
});

export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    clearCart,
    setPackage,
    incrementInputQuantity,
    setVoucherPayload,
    setCheckoutVoucher
} = cartSlice.actions;