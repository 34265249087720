import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    template: 'default',
    logo: {
        logoType: 'logo',
        logoPath: '',
        faviconPath: '',
        faviconGenerated: false,
        faviconFontFamily: '',
        logoText:'',
        slogan:'',
    },
    colors: {
        primary: '#000000',
        secondary: '#ffffff',
        tertiary: '#000000',
        quaternary: '#ff0000',
        headings: '#000000',
        body: '#ffffff',
    },
    typography: {
        headings: 'Poppins',
        body: 'Poppins',
    },
    buttons: {
        primary: '#000000',
        hover: '#000000',
        borderRadius: 5,
    },
    banners: {
        numberOfBanners: 0,
        path: [],
    },
    preOrderBadgeType: 'normal',
    disclaimer: {
        termsAndConditions:'',
        privaryPolicy:'',
        shippingPolicy:'',
        returnsAndExchange:'',
        faqs:''
    },
    topBar:{
        showTopBar: true,
        leftSectionIcon:'AddShoppingCart',
        leftSectionText:'Free shipping for Order of KES 10,000.00',
        middleSectionIcon:'Redeem',
        middleSectionText:'We have restocked! Get your order today.',
    },
    socialMedia:{
        facebook:'',
        instagram:'',
        x:'',
        linkedIn:'',
        tikTok:'',
    }
};

const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setTemplate(state, action) {
            state.template = action.payload;
        },
        setLogo(state, action) {
            state.logo = { ...state.logo, ...action.payload };
        },
        setColors(state, action) {
            state.colors = { ...state.colors, ...action.payload };
        },
        setTypography(state, action) {
            state.typography = { ...state.typography, ...action.payload };
        },
        setButtons(state, action) {
            state.buttons = { ...state.buttons, ...action.payload };
        },
        setBanners(state, action) {
            state.banners = { ...state.banners, ...action.payload };
        },
        setPreOrderBadgeType(state, action) {
            state.preOrderBadgeType = action.payload;
        },
        setDisclaimer(state, action) {
            state.disclaimer = { ...state.disclaimer, ...action.payload };
        },
        setTopBar(state, action) {
            state.topBar = { ...state.topBar, ...action.payload };
        },
        setSocialMedia(state, action) {
            state.socialMedia = { ...state.socialMedia, ...action.payload };
        },
    },
});

export const {
    setTemplate,
    setLogo,
    setColors,
    setTypography,
    setButtons,
    setBanners,
    setPreOrderBadgeType,
    setDisclaimer,
    setTopBar,
    setSocialMedia,
} = templateSlice.actions;

export default templateSlice.reducer;
