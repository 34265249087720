import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Divider, TextField, Typography, IconButton, Collapse, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { setPricingData } from '../../features/productSlice';

export default function PricingComponent(props) {
    const { variationData } = props;
    const dispatch = useDispatch();

    const generateCombinations = useCallback((variations, index = 0, currentCombination = {}) => {
        if (index === variations.length) {
            return [currentCombination];
        }
        const currentVariation = variations[index];
        return currentVariation.variationValues.flatMap(value => {
            const updatedCombination = { ...currentCombination, [currentVariation.variationName]: value };
            return generateCombinations(variations, index + 1, updatedCombination);
        });
    }, []);

    const [expanded, setExpanded] = useState({});
    const [pricing, setPricing] = useState({});
    const [copyValues, setCopyValues] = useState(false);

    const initializePricing = useCallback(() => {
        const combinations = generateCombinations(variationData);
        const initialPricing = {};
        combinations.forEach(combination => {
            const variationName = Object.values(combination).join(', ');
            initialPricing[variationName] = {
                initialStock: '',
                runningLowLevel: '',
                normalPrice: '',
                discountedPrice: '',
                buyPrice: '',
            };
        });
        setPricing(initialPricing);
    }, [generateCombinations, variationData]);

    useEffect(() => {
        initializePricing();
    }, [initializePricing]);

    const handlePricingChange = (variationName, field, value) => {
        setPricing(prevPricing => ({
            ...prevPricing,
            [variationName]: {
                ...prevPricing[variationName],
                [field]: value
            }
        }));
    };

    const handleExpand = (index) => {
        setExpanded(prevExpanded => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
    };

    const combinations = useMemo(() => generateCombinations(variationData), [variationData, generateCombinations]);

    useEffect(() => {
        if (Object.keys(pricing).length > 0) {
            const transformedPricing = Object.keys(pricing).map(variationKey => ({
                variationKey: variationKey,
                initialStock: pricing[variationKey].initialStock,
                runningLowLevel: pricing[variationKey].runningLowLevel,
                normalPrice: pricing[variationKey].normalPrice,
                discountedPrice: pricing[variationKey].discountedPrice,
                buyingPrice: pricing[variationKey].buyPrice
            }));

            dispatch(setPricingData(transformedPricing));
            props.receivingPricingData(transformedPricing);
        }
    }, [pricing]);

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setCopyValues(isChecked);

        if (isChecked) {
            const firstCombinationKey = Object.values(combinations[0]).join(', ');
            const firstValues = pricing[firstCombinationKey];

            setPricing(prevPricing => {
                const newPricing = { ...prevPricing };
                combinations.forEach(combination => {
                    const variationKey = Object.values(combination).join(', ');
                    if (variationKey !== firstCombinationKey) {
                        newPricing[variationKey] = { ...firstValues };
                    }
                });
                return newPricing;
            });
        }
    };

    return (
        <div>
            {combinations.map((combination, index) => {
                const variationKey = Object.values(combination).join(', ');
                return (
                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px", flexDirection: "column" }}>
                        <Divider />
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant='h6' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600", paddingTop: "10px" }}>
                                Variation: {variationKey}
                            </Typography>
                            <IconButton onClick={() => handleExpand(index)}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                        <Divider />
                        <Collapse in={expanded[index]} sx={{ backgroundColor: "#fafafa" }}>
                            <Box sx={{ marginTop: "25px", display: "flex", justifyContent: "space-between" }}>
                                <TextField
                                    label="Initial Stock"
                                    value={pricing[variationKey]?.initialStock || ''}
                                    onChange={e => handlePricingChange(variationKey, 'initialStock', e.target.value)}
                                    style={{ width: '100%', marginRight: "10px" }}
                                />
                                <TextField
                                    label="Running Low Level"
                                    value={pricing[variationKey]?.runningLowLevel || ''}
                                    onChange={e => handlePricingChange(variationKey, 'runningLowLevel', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                            <Box sx={{ marginTop: "25px", display: "flex", justifyContent: "space-between" }}>
                                <TextField
                                    label="Normal Price"
                                    value={pricing[variationKey]?.normalPrice || ''}
                                    onChange={e => handlePricingChange(variationKey, 'normalPrice', e.target.value)}
                                    style={{ width: '100%', marginRight: "10px" }}
                                />
                                <TextField
                                    label="Discounted Price"
                                    value={pricing[variationKey]?.discountedPrice || ''}
                                    onChange={e => handlePricingChange(variationKey, 'discountedPrice', e.target.value)}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                            <Box sx={{ marginTop: "25px", display: "flex", justifyContent: "space-between" }}>
                                <TextField
                                    label="Buy Price"
                                    value={pricing[variationKey]?.buyPrice || ''}
                                    onChange={e => handlePricingChange(variationKey, 'buyPrice', e.target.value)}
                                    style={{ width: '100%', marginRight: "10px" }}
                                />
                            </Box>
                        </Collapse>
                        {index === 0 && (
                            <Box style={{ display: "flex" }}>
                                <Checkbox
                                    style={{ color: "#b4bcc4", margin: '10px 6px 37px 0px' }}
                                    checked={copyValues}
                                    onChange={handleCheckboxChange}
                                />
                                <Typography sx={{ margin: '20px 6px 37px 12px', fontSize: '16px', color: '#707070', fontFamily: 'Poppins' }}>
                                    Copy Values to Other Variations
                                </Typography>
                               
                            </Box>
                        )}
                    </Box>
                );
            })}
        </div>
    );
}
