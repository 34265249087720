import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    isLoggedIn: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logInUser: (state, action) => {
            const { isLoggedIn, state: payloadState } = action.payload;
            state.isLoggedIn = isLoggedIn;
            state.state = payloadState;
        },
        logoutUser: (state) => {
            state.isLoggedIn = false;
        },
        changeToken: (state, action) => {
            const { token: token } = action.payload;
            state.token = token;
        },
    },
});

export const { logInUser, logoutUser, changeToken } = userSlice.actions;

export default userSlice.reducer;