import './App.css';
import { createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from './components/Dashboard';
import Orders from './components/Orders/Orders';
import NotFoundpage from './components/404.page';
import PublicRoutes from './components/layouts/PublicRoutes';
import PrivateRoutes from './components/layouts/PrivateRoutes';
import Products from './components/products/Products';
import AddProducts from './components/products/AddProduct';
import Categories from './components/Categories/Categories';
import HomePage from './components/customer/homePage';
import AddCategories from './components/Categories/AddCategory';
import EditCategory from './components/Categories/EditCategory';
import Login from './components/customer/auth/login';
import AllproductsPage from './components/customer/productsComponent/allproducts';
import Contacts from './components/customer/contacts';
import Blogs from './components/customer/blogs';
import Faqs from './components/customer/faqs';
import Cart from './components/customer/cart';
import Register from './components/customer/auth/register';
import ForgotPassword from './components/customer/auth/forgotPassword';
import PasswordReset from './components/customer/auth/passwordReset';
import PasswordChange from './components/customer/auth/passwordChange';
import ProductPage from './components/customer/productsComponent/productPage';
import OrderPaidSuccess from './components/Orders/orderPaidSuccess';
import AdditionalServices from './components/products/additionalServices';
import Checkout from './components/customer/checkout';
import TagsComponent from './components/tags/tagsComponent';
import VariationsComponent from './components/variations/variationsComponent';
import ShippingRegion from './components/deliveryMethods/shippingRegion';
import PickupRegions from './components/deliveryMethods/pickupRegions';
import ConfigurationZed from './components/configuration/configuration';
import AdminLogin from './components/customer/auth/admin/authLogin';
import CustomerPrivateRoutes from './components/layouts/CustomerPrivateRoutes';
import EditProduct from './components/products/EditProduct';
import CustomerAdminComponent from './components/customers/customerAdmin';
import { QrcodeComponent } from './components/reactBusinessQrcodeGenerate/reactQrcode';
import EditAdditionalServices from './components/products/editAdditionalServices';
import BusinessRoutes from './components/layouts/BusinessRoutes';
import OrderDetails from './components/Orders/OrderDetails';
import CustomerOrders from './components/customers/customerOrders';
import CustomerAllOrders from './components/customers/customerAllOrders';
import Reports from './components/reports/reportsActivity';
import { CustomerProfile } from './components/customer/profile/customerProfile';
import { useEffect } from 'react';
import PackagingFee from './components/products/packagingFee';
import PreOrders from './components/preorders/preorders';
import CustomerOrderDetails from './components/customer/profile/customerOrderDetails';
import CustomizeStore from './components/configuration/customizeStore';
import DynamicStyles from './components/common/DynamicStyles';
import { useSelector } from 'react-redux';
import TermsAndConditions from './components/customer/links/termsAndConditions';
import ReturnsAndExchanges from './components/customer/links/returnsAndExchanges';
import ShippingAndDelivery from './components/customer/links/shippingAndDelivery';
import PrivacyPolicy from './components/customer/links/privacyPolicy';
import EvouchersCampaigns from './components/evouchers/campaigns';
import PurchasedVocuhers from './components/evouchers/purchased';
import PurchasedDetails from './components/evouchers/purchasedDetails';
import EvoucherPage from './components/evouchers/evoucherPage';


const App = () => {
  const typography = useSelector((store) => store.template.typography);
  const theme = createTheme({
    typography: {
      fontFamily: typography?.body || 'Poppins, sans-serif',
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#dc3545",
            "&$error": {
              color: "#dc3545",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <DynamicStyles />
      <Router>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route element={<BusinessRoutes isNotAuthRoute={true} />}>
              <Route path="/" element={<HomePage />} />
              <Route path='/products' element={<AllproductsPage />} />
              <Route path='/evouchers' element={<EvoucherPage />} />
              <Route path='/product/:productId' element={<ProductPage />} />
              <Route path='/contacts' element={<Contacts />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/faqs' element={<Faqs />} />
              <Route path='/cart' element={<Cart />} />
              <Route path="*" element={<NotFoundpage />} />
              <Route path="/qrcode" element={<QrcodeComponent />} />

              <Route path='/terms' element={<TermsAndConditions />} />
              <Route path='/returns' element={<ReturnsAndExchanges />} />
              <Route path='/shipping' element={<ShippingAndDelivery />} />
              <Route path='/privacy' element={<PrivacyPolicy />} />
            </Route>
            <Route element={<BusinessRoutes isNotAuthRoute={false} />}>
              <Route path='/login'  element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/password/forgot' element={<ForgotPassword />} />
              <Route path='/password/reset' element={<PasswordReset />} />
              <Route path='/password/change' element={<PasswordChange />} />
            </Route>

            <Route path='/zed_ecommerce/admin/login' element={<AdminLogin />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/zed_ecommerce/dashboard" element={<Dashboard />} />
            <Route path="/zed_ecommerce/configurations" element={<ConfigurationZed />} />
            <Route path="/zed_ecommerce/evouchercompaings" element={<EvouchersCampaigns/>} />
            <Route path="/zed_ecommerce/evoucherpurchased" element={<PurchasedVocuhers/>} />
            <Route path="/zed_ecommerce/evoucher/:voucherId" element={<PurchasedDetails/>} />
            <Route path="/zed_ecommerce/additionalServices" element={<AdditionalServices />} />
            <Route path="/zed_ecommerce/packaging" element={<PackagingFee />} />
            <Route path="/zed_ecommerce/additionalServices/:serviceId/edit" element={<EditAdditionalServices />} />
            <Route path="/zed_ecommerce/orders" element={<Orders />} />
            <Route path="/zed_ecommerce/orders/:orderId/details" element={<OrderDetails />} />
            <Route path="/zed_ecommerce/products" element={<Products />} />
            <Route path="/zed_ecommerce/customers" element={<CustomerAdminComponent />} />
            <Route path="/zed_ecommerce/customer/:customerId/details" element={<CustomerOrders />} />
            <Route path="/zed_ecommerce/customer/:customerId/orders" element={<CustomerAllOrders />} />
            <Route path="/zed_ecommerce/reports" element={<Reports />} />
            <Route path="/zed_ecommerce/categories" element={<Categories />} />
            <Route path="/zed_ecommerce/addProducts" element={<AddProducts />} />
            <Route path="/zed_ecommerce/addCategories" element={<AddCategories />} />
            <Route path="/zed_ecommerce/categories/:categoryId/edit" element={<EditCategory />} />
            <Route path="/zed_ecommerce/products/:productId/edit" element={<EditProduct />} />
            <Route path="/zed_ecommerce/shippingRegions" element={<ShippingRegion />} />
            <Route path="/zed_ecommerce/pickups" element={<PickupRegions />} />
            <Route path="/zed_ecommerce/tags" element={<TagsComponent />} />
            <Route path="/zed_ecommerce/variations" element={<VariationsComponent />} />
            <Route path="/zed_ecommerce/preOrders" element={<PreOrders />} />
            <Route path="/zed_ecommerce/customizeStore" element={<CustomizeStore />} />
          </Route>

          <Route element={<CustomerPrivateRoutes />}>
            <Route path="/order/customer/:customerId/:orderId" element={<OrderPaidSuccess />} />
            <Route path='/zed-ecommerce/customer-profile' element={<CustomerProfile />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/customer/order/:orderId' element={<CustomerOrderDetails />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
