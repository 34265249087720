import { Box, Button, Grid, Typography, TextField, Modal, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos, Circle, EditOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomTable from "../common/CustomTable";
import CustomSearchInput from "../common/CustomSearchInput";
import warningIcon from '../../images/warning-remove-icn.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import secureLocalStorage from "react-secure-storage";

const deleteVariationModal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const VariationsComponent = () => {
  const [search, setSearch] = useState('');
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [showAddVariationModal, setShowAddVariationModal] = useState(false)
  const [showEditVariationModal, setShowEditVariationModal] = useState(false)
  const [variationData, setVariationData] = useState([])
  const [variationFormdata, setVariationFormdata] = useState({
    variationName: "",
    variationValues: "",
  })
  const [variation, setVariation] = useState({});
  const [openDeleteVariation, setOpenDeleteVariation] = useState(false)
  const handleOpenDeleteVariation = () => { setOpenDeleteVariation(true) }
  const handleCloseDeleteVariation = () => { setOpenDeleteVariation(false) }
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

  const handleFormData = (e) => {
    const { name, value } = e.target
    setVariationFormdata({ ...variationFormdata, [name]: value })
  }
  const handleRowClick = (event, params) => {
    event.stopPropagation();

    setVariationFormdata({
      variationName: params?.row?.variationName,
      variationValues: params?.row?.variationValues.join(','),
    });
    setVariation(params?.row);
    setShowEditVariationModal(true);
  }
  const columns = [
    {
      field: 'variationName', headerName: 'variation Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Variation Name </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Grid container spacing={2}>
            <Grid item>
              {params.row.variationName}
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'variationValues', headerName: 'variation Description', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> variation Description   </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params.row.variationValues.length > 0 && (
            <Box sx={{ display: "flex" }}>
              {params.row.variationValues.map((item, index) => (
                <>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box key={index} sx={{ minwidth: "57px", height: "34px", backgroundColor: "#efefef", borderRadius: "5px", padding: "5px 10px 5px 10px", marginLeft: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography variant="h6" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600" }}>
                        {item}
                      </Typography>
                    </Box>

                  </Box>
                </>
              ))}
            </Box>
          )}
        </Box>)
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
      renderCell: (params) => {
        const onClickEdit = (event) => {
          event.stopPropagation();

          setVariationFormdata({
            variationName: params?.row?.variationName,
            variationValues: params?.row?.variationValues.join(','),
          });
          setVariation(params?.row);
          setShowEditVariationModal(true);
        }
        const onClickDelete = (event) => {
          event.stopPropagation();
          setVariation(params.row);
          handleOpenDeleteVariation();
        }
        return (
          <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
            <EditOutlined onClick={(event)=>handleRowClick(event,params)} sx={{ color: '#032541', marginRight: '10px' }} />
            <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
          </Box>
        )
      }
    },
  ]
  const getvariationData = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_business_variations?businessNumber=${businessNumber}&searchValue=${search}`
    }).then((data) => {
      setPageState({
        ...pageState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false
      })
    }).catch((error) => {
    });
  }

  useEffect(() => {
    getvariationData()
  }, [search])

  const navigate = useNavigate();

  const handleVariationAddSubmit = () => {
    if (!variationFormdata.variationName) {
      setErrorShow({ state: true, message: "Variation name is required" });
      return;
    }
    if (!variationFormdata.variationValues) {
      setErrorShow({ state: true, message: "Values are  required" });
      return;
    }
    let newData
    let newVariationValues = variationFormdata.variationValues.split(',')
    newData = { variationName: variationFormdata.variationName, variationValues: newVariationValues }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/business/addBusiness_variations`,
      body: newData

    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Variation added successfully" });
        setShowAddVariationModal(false)
        getvariationData()
        setVariationFormdata({
          variationName: "",
          variationValues: "",
        })
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error adding shipping Region" });
    })
  }
  const handleVariationUpdateSubmit = () => {
    if (!variationFormdata.variationName) {
      setErrorShow({ state: true, message: "Variation name is required" });
      return;
    }
    if (!variationFormdata.variationValues) {
      setErrorShow({ state: true, message: "Values are  required" });
      return;
    }
    let newData
    let newVariationValues = variationFormdata.variationValues.split(',')
    newData = { variationName: variationFormdata.variationName, variationValues: newVariationValues }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/business/editBusiness_variations/${variation._id}`,
      body: newData
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Variation updated successfully" });
        setShowEditVariationModal(false)
        getvariationData()
        setVariationFormdata({
          variationName: "",
          variationValues: "",
        })
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error updating variation" });
    })
  }
  const handleDeleteVariation = (id) => {
    handleCloseDeleteVariation();
    HttpRequest({
      method: 'DELETE',
      url: `/api/v1/ecommerce/business/deleteBusiness_variations/${id}`,
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Variation deleted successfully" });
        setVariation({});
        getvariationData();
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error deleting variation" });
    })
  }
  return (
    <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
        <Box sx={{ flexDirection: "column" }}>
          <Box >
            {showAddVariationModal || showEditVariationModal ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowEditVariationModal(false); setShowAddVariationModal(false); }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", }}>Add Variation</Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", }}>
                Variations
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
              View and manage your Variations
            </Typography>
          </Box>
        </Box>
        {showAddVariationModal || showEditVariationModal ? null :
          <Box style={{ marginRight: "15px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => setShowAddVariationModal(true)}>
              Add Variation
            </Button>
          </Box>
        }
      </Box>
      {showAddVariationModal || showEditVariationModal ? null :
        <Box sx={{ display: 'flex' }}>
          <Box sx={{}}>
            <CustomSearchInput placeholder={"Search variation name ...."} name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
          </Box>
        </Box>
      }
      {showAddVariationModal ? <>
        <Box sx={{ width: "500px", }}>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <CustomInputLabel label={"Variation Name"} name={"variationName"} required={true} />
            <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
              Name is the attribute of the variation e.g Size, Color
            </Typography>
            <TextField label="Variation Name" value={variationFormdata?.variationName} name="variationName" onChange={handleFormData} style={{ width: "100%" }} />
          </Box>

          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <CustomInputLabel label={"variationValues"} name={"variationValues"} required={true} />
            <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
              Values are different attributes in the variation e.g Medium
            </Typography>
            <TextField label="variation Values" value={variationFormdata?.variationValues} name="variationValues" onChange={handleFormData} style={{ width: "100%" }} />
            <Box sx={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
                Separate multiple values with a comma “,” e.g Medium, Large
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
            <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowAddVariationModal(false)}>
              Cancel
            </Button>

            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleVariationAddSubmit}>
              Add Variation
            </Button>

          </Box>
        </Box>

      </> : ''
      }
      {showEditVariationModal ? <>
        <Box sx={{ width: "500px", }}>
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <CustomInputLabel label={"Variation Name"} name={"variationName"} required={true} />
            <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
              Name is the attribute of the variation e.g Size, Color
            </Typography>
            <TextField label="Variation Name" value={variationFormdata?.variationName} name="variationName" onChange={handleFormData} style={{ width: "100%" }} />
          </Box>

          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <CustomInputLabel label={"variationValues"} name={"variationValues"} required={true} />
            <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
              Values are different attributes in the variation e.g Medium
            </Typography>
            <TextField label="variation Values" value={variationFormdata?.variationValues} name="variationValues" onChange={handleFormData} style={{ width: "100%" }} />
            <Box sx={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
                Separate multiple values with a comma “,” e.g Medium, Large
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
            <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowEditVariationModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleVariationUpdateSubmit}>
              Update
            </Button>
          </Box>
        </Box>

      </> : ''
      }
      {showAddVariationModal || showEditVariationModal ? '' :
        <Box style={{ marginRight: "15px" }}>
          <DataGrid
            sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
            rowCount={pageState?.total}
            pagination
            loading={pageState?.isLoading}
            page={pageState?.page - 1}
            pageSizeOptions={[1, 10, 20, 50, 100]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: dataGridPageSize,
                  page: pageState?.page - 1,
                },
              },
            }}
            pageSize={dataGridPageSize}
            onPaginationModelChange={(data) => {
              const { pageSize, page } = data;
              setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
              setPageSize(pageSize)
            }}
            paginationMode="server"
            columns={columns}
            rows={pageState?.data}
            getRowId={(row) => row._id}
          />
        </Box>
      }
      <Modal open={openDeleteVariation} onClose={handleCloseDeleteVariation} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={deleteVariationModal}>
          <Box sx={{ padding: "10px" }}>
            <Box sx={{ display: 'flex' }}>
              <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
              <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                  Delete Variation?
                </Typography>
                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                  Selected variation will be deleted. This action cannot be undone.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Circle sx={{ width: '10px', height: '10px', margin: '1px 5px 6px 0', color: '#0c253f' }} />
                  <Typography sx={{ width: '121px', height: '20px', margin: "0 0 0 8px", fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#032541' }}>
                    {variation.variationName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
              <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteVariation} >Cancel</Button>
              <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => { handleDeleteVariation(variation._id) }}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </Modal>

    </div>
  )
}

export default VariationsComponent