import React, { useState, useEffect } from 'react';
import * as Icons from '@mui/icons-material';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

const MuiIconAutocomplete = ({ onSelect,defaultIcon }) => {
  const [iconOptions, setIconOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({label:defaultIcon,value:defaultIcon,iconComponent:Icons[defaultIcon]});
  
  const shoppingIconNames = [
    "ShoppingCart",
    "LocalMall",
    "LocalGroceryStore",
    "Store",
    "Storefront",
    "CreditCard",
    "Payment",
    "AttachMoney",
    "MonetizationOn",
    "Receipt",
    "Redeem",
    "CardGiftcard",
    "Loyalty",
    "AddShoppingCart",
    "AccountBalanceWallet",
    "Notifications"
  ];

  const createIcons = (icons) => {
    setIconOptions(icons.map(icon => ({
      label: icon,
      value: icon,
      iconComponent: Icons[icon]
    })));
  };

  useEffect(() => {
    const filteredIcons = shoppingIconNames.filter(icon => Icons[icon]);
    createIcons(filteredIcons);
  }, []);

  return (
    <Autocomplete
      style={{ width: "200px", height: "40px", padding: '10px 15px', borderRadius: '5px', backgroundColor: '#fff' }}
      id="outlined-select-meals"
      options={iconOptions}
      getOptionLabel={(option) => option.label ?? 'Icon'}
      value={selectedOption} 
      onChange={(event, newValue) => {
        setSelectedOption(newValue); 
        if (newValue && onSelect) {
          onSelect(newValue.value);
        }
      }}
      renderInput={(params) => {
        const IconComponent = selectedOption?.iconComponent;
        return (
          <TextField
            {...params}
            helperText=""
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                IconComponent ? (
                  <IconComponent style={{ marginRight: 8 }} />
                ) : null
              ),
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        const IconComponent = option.iconComponent;
        return (
          <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
            {IconComponent && <IconComponent sx={{ pr: 1 }} />}
            <Typography variant="body1">{option.label}</Typography>
          </Box>
        );
      }}
    />
  );
};

export default MuiIconAutocomplete;
