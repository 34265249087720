import React from "react";
import QRCode from 'react-qr-code'

export const QrcodeComponent = (props) => {
    const value = 'https://eastwestfashions.zed.business'
    const size = props?.size || 200
    return  (
        <div style={{margin: '8% 0 0 22px', padding: '25px 34px 136px 500px'}}> 
            <QRCode value={value} size={size}  />
        </div>
    )
}