import { Box, FormControl, Grid, InputLabel, OutlinedInput, Paper, TextField, Typography } from "@mui/material"
import RoomIcon from '@mui/icons-material/Room';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { ArrowBackIosNew } from "@mui/icons-material";
const CustomerAdressDetails = ({ customerDetails }) => {

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [apartment, setApartment] = useState('');

    const [addAddress, setAddAddress] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [countyError, setCountyError] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [apartmentError, setApartmentError] = useState(false);
    const [shippingRegionError, setShippingRegionError] = useState(false);
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [mpesaPhoneNumberError, setMpesaPhoneNumberError] = useState(false);
    const [mobileMoneyNumberError, setMobileMoneyNumberError] = useState(false);
    const [pickupLocationError, setPickupLocationError] = useState(false);

    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {!addAddress && <Box sx={{ width: '100%',maxWidth:{xs:'290px',sm:'700px',md:'100%'}, height: '100%', maxHeight: '582px', margin: '20px 23px 0 0px' }}>
                    <Typography sx={{ margin: '0 43px 24px 0', fontSize: '16px', fontWeight: '600', color: '#000' }}>Delivery Addresses</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', maxHeight: '20px', margin: '24px 20px 16px 0px',flexWrap:'wrap' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>My Addresses</Typography>
                        <Typography onClick={() => setAddAddress(true)} sx={{ fontSize: '14px', color: '#02b3ad', cursor: 'pointer' }}>Add New</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', maxWidth: '591px', height: 'auto', minHeight: '85px', margin: '16px 0', padding: '15px 20px 15px 20px', borderRadius: '10px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ display: 'flex' }}>
                            <RoomIcon sx={{ width: '30px', height: '30px', margin: '0px 23px 15px 0', objectFit: 'contain', color: '#92B1B1' }} />
                            <Box sx={{ width: '100%', maxWidth: '237px', height: '100%', maxHeight: '55px', margin: '0 24px 0 23px' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#000' }}>{customerDetails?.customerpreference?.city}</Typography>
                                <Typography sx={{ fontSize: '14px', color: '#898989', margin: '5px 0 0', }}>{customerDetails?.customerpreference?.address} {customerDetails?.customerpreference?.county} {customerDetails?.customerpreference?.postalCode} {customerDetails?.customerpreference?.apartment}</Typography>
                            </Box>
                        </Box>
                        <EditIcon sx={{ width: '25px', height: '25px', color: '#02b3ad' }} />
                    </Box>
                </Box>}
                {addAddress && <Box sx={{ width: '100%', height: '100%', maxHeight: '582px', margin: '20px 23px 0 16px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIosNew onClick={() => setAddAddress(false)} sx={{ width: '20px', height: '20px', margin: '0 2px 0 0', objectFit: 'contain', color: '#828283', cursor: 'pointer' }} />
                        <Typography sx={{ margin: '0 43px 0px 0', fontSize: '16px', fontWeight: 'bold', color: '#000' }}>Add address</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '591px', height: '100%', maxHeight: '20px', margin: '24px 0 16px' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>Add your home or office address.</Typography>
                    </Box>
                    <Grid container item xs={12}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '300px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }} required>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', } }} error={addressError} helperText={addressError ? 'Please enter address' : ''} value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="Physical Address" sx={{ backgroundColor: '#fff', border: addressError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '300px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', } }} error={cityError} helperText={cityError ? 'Please enter city' : ''} value={city} onChange={(e) => setCity(e.target.value)} required placeholder="City" sx={{ backgroundColor: '#fff', border: cityError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '300px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', } }} error={countyError} helperText={countyError ? 'Please enter county' : ''} value={county} onChange={(e) => setCounty(e.target.value)} required type="text" placeholder="County" sx={{ backgroundColor: '#fff', border: countyError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '300px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', } }} error={postalCodeError} helperText={postalCodeError ? 'Please enter postal code' : ''} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required type="text" placeholder="Postal code" sx={{ backgroundColor: '#fff', border: postalCodeError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '300px', sm: '340px', md: '300px', lg: '638px' }, height: '48px', margin: { xs: '6px 15px 11px 0px', sm: '6px 15px 11px 0px', md: '6px 15px 11px 0px', lg: '16px 15px 11px 0px' }, padding: { lg: '4px 26px 14px 0px' }, borderRadius: '5px', }}>
                                    <TextField error={apartmentError} helperText={apartmentError ? 'Please enter apartment' : ''} value={apartment} onChange={(e) => setApartment(e.target.value)} required type="text" placeholder="Apartment,Suite etc. (optional)" sx={{ backgroundColor: '#fff', border: apartmentError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>}
            </Grid>
        </Grid>
    )
}


export { CustomerAdressDetails };