import noRows from './images/No Rows.png'
const NoRowsOverlay = (props) => (
	<div>
		<p align={'center'} style={{marginTop : '5%'}}>
			<img src={noRows} alt="no-item" />
		</p>
		<p align={'center'}>
			<span style={{color : '#b4bcc4'}}>No Data to Display!</span>
		</p>
	</div>
);

export {NoRowsOverlay}