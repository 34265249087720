import { Box, Button, Grid, Typography, TextField, Modal, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos, Circle, EditOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomSearchInput from "../common/CustomSearchInput";
import warningIcon from '../../images/warning-remove-icn.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import secureLocalStorage from "react-secure-storage";

const deleteTagModal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const TagsComponent = () => {
  const [search, setSearch] = useState("");
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [showAddTagModal, setShowAddTagModal] = useState(false)
  const [showEditTagModal, setShowEditTagModal] = useState(false)
  const [tagDataData, setTagDataData] = useState({
    tagName: "",
    tagDescription: "",
  })
  const [tag, setTag] = useState({});
  const [openDeleteTag, setOpenDeleteTag] = useState(false)
  const handleOpenDeleteTag = () => { setOpenDeleteTag(true) }
  const handleCloseDeleteTag = () => { setOpenDeleteTag(false) }
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

  const handleFormData = (e) => {
    const { name, value } = e.target
    setTagDataData({ ...tagDataData, [name]: value })
  }
  const handleRowClick = (event, params) => {
    event.stopPropagation();
    setTagDataData({
      tagName: params?.row?.tagName,
      tagDescription: params?.row?.tagDescription,
    })
    setTag(params?.row);
    setShowEditTagModal(true);
  }
  const columns = [
    {
      field: 'tagName', headerName: 'Tag Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Tag Name </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)}  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Grid container spacing={2}>
            <Grid item>
              {params?.row?.tagName}
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'tagDescription', headerName: 'Tag Description', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Tag Description   </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)}  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params?.row?.tagDescription}
        </Box>)
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
      renderCell: (params) => {
        const onClickDelete = (event) => {
          event.stopPropagation();
          setTag(params?.row);
          handleOpenDeleteTag(true);
        }
        return (
          <Box  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", mt: '30px' }}>
            <EditOutlined onClick={(event)=>handleRowClick(event,params)}  sx={{ color: '#032541', marginRight: '10px' }} />
            <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
          </Box>
        )
      }
    },
  ]
  const getTagData = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/business/getTag?businessNumber=${businessNumber}&searchValue=${search}`
    }).then((data) => {
      setPageState((old) => ({
        ...old,
        isLoading: false,
        total: data?.response.count,
        data: data.response?.data,
      }));
    }).catch((error) => {
    });
  }

  useEffect(() => {
    getTagData()
  }, [search])


  const handleTagAddSubmit = () => {
    if (!tagDataData.tagName) {
      setErrorShow({ state: true, message: "Tag name is required" });
      return;
    }
    if (!tagDataData.tagDescription) {
      setErrorShow({ state: true, message: "Tag description is required" });
      return;
    }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/business/addTag`,
      body: tagDataData

    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Tag Name updated successfully" });
        setShowAddTagModal(false)
        getTagData()
        setTagDataData({
          tagName: "",
          tagDescription: "",

        })
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error adding shipping Region" });
    })
  }
  const handleTagUpdateSubmit = () => {
    if (!tagDataData.tagName) {
      setErrorShow({ state: true, message: "Location name is required" });
      return;
    }
    if (!tagDataData.tagDescription) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/business/editBusinessTag/${tag._id}`,
      body: tagDataData
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Tag Name updated successfully" });
        setShowEditTagModal(false)
        getTagData()
        setTagDataData({
          tagName: "",
          tagDescription: "",
        })
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error adding shipping Region" });
    })
  }
  const handleDeleteTag = (id) => {
    handleCloseDeleteTag();
    HttpRequest({
      method: 'DELETE',
      url: `/api/v1/ecommerce/business/deleteBusinessTag/${id}`,
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Tag deleted successfully" });
        setTag({});
        getTagData()
        setTagDataData({
          tagName: "",
          tagDescription: "",
        })
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error deleting tag" });
    })
  }
  return (
    <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
        <Box sx={{ flexDirection: "column" }}>
          <Box>
            {showAddTagModal ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowAddTagModal(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Add Tag
                </Typography>
              </Box>
            ) : showEditTagModal ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowEditTagModal(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Edit Tag
                </Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                Tags
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}>
              {showAddTagModal ? 'Create different tags' : showEditTagModal ? 'Edit tag details' : 'View and manage your Tags'}
            </Typography>
          </Box>
        </Box>
        {showAddTagModal || showEditTagModal ? null :
          <Box style={{ marginRight: "15px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => setShowAddTagModal(true)}>
              Add Tag
            </Button>
          </Box>
        }
      </Box>
      {showAddTagModal || showEditTagModal ? null :
        <Box sx={{ display: 'flex' }}>
          <Box sx={{}}>
            <CustomSearchInput placeholder={"Search tag name ...."} name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
          </Box>
        </Box>
      }
      {showAddTagModal ?
        <>
          <Box sx={{ width: "500px", }}>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Tag  Name" placeholder="Enter Tag Name " />
              <TextField label="Tag Name" value={tagDataData?.tagName} name="tagName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Tag Description" placeholder="Enter Tag  Description*" />
              <TextField name={"tagDescription"} value={tagDataData.tagDescription} onChange={handleFormData} placeholder={"Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
              <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowAddTagModal(false)}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleTagAddSubmit}>
                Add Tag
              </Button>
            </Box>
          </Box>
        </> : showEditTagModal ?
          <>
            <Box sx={{ width: "500px", }}>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Tag  Name" placeholder="Enter Tag Name " />
                <TextField label="Tag Name" value={tagDataData?.tagName} name="tagName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
              </Box>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Tag Description" placeholder="Enter Tag  Description*" />
                <TextField name={"tagDescription"} value={tagDataData.tagDescription} onChange={handleFormData} placeholder={"Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
                <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowEditTagModal(false)}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleTagUpdateSubmit}>
                  Update
                </Button>
              </Box>
            </Box>
          </>
          : <Box style={{ marginRight: "15px" }}>
            <DataGrid
              sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
              rowCount={pageState.total}
              pagination
              loading={pageState.isLoading}
              page={pageState.page - 1}
              pageSizeOptions={[1, 10, 20, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: dataGridPageSize,
                    page: pageState.page - 1,
                  },
                },
              }}
              pageSize={dataGridPageSize}
              onPaginationModelChange={(data) => {
                const { pageSize, page } = data;
                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                setPageSize(pageSize)
              }}
              paginationMode="server"
              columns={columns}
              rows={pageState.data}
              getRowId={(row) => row._id}
            />
          </Box>
      }
      <Modal open={openDeleteTag} onClose={handleCloseDeleteTag} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={deleteTagModal}>
          <Box sx={{ padding: "10px" }}>
            <Box sx={{ display: 'flex' }}>
              <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
              <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                  Delete Tag?
                </Typography>
                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                  Selected tag will be deleted. This <br /> action cannot be undone.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Circle sx={{ width: '10px', height: '10px', margin: '1px 5px 6px 0', color: '#0c253f' }} />
                  <Typography sx={{ textWrap: 'nowrap', width: '121px', height: '20px', margin: "0 0 0 8px", fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#032541' }}>
                    {tag.tagName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
              <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteTag} >Cancel</Button>
              <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => { handleDeleteTag(tag._id) }}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default TagsComponent