import { InputLabel, Typography } from '@mui/material';

const CustomInputLabel = ({ label,required }) => {
    const inputLabelAsterisk = {
        display: "flex",
        color: "#E54335",
        "&.MuiInputLabel-asterisk": {
            color: "#E54335",
        },
        textAlign: 'start',
        fontSize: "0.175rem",
        marginBottom: "-0.5rem",
    };

    const inputLabel = {
        color:' #032541',
        fontSize:"1.5rem"
    }
    return (
        <InputLabel sx={inputLabelAsterisk} required={required} position="start" shrink>
            <Typography sx={inputLabel}>{label}</Typography>
        </InputLabel>
    );
};

export default CustomInputLabel;
