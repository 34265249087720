import { Box, Grid } from '@mui/material'
import React from 'react'
import "./index.css"
import TopNavBar from './topNavBar';
import SearchNavBar from './searchBar';
import Navbar from './navbar';
import { useSelector } from 'react-redux';

const Header = () => {
    const template = useSelector((state) => state.template);
    const showTopNavBar = template?.topBar?.showTopBar;
    return (
        <Grid item>
            <Box sx={{ position: 'fixed', width: '100%', zIndex: 10, backgroundColor: '#fff' }}>
                {showTopNavBar && <Box>
                    <TopNavBar sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} />
                </Box>}
                <Box className="container">
                    <SearchNavBar />
                    <Navbar />
                </Box>
            </Box>
        </Grid>
    )
}

export default Header