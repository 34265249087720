import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material"
import VoucherImg from '../../images/product-main-img.png'
import cardImg from '../../images/cardimg.jpg'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
const EvoucherCardDetails = ({ data , setCheckout ,setVoucherData }) => {
    const [selectedVoucher, setSelectedVoucher] = useState();
    const [otherVoucherDetails, setOtherVoucherDetails] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    let { isLoggedIn } = useSelector((store) => store?.user);
    const theme = useTheme();
    const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const amountfound = data?.map((item) => item?.amounts?.map((voucher) => voucher));
    const disabled = !isSelected;
    const navigate = useNavigate();
    const handleSelectVoucher = (voucher , item) => {
        setIsSelected(true);
        setSelectedVoucher(voucher);
        setOtherVoucherDetails(item);
    }
    const payload = {
        voucherDetails:otherVoucherDetails,
        selectedVoucher:selectedVoucher,
    }
     const handleSubmit = () => {
        setCheckout(true);
        setIsSelected(false);
        setVoucherData(payload);
        window.scrollTo(0, 0)
     }
     const handleRedirect = () => {
        if(isLoggedIn){
            handleSubmit();
        }else{
            navigate('/login' , { state: { previousPath: '/evouchers' } , });
        }
     }
     useEffect(() => {
        if (data) {
            const firstItemWithAmounts = data?.find((item) => item?.amounts?.length === 1);
            if (firstItemWithAmounts) {
                const defaultVoucher = firstItemWithAmounts.amounts[0]; 
                setSelectedVoucher(defaultVoucher);
                setOtherVoucherDetails(firstItemWithAmounts);
                setIsSelected(true);
            }
        }
    }, [data]);
    
     

    return (
        <Grid container display={'flex'} width={'100%'} direction={'column'} pb={10} pt={10}>
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                <Grid container width={'100%'} gap={2}>
                    <Grid item xs={12} lg={3} md={6} sm={12} xl={3}>
                        <Box component={'div'} width={'100%'} sx={{display:'flex' ,height:'100%', justifyContent:isMuiMobile ? 'center' : 'flex-start'}}>
                            <Box component={'img'} src={VoucherImg} width={isMuiMobile ? '100%':'50%'} height={'100%'}  sx={{objectFit:'contain'}} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={8} md={6} sm={12} xl={8}>
                        <Box component={'div'} width={'100%'} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "10px" }}>
                            <Typography variant="h5" fontWeight={'bold'}>Evoucher</Typography>
                            <Typography variant="body1" fontWeight={400} sx={{ fontSize: '14px' }}>Description</Typography>
                            <Typography variant="body1" fontWeight={400} sx={{ fontSize: '14px', color: '#707070', lineHeight: 2  }}>Give the gift of choice with our eVouchers! Whether treating yourself or surprising someone special, our Evouchers offer the perfect way to explore our collection and pick out something truly loved. <strong style={{ color: '#000000' }}>Instant, flexible, and hassle-free; sent immediately to your email</strong>.</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#000000', fontSize: '14px' }}>Select Amount <strong style={{ color: '#ef0b0b' }}>*</strong></Typography>
                            <Box component="div" display="flex" gap={1} width={'100%'}>
                            {data?.map((item) => item?.amounts?.map((voucher) => (
                                <Box key={voucher?._id} component="div"  sx={{width:"80px" , display:'flex' , flexWrap:'wrap' , justifyContent:'center' , alignItems:'center'}}>
                                        <Button onClick={() => handleSelectVoucher(voucher , item)}  style={{
                                                    width:"100%",
                                                    backgroundColor:isSelected && selectedVoucher?._id === voucher?._id
                                                        ? '#02b3ad'
                                                        : '#ffffff', 
                                                    color:isSelected && selectedVoucher?._id === voucher?._id
                                                        ? '#ffffff'
                                                        : '#707070',
                                                    width: '100px',
                                                    border:isSelected && selectedVoucher?._id === voucher?._id ? '2px solid #02b3ad':'2px solid #bfbfbf'
                                                }}
                                            >
                                                {voucher?.voucherAmount}
                                            </Button>
                                        </Box>
                                    ))
                                )}
                            </Box>
                            <Box component="div" display="flex" mt={2} gap={1}>
                                <Button disabled={disabled} onClick={handleRedirect} variant="contained" sx={ disabled ? {backgroundColor: '#02b3ad', color: '#ffffff', width: '150px' , textTransform:'inherit' }:{ backgroundColor: '#02b3ad', color: '#ffffff', width: '150px' , textTransform:'inherit' }}>Pay Now</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EvoucherCardDetails