import { Box, Grid, Typography } from '@mui/material'
import TopNavBar from './topNavBar';
import "./index.css";
import Header from './header';
import Footer from './footer';
const Blogs = () => {
    return (
        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Grid  item spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} sx={{ textAlign: 'center'}}>
                    <Typography variant="h4" sx={{ textAlign: 'left', mb: '2%', color: "#343434", fontWeight: "500", fontSize: "20px" }}> Blogs </Typography>
                    <Typography sx={{fontSize: '18px', fontWeight: 'normal', color: '#000', lineHeight: 'normal', textWrap: 'wrap', mb: '50px' }}>
                        No blogs available Yet
                    </Typography>
                    <Typography sx={{fontSize: '16px', fontWeight: 'normal', color: '#707070', lineHeight: 'normal', textWrap: 'wrap' }}>
                        It looks like there are no blogs posted at the moment. Check back soon for the latest updates and insights!
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Blogs;