import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";

export const CustomizedDate = ({ style, setDateFrom, setDateTo }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    
    const gridFormatDate = (inputDate) => {
      // const today = new Date();
      const yyyy = new Date(inputDate)?.getFullYear();
      let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
      let dd = new Date(inputDate)?.getDate();
  
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
  
      const formattedToday = yyyy + "-" + mm + "-" + dd;
  
      return formattedToday;
    };
  
    useEffect(() => {
      const newStartDate = gridFormatDate(startDate);
      startDate ? setDateFrom(newStartDate) : setDateFrom("")
      const newEndDate = gridFormatDate(endDate);
      endDate ? setDateTo(newEndDate) : setDateTo("")
    }, [startDate, endDate]);
  
    return (
      <div style={style}>

          <DatePicker sx={{marginTop:'10px',mr:'10px',width:'250px'}} label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ margin: "0px 15px 0px 15px" }} />} />
          <br />
          <DatePicker sx={{marginTop:'10px',width:'250px'}} label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} />} style={{ margin: "0px 15px 0px 15px" }} />

      </div>
    );
  };