import { Box, CircularProgress, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import "./index.css"
import TopNavBar from './topNavBar';
import SearchNavBar from './searchBar';
import Navbar from './navbar';
import BannerPage from './banner';
import Products from './products';
import Footer from './footer';
import EvoucherBannerCounter from '../evouchers/evoucherCounterbanner';
import secureLocalStorage from 'react-secure-storage';
import { HttpRequest } from '../HttpRequest';
import { useSelector } from 'react-redux';

const HomePage = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [vouchers, setVouchers] = React.useState([]);
    const {evoucherEnabled} = useSelector((store) => store.configs);
    const getVoucherss = () => {
        setIsLoading(true);
        let businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/voucher_campaigns?businessNumber=${businessNumber}&limit=1&page=1`
        }).then(async (data) => {
            const voucherfound = data.response.data
            setVouchers(voucherfound);
        }).catch((error) => {
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getVoucherss();
    }, []);
    const [timeRemaining, setTimeRemaining] = React.useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const countDownDate = new Date(vouchers[0]?.endDate).getTime();
            const differenceTime = countDownDate - now;

            if (differenceTime < 0) {
                clearInterval(timer);
                setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            } else {
                const days = Math.floor(differenceTime / (1000 * 60 * 60 * 24)) ? Math.floor(differenceTime / (1000 * 60 * 60 * 24)) : 0;
                const hours = Math.floor((differenceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) ? Math.floor((differenceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0;
                const minutes = Math.floor((differenceTime % (1000 * 60 * 60)) / (1000 * 60)) ? Math.floor((differenceTime % (1000 * 60 * 60)) / (1000 * 60)) : 0;
                const seconds = Math.floor((differenceTime % (1000 * 60)) / 1000) ? Math.floor((differenceTime % (1000 * 60)) / 1000) : 0;

                setTimeRemaining({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [vouchers[0]?.endDate]);

    return (
        <Grid container spacing={2} >
            {isLoading &&
                <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                <CircularProgress />
            </Box>}
          {!isLoading && vouchers?.length > 0 && evoucherEnabled === true &&
                <Grid item xs={12} py={2}>
                    <EvoucherBannerCounter days={timeRemaining.days} hours={timeRemaining.hours} mins={timeRemaining.minutes} secs={timeRemaining.seconds} />
                </Grid>
            }
            <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: { xs: '350px', sm: '100%' } }}>
                    <BannerPage />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: { xs: '320px', sm: '700px', md: '100%' } }}>
                    <Products />
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomePage