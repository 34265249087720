import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import RenderBlock from './renderBlock';

const TermsAndConditions = () => {
  const template = useSelector((state) => state.template);

  const bodyColor = 'var(--body)';
  const headingsColor = 'var(--headings)';

  const rawContent = template?.disclaimer?.termsAndConditions;
  let decodedContent = {};
  if (rawContent){
     decodedContent = JSON.parse(rawContent);
  }

  return (
    <Grid sx={{ minHeight: '100vh' }}>
      <Box className="container">
        <Typography variant="h4" sx={{ textAlign: 'left', mb: '2%', color: headingsColor, fontWeight: '500', fontSize: '25px' }}>
          Terms And Conditions
        </Typography>
        <Box>
          {decodedContent?.blocks?.map((block) => (
            <Box key={block.key}>{RenderBlock(block,decodedContent)}</Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default TermsAndConditions;
