import { useSelector } from "react-redux";
import BannerOne from "../../images/banner1.png";
import BannerTwo from "../../images/banner2.png";
import BannerThree from "../../images/banner3.png";
import BannerFour from "../../images/banner4.png";
import BannerFive from "../../images/banner5.png";
import BannerSix from "../../images/banner6.png";
import zedLogo from '../../images/logo-upload@3x.png';

const useGetTemplates = () => {
    let { businessName } = useSelector((store) => store.configs);
    const { logo } = useSelector((state) => state.template);
    if(!businessName){
        businessName ='Zed';
    }
    if(logo?.logoText){
        businessName = logo?.logoText;
    }
    const templates = {
        'Zedify': {
            template: 'Zedify',
            logo: {
                logoType: 'text',
                logoPath: zedLogo,
                faviconPath: zedLogo,
                faviconGenerated: true,
                faviconFontFamily: 'Poppins',
                logoText: businessName?.charAt(0),
                slogan: `${businessName} eCommerce`,
            },
            colors: {
                primary:'#02B3AD',
                secondary:'#E0F4F3',
                tertiary: '#898989',
                quaternary: '#707070',
                headings: '#343434',
                body: '#707070',
            },
            typography: {
                headings: 'Poppins',
                body: 'Poppins',
            },
            buttons: {
                primary: '#02B3AD',
                hover: '#02B3AD',
                borderRadius: 5,
            },
            banners: {
                numberOfBanners: 6,
                path: [
                    { id: 0, url: BannerOne },
                    { id: 1, url: BannerTwo },
                    { id: 2, url: BannerThree },
                    { id: 3, url: BannerFour },
                    { id: 4, url: BannerFive },
                    { id: 5, url: BannerSix }
                ],
            },
            preOrderBadgeType: 'normal',
            disclaimer: {
                termsAndConditions: '',
                privaryPolicy: '',
                shippingPolicy: '',
                returnsAndExchange: '',
                faqs:''
            },
            topBar:{
                showTopBar: true,
                leftSectionIcon:'AddShoppingCart',
                leftSectionText:'Free shipping for Order of KES 10,000.00',
                middleSectionIcon:'Notifications',
                middleSectionText:'We have restocked! Get your order today.',
            },
            socialMedia:{
                facebook:'https://www.facebook.com/ZedPayments',
                instagram:'https://www.instagram.com/ZedPayments',
                x:'https://www.x.com/ZedPayments',
                linkedIn:'https://www.linkedin.com/ZedPayments',
                tikTok:'https://www.tiktok.com/ZedPayments',
            }
        },
        'Essence': {
            template: 'Essence',
            logo: {
                logoType: 'text',
                logoPath: zedLogo,
                faviconPath: zedLogo,
                faviconGenerated: true,
                faviconFontFamily: 'Roboto',
                logoText: businessName?.charAt(0),
                slogan: `${businessName} eCommerce`,
            },
            colors: {
                primary: '#FF5722', 
                secondary: '#FFCCBC', 
                tertiary: '#9E9E9E',
                quaternary: '#424242',
                headings: '#212121', 
                body: '#757575', 
            },
            typography: {
                headings: 'Roboto',
                body: 'Roboto',
            },
            buttons: {
                primary: '#FF5722',
                hover: '#E64A19', 
                borderRadius: 8, 
            },
            banners: {
                numberOfBanners: 1,
                path: [
                    { id: 0, url: BannerOne },
                ],
            },
            preOrderBadgeType: 'circular',
            disclaimer: {
                termsAndConditions: '',
                privaryPolicy: '',
                shippingPolicy: '',
                returnsAndExchange: '',
                faqs:''
            },
            topBar:{
                showTopBar: false,
                leftSectionIcon:'',
                leftSectionText:'',
                middleSection:'',
                middleSectionText:''
            },
            socialMedia:{
                facebook:'',
                instagram:'',
                x:'',
                linkedIn:'',
                tikTok:'',
            }
        }
    };

    return templates;
};

export default useGetTemplates;
