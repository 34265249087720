import { Box, Typography } from "@mui/material";

const RenderBlock = (block,decodedContent) => {

    const { text, type, inlineStyleRanges, entityRanges } = block;
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    let styledText = text;
    inlineStyleRanges.forEach((range) => {
      if (range.style === 'BOLD') {
        const before = styledText.slice(0, range.offset);
        const boldText = styledText.slice(range.offset, range.offset + range.length);
        const after = styledText.slice(range.offset + range.length);
        styledText = (
          <>
            {before}
            <strong>{boldText}</strong>
            {after}
          </>
        );
      }
    });

    switch (type) {
      case 'header-two':
        return (
          <Typography variant="h6" sx={{ textAlign: 'left', color: headingsColor, fontWeight: 'bold', marginTop: '16px' }}>
            {styledText}
          </Typography>
        );
      case 'unordered-list-item':
        return (
          <Typography
            component="li"
            sx={{ textAlign: 'left', color: bodyColor, marginLeft: '20px', fontSize: '14px' }}
          >
            {styledText}
          </Typography>
        );
      case 'atomic':
        if (entityRanges.length > 0) {
          const entityKey = entityRanges[0].key;
          const entityData = decodedContent.entityMap[entityKey]?.data;

          if (entityData && entityData.url) {
            return (
              <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
                <img src={entityData.url} alt="Content" style={{ maxWidth: '100%', height: 'auto' }} />
              </Box>
            );
          }
        }
        return null;
      default:
        return (
          <Typography sx={{ textAlign: 'left', color: bodyColor, fontSize: '14px', marginTop: '8px' }}>
            {styledText}
          </Typography>
        );
    }
  };

  export default RenderBlock;