import { Box, CircularProgress, Grid } from "@mui/material"
import EvoucherBannerCounter from "./evoucherCounterbanner"
import EvoucherCardDetails from "./evoucherCardDetails"
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { HttpRequest } from "../HttpRequest";
import EvoucherCheckout from "./evoucherCheckout";

const EvoucherPage = () => {
    const [checkout, setCheckout] = useState(false);
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [voucherData, setVoucherData] = useState(null);


    const getVoucherss = () => {
        setLoading(true);
        let businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/voucher_campaigns?businessNumber=${businessNumber}&limit=1&page=1`
        }).then(async (data) => {
            const voucherfound = data.response.data
            setVouchers(voucherfound);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getVoucherss();
    }, []);

    const [timeRemaining, setTimeRemaining] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });


    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date().getTime();
            const countDownDate = new Date(vouchers[0]?.endDate).getTime();
            const differenceTime = countDownDate - now;

            if (differenceTime < 0) {
                clearInterval(timer);
                setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            } else {
                const days = Math.floor(differenceTime / (1000 * 60 * 60 * 24));
                const hours = Math.floor((differenceTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((differenceTime % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((differenceTime % (1000 * 60)) / 1000);

                setTimeRemaining({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [vouchers[0]?.endDate]);



    return (
        <Grid container display={'flex'} width={'100%'} direction={'column'} height={'100%'} pb={10}>
            <Grid item xs={12}>
                {loading && (
                    <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Grid>
            <Grid item xs={12}>
                {!checkout && !loading && vouchers.length > 0 &&
                    <EvoucherBannerCounter days={timeRemaining.days} hours={timeRemaining.hours} mins={timeRemaining.minutes} secs={timeRemaining.seconds} />
                }
            </Grid>
            <Grid item xs={12}>
                {!checkout && !loading && vouchers.length > 0 ? (
                    <EvoucherCardDetails data={vouchers} checkout={checkout} setVoucherData={setVoucherData} setCheckout={setCheckout} />
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {checkout && !loading &&
                    <EvoucherCheckout voucherData={voucherData} setVoucherData={setVoucherData} setCheckout={setCheckout} />
                }
            </Grid>
        </Grid>
    )
}
export default EvoucherPage