import { Navigate, Outlet } from 'react-router-dom';
import {Box}  from '@mui/material'

const PublicRoutes = () => {
    let loggedIn = false;

 
return (
    <Box component={'div'} width={'100%'} sx={{minHeight:'100vh'}}>
       { loggedIn ? <Navigate to='/zed_ecommerce/dashboard' />: <Outlet /> }
    </Box>
  )
};

export default PublicRoutes;
