import { ArrowBackIos, ArrowForwardIos, ShowChart, TrendingDown, TrendingUp } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, LinearProgress, MenuItem, Select, Tab, Typography, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import imagePlaceHolder from './../../images/img-placeholder.svg';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomTable from "../common/CustomTable";
import CurrencyFormatter from "../common/currencyFormatter";
import salesIcon from "../../images/sales-icon.svg";
import billsIcon from "../../images/bills-icon.svg";
import Slider from "react-slick";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import DateFormatter from "../common/dateFormatter";
import CustomSelect from "../common/customSelectInput";
import { startOfToday, startOfYesterday, endOfToday, endOfYesterday, subDays, startOfMonth, endOfMonth, subMonths, format, parse } from 'date-fns';
import { CustomizedDate } from "../common/customDate";
import ExportData from "../common/exportData";

const getLastMonthRange = (date) => {
  const previousMonth = subMonths(date, 1);
  const start = startOfMonth(previousMonth);
  const end = endOfMonth(previousMonth);
  return { start, end };
};

const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "#fff" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none", maxWidth: '200px', [theme.breakpoints.up("sm")]: { minWidth: 0 }, textWrap: 'nowrap', padding: '13px 14px 12px', width: 'auto',
  fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
  fontSize: "16px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
  letterSpacing: "normal", textAlign: "left", color: "#707070", fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#fff", backgroundColor: '#032541' },
  "&.Mui-focusVisible": { backgroundColor: "none" },
}));


const Reports = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('Analytics');
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], salesPerProductData: [], total: 0, page: 1, pageSize: dataGridPageSize, });

  const [transactionsState, setTransactionsState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 10 });
  const [salesPerProductState, setSalesPerProductState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 10 });
  const [customersState, setCustomersState] = useState({ isLoading: false, data: [], returningColumns: [], total: 0, page: 1, pageSize: 10 });
  const [salesData, setSalesData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [range, setRange] = useState('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [customerType, setCustomerType] = useState('');
  const [orderType, setOrderType] = useState('');
  const [dataToExport, setDataToExport] = useState([]);
  const [dataToExportSalesByProducts, setDataToExportSalesByProducts] = useState([]);
  const [dataToExportTransactions, setDataToExportTransactions] = useState([]);
  const [exportTitle, setExportTitle] = useState('');
  const [tableColumns, setTableColumns] = useState([
    {
      field: 'customerName',
      headerName: 'Customer Name',
    },
    {
      field: 'noOfOrders',
      headerName: 'Number of Orders',
    },
    {
      field: 'totalAmountOfOrders',
      headerName: 'Total Amount of Orders',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
    },
  ]);
  const [tableColumnsProducts, setTableColumnsProducts] = useState([
    {
      field: 'productName',
      headerName: 'Product Name',
    },
    {
      field: 'totalSales',
      headerName: 'Total Sales',
    },
    {
      field: 'totalQuantity',
      headerName: 'Total Quantity',
    },
  ]);
  const [tableColumnsTransactions, setTableColumnsTransactions] = useState([
    {
      field: 'orderNumber',
      headerName: 'Order Number',
    },
    {
      field: 'transactionType',
      headerName: 'Transaction Type',
    },
    {
      field: 'transactionID',
      headerName: 'Transaction ID',
    },
    {
      field: 'transamount',
      headerName: 'Transaction Amount',
    },
    {
      field: 'date',
      headerName: 'Date',
    },
    {
      field: 'totalProducts',
      headerName: 'No. of Products',
    },
    {
      field: 'shippingAmount',
      headerName: 'Shipping Amount',
    }
  ]);
  const types = [
    { id: 3, label: 'Orders', value: false },
    { id: 4, label: 'Pre-Orders', value: true },
  ];

  const [topSellingProducts, setTopSellingProducts] = useState([{
    "_id": "MPESA",
    "total": 0,
    "count": 0
  }]);
  const [salesChannels, setSalesChannels] = useState([
    {
      "total": 0,
      "count": 0
    }
  ]);
  const [totalSales, setTotalSales] = useState([
    {
      "total": 0,
      "count": 0
    }
  ]);
  const [totalOrders, setTotalOrders] = useState([
    {
      "total": 0,
      "count": 0
    }
  ]);
  const [totalShipping, setTotalShipping] = useState([
    {
      "total": 0,
      "count": 0,
      "percentageTotal": 0
    }
  ]);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const columns = [
    {
      field: 'customerName', headerName: 'Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Name  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "550" }}>
          <Typography sx={{ marginTop: '20px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 550, textAlign: 'left', color: '#000', textDecoration: 'underline' }}>{params?.row?.customerName}</Typography>
        </Box>)
      }
    },
    {
      field: 'email', headerName: 'Email', flex: 1.5,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Email  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.email}</Typography>
        </Box>)
      }
    },
    {
      field: 'phone', headerName: 'Phone No.', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Phone No.</Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.phone}</Typography>
        </Box>)
      }
    },
    {
      field: 'totalAmountOfOrders', headerName: 'Amount Spent', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Amount Spent  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> Ksh {params?.row?.totalAmountOfOrders} </Box>) }
    },
    {
      field: 'firstOrderDate', headerName: 'First Order Date', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Action </Box>) },
      renderCell: (params) => {
        return (<Box onClick={() => navigate(`/zed_ecommerce/customer/${params?.row?._id}/orders`)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px', textDecoration: 'underline', fontWeight: 550, fontFamily: 'Poppins', color: '#000', fontSize: '14px' }}>View Order</Typography>
        </Box>)
      }
    },

  ]
  const returningColumns = [
    {   
      field: 'customerName', headerName: 'Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Name  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "550" }}>
          <Grid container spacing={2} sx={{ display: 'flex' }}>
            <Grid item>
              <Typography sx={{ marginTop: '0 42px 0 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 550, textAlign: 'left', color: '#000', textDecoration: 'underline' }}>{params?.row?.customerName}</Typography>
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'email', headerName: 'Email', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Email  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.email}</Typography>
        </Box>)
      }
    },
    {
      field: 'phone', headerName: 'Phone No.', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Phone No.</Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.phone}</Typography>
        </Box>)
      }
    },
    {
      field: 'noOfOrders', headerName: 'Orders', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Orders </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => navigate(`/zed_ecommerce/customer/${params?.row?._id}/orders`)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px', textDecoration: 'underline', fontWeight: 550, color: '#000', fontSize: '14px' }}>{params?.row?.noOfOrders} Orders</Typography>
        </Box>)
      }
    },
    {
      field: 'totalAmountOfOrders', headerName: 'Amount Spent', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Amount Spent  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> Ksh {params?.row?.totalAmountOfOrders} </Box>) }
    },
  ]
  const salesPerChannel = [
    {
      field: '_id', headerName: 'Payment Mode', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Payment Mode  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?._id}</Typography>
        </Box>)
      }
    },
    {
      field: 'count', headerName: 'Quantity', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Total Quantity</Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.count}</Typography>
        </Box>)
      }
    },
    {
      field: 'total', headerName: 'Total Sales', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Total Sales  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> {CurrencyFormatter(params?.row?.total)} </Box>) }
    },
  ]
  const salesPerProductColumns = [
    {
      field: 'productName', headerName: 'Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Name  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.productName}</Typography>
        </Box>)
      }
    },
    {
      field: 'totalQuantity', headerName: 'Quantity', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Quantity</Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.totalQuantity}</Typography>
        </Box>)
      }
    },
    {
      field: 'totalSales', headerName: 'Total Sales', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Total Sales  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> {CurrencyFormatter(params?.row?.totalSales)} </Box>) }
    },
  ]
  const monthlySalesColumns = [
    {
      field: 'month', headerName: 'Month', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Month  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.month}</Typography>
        </Box>)
      }
    },
    {
      field: 'totalSales', headerName: 'Total Sales', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Total Sales</Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.totalSales}</Typography>
        </Box>)
      }
    },
  ]
  const transactionsColumns = [
    {
      field: 'transactionID', headerName: 'TXN ID', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> TXN ID  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.transactionID}</Typography>
        </Box>)
      }
    },
    {
      field: 'orderNumber', headerName: 'Order No.', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Order No.</Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.orderNumber}</Typography>
        </Box>)
      }
    },
    {
      field: 'transtime', headerName: 'TXN Date', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> TXN Date </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> {DateFormatter(params?.row?.transtime)} </Box>) }
    },
    {
      field: 'totalProducts', headerName: 'No of Products', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> No of Products  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.totalProducts}</Typography>
        </Box>)
      }
    },
    {
      field: 'shippingFees', headerName: 'Shipping Fees', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Shipping Fees</Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{CurrencyFormatter(params?.row?.shippingFees ?? 0)}</Typography>
        </Box>)
      }
    },
    {
      field: 'transamount', headerName: 'Total Sales', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Total Sales  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> {CurrencyFormatter(params?.row?.transamount)} </Box>) }
    },
  ]
  const getSalesPerProduct = () => {
    setSalesPerProductState((old) => ({ ...old, isLoading: true }));
    const url = `/api/v1/ecommerce/getall-salesbyproduct`;
    HttpRequest({
      method: 'GET',
      url: `${url}?page=${salesPerProductState?.page}&limit=${salesPerProductState?.pageSize}&startDate=${from ?? ''}&endDate=${to ?? ''}`,
    }).then((data) => {
      setSalesPerProductState({
        ...salesPerProductState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false,
      });
      getDataToExportProducts(`${url}?page=${salesPerProductState?.page}&limit=${data?.response?.count}&startDate=${from ?? ''}&endDate=${to ?? ''}`);

    }).catch((error) => {
      setSalesPerProductState((old) => ({ ...old, isLoading: false }));
    });
  };

  const getTransactions = () => {
    setTransactionsState((old) => ({ ...old, isLoading: true }));
    const url = `/api/v1/ecommerce/admin-get-transactions`;
    HttpRequest({
      method: 'GET',
      url: `${url}?page=${transactionsState.page}&limit=${transactionsState.pageSize}&startDate=${from ?? ''}&endDate=${to ?? ''}&isPreOrder=${orderType}`,
    }).then((data) => {
      setTransactionsState({
        ...transactionsState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false,
      });
      getDataToExportTransactions(`${url}?page=${transactionsState.page}&limit=${data?.response?.count}&startDate=${from ?? ''}&endDate=${to ?? ''}&isPreOrder=${orderType}`);
    }).catch((error) => {
      setTransactionsState((old) => ({ ...old, isLoading: false }));
    });
  };

  const getCustomers = () => {
    setCustomersState((old) => ({ ...old, isLoading: true }));
    const url = `/api/v1/ecommerce/getzedecommercecustomers`;
    HttpRequest({
      method: 'GET',
      url: `${url}?page=${customersState?.page}&limit=${customersState?.pageSize}&customerType=${customerType}`,
    }).then((data) => {
      setCustomersState({
        ...customersState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false,
      });
      getDataToExport(`${url}?page=${customersState?.page}&limit=${data?.response?.count}&customerType=${customerType}`);
    }).catch((error) => {
      setCustomersState((old) => ({ ...old, isLoading: false }));
    });
  };
  const getDataToExport = (url) => {
    return HttpRequest({
      method: 'GET',
      url: url
    }).then((data) => {
      const filteredCustomers = data?.response?.data?.map(({ _id, customerId, businessNumber, ...rest }) => ({
        ...rest,
      }));
      setDataToExport(filteredCustomers);
      return filteredCustomers;
    }).catch((error) => {
      throw error;
    });
  };
  const getDataToExportProducts = async (url) => {
    try {
      const data = await HttpRequest({
        method: 'GET',
        url: url
      });
      const filteredProducts = data?.response?.data?.map(({ _id, ...rest }) => ({
        ...rest,
      }));
      setDataToExportSalesByProducts(filteredProducts??salesPerProductState?.data);
      return data?.response?.data;
    } catch (error) {
      throw error;
    }
  };
  const getDataToExportTransactions = (url) => {
    return HttpRequest({
      method: 'GET',
      url: url
    }).then((data) => {
      const filteredTransactions = data?.response?.data?.map(({ _id, pushyId, transtime, businessNo, ...rest }) => ({
        ...rest,
        date: DateFormatter(transtime),
      }));
      setDataToExportTransactions(filteredTransactions);
      return filteredTransactions;
    }).catch((error) => {
      throw error;
    });
  };

  const getTopSellingProducts = () => {
    setPageState({
      ...pageState,
      data: [],
      total: 0,
      isLoading: true
    })
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getzedecommerce-top-selling-products`
    }).then((data) => {
      setTopSellingProducts(data?.response);
    }).catch((error) => {
    });
  }
  const getTopSalesAnalytics = () => {
    setPageState({
      ...pageState,
      data: [],
      total: 0,
      isLoading: true
    })
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getzedecommercesalesanalytics`
    }).then((data) => {
      setSalesChannels(data?.response?.salesByChannel);
      setTotalSales(data?.response?.totalSales);
      setTotalOrders(data?.response?.totalOrders);
    }).catch((error) => {
    });
  }
  const getShippingTotals = () => {
    setPageState({
      ...pageState,
      data: [],
      total: 0,
      isLoading: true
    })
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getzedecommerce-shipping-totals`
    }).then((data) => {
      setTotalShipping(data?.response?.data);
    }).catch((error) => {
    });
  }
  useEffect(() => {
    getTopSellingProducts();
    getTopSalesAnalytics();
    getShippingTotals();
    salesActivity();
  }, []);

  useEffect(() => {
    getTransactions();
  }, [transactionsState?.page, transactionsState?.pageSize, from, to, orderType]);
  useEffect(() => {
    getCustomers();
  }, [customersState?.page, customersState?.pageSize, customerType]);

  useEffect(() => {
    getSalesPerProduct();
  }, [salesPerProductState.page, salesPerProductState.pageSize, from, to]);

  const handleChangeFilter = (event) => {

  };
  const handleRowClick = (event, params) => {
    event.stopPropagation();
    navigate(`/zed_ecommerce/customer/${params?.row?._id}/details`);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px', mr: '20px',verticalAlign:'middle' }}>
        {tabValue === 'Transactions' && <Box sx={{ mr: '2%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <CustomSelect sx={{ mr: '10px', mt: '8px' }} value={orderType} placeholder='Order Type' options={types} sm={"xs"} onChange={(e) => setOrderType(e.target.value)} />
        </Box>}
        {(tabValue === 'Transactions' || tabValue === 'SalesByProducts') &&
          <Box sx={{ mr: '2%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <CustomSelect
              sx={{ mr: '10px', mt: '8px' }}
              value={range}
              options={ranges}
              sm={"lg"}
              onChange={(e) => setRange(e.target.value)}
            />
            {range === 'customDate' && (
              <CustomizedDate setDateFrom={setFrom} setDateTo={setTo} style={{ display: "flex", alignItems: "center" }} />
            )}
          </Box>}
        <ExportData
          data={
            tabValue === 'OneTimeCustomers' || tabValue === 'ReturningCustomers'
              ? dataToExport
              : tabValue === 'SalesByProducts'
                ? dataToExportSalesByProducts
                : tabValue === 'Transactions'
                  ? dataToExportTransactions
                  : null
          }
          tableColumn={
            tabValue === 'OneTimeCustomers' || tabValue === 'ReturningCustomers'
              ? tableColumns
              : tabValue === 'SalesByProducts'
                ? tableColumnsProducts
                : tabValue === 'Transactions'
                  ? tableColumnsTransactions
                  : null
          }
          title={exportTitle}
        />

      </GridToolbarContainer>
    );
  }
  const salesActivity = async () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/zed_ecommerce_sales_activity?year=${year}`,
      body: null,
    }).then((data) => {
      if (data.status === 200) {
        setSalesData(data?.response?.data);
      }
    }).catch((error) => {
    })
  }
  const ranges = [
    { id: 1, label: 'Select Date', value: '' },
    { id: 2, label: 'Today', value: 'today' },
    { id: 3, label: 'Yesterday', value: 'yesterday' },
    { id: 4, label: 'Last 7 Days', value: 'oneWeek' },
    { id: 5, label: 'This Month', value: 'thisMonth' },
    { id: 6, label: 'Last Month', value: 'lastMonth' },
    { id: 7, label: 'Custom Range', value: 'customDate' },
  ];
  useEffect(() => {
    const updateDates = (range) => {
      const today = new Date();
      let fromDate = null;
      let toDate = null;

      switch (range) {
        case 'today':
          fromDate = format(startOfToday(), 'MM/dd/yyyy');
          toDate = format(endOfToday(), 'MM/dd/yyyy');
          break;
        case 'yesterday':
          fromDate = format(startOfYesterday(), 'MM/dd/yyyy');
          toDate = format(endOfYesterday(), 'MM/dd/yyyy');
          break;
        case 'oneWeek':
          fromDate = format(subDays(today, 7), 'MM/dd/yyyy');
          toDate = format(endOfToday(), 'MM/dd/yyyy');
          break;
        case 'thisMonth':
          fromDate = format(startOfMonth(today), 'MM/dd/yyyy');
          toDate = format(endOfMonth(today), 'MM/dd/yyyy');
          break;
        case 'lastMonth':
          ({ start: fromDate, end: toDate } = getLastMonthRange(today));
          fromDate = format(fromDate, 'MM/dd/yyyy');
          toDate = format(toDate, 'MM/dd/yyyy');
          break;
        default:
          fromDate = null;
          toDate = null;
          break;
      }

      setFrom(fromDate);
      setTo(toDate);
    };

    updateDates(range);
  }, [range]);

  return (
    <div style={{ marginTop: '4%' }}>
      <Box sx={{ mb: '2%' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", margin: '7px 15px 4px 0' }}>
            Reports
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='h4' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", mr: '5px' }}>View and manage your Reports </Typography>
        </Box>
      </Box>
      <Box>
        <TabContext value={tabValue}>
          <AntTabs value={tabValue} onChange={handleChange} sx={{ display: 'flex' }}>
            <AntTab label="Analytics" value="Analytics" />
            <AntTab onClick={() => setCustomerType('oneTime')} label="One -Time Customers" value="OneTimeCustomers" style={{ marginLeft: '20px', padding: '13px 14px 12px' }} />
            <AntTab onClick={() => setCustomerType('returningCustomer')} label="Returning Customers" value="ReturningCustomers" style={{ marginLeft: "20px", padding: '13px 14px 12px' }} />
            <AntTab label="Sales by Payment Mode" value="SalesByPaymentMode" />
            <AntTab label="Monthly Sales" value="MonthlySales" style={{ marginLeft: '20px', padding: '13px 14px 12px' }} />
            <AntTab label="Sales by Products" value="SalesByProducts" style={{ marginLeft: "20px", padding: '13px 14px 12px' }} />
            <AntTab label="Transactions" value="Transactions" style={{ marginLeft: "20px", padding: '13px 14px 12px' }} />
          </AntTabs>
          <TabPanel value="Analytics" >
            <Box>
              <Box sx={{ display: 'flex', }} >
                <Box onClick={() => setTabValue('Transactions')} sx={{ cursor: 'pointer', width: '100%', maxWidth: '416px', margin: '20px 12px 16px 0', padding: '26px 57px 43px 27px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                  <Typography sx={{ margin: '0 24px 25px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 1.83, textAlign: 'left', color: '#032541' }}>Total Sales </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <img src={salesIcon} alt="" style={{ width: '50px', height: '50px', margin: '41px 25px 15px 0', objectFit: 'contain' }} />
                    {totalSales?.map((sale) => (
                      <Box>
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ margin: '25px 0px 7px 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#231f20' }}>{sale?.count}</Typography>
                          <Typography sx={{ margin: '25px 70px 7px 5px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#707070' }}>Transactions</Typography>
                        </Box>
                        <Typography sx={{ margin: '0px 0 2px 25px', fontFamily: 'Poppins', fontSize: '27px', fontWeight: 'bold', textAlign: 'left', color: '#231f20' }}>Ksh {sale?.total}</Typography>
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ margin: '2px 10px 0 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#009447' }}>10.01</Typography>
                          <TrendingUp sx={{ color: '#009447' }} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box onClick={() => navigate('/zed_ecommerce/orders')} sx={{ cursor: 'pointer', width: '100%', maxWidth: '416px', height: '200px', margin: '20px 12px 16px 0', padding: '26px 57px 43px 27px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ margin: '0 24px 25px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 1.83, textAlign: 'left', color: '#032541' }}>Total Orders</Typography>
                    <Box sx={{ display: 'flex' }}>
                      <ArrowBackIos sx={{ margin: '4px 10.4px 4.8px 0', color: '#e0e0e0' }} />
                      <Typography sx={{ margin: '0 9px 0 9px', fontFamily: 'Poppins', fontSize: '16px', lineHeight: '2.36', textAlign: 'center', color: '#032541' }}>Completed</Typography>
                      <ArrowForwardIos sx={{ margin: '4px 10.4px 4.8px 0', color: '#707070' }} />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '50px', height: '50px', margin: '41px 31px 15px 0', padding: '6px 4px 4px 6px', borderRadius: '4px', backgroundColor: 'rgba(61, 162, 248, 0.1)' }}>
                      <img src={billsIcon} alt="" style={{ width: '48px', height: '48px', objectFit: 'contain' }} />
                    </Box>
                    {totalOrders?.map((order) => (
                      <Box>
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ margin: '25px 0px 7px 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#231f20' }}>{order.count}</Typography>
                          <Typography sx={{ margin: '25px 70px 7px 5px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#707070' }}>Completed</Typography>
                        </Box>
                        <Typography sx={{ margin: '0px 0 2px 25px', fontFamily: 'Poppins', fontSize: '27px', fontWeight: 'bold', textAlign: 'left', color: '#231f20' }}>Ksh {order.total}</Typography>
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ margin: '2px 10px 0 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#009447' }}>1.01%</Typography>
                          <TrendingUp sx={{ color: '#009447' }} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box onClick={() => setTabValue('SalesByPaymentMode')} sx={{ cursor: 'pointer', width: '100%', maxWidth: '416px', height: '200px', margin: '20px 12px 16px 0', padding: '26px 57px 43px 27px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                  <Slider ref={slider => { sliderRef = slider; }} {...settings}>
                    {salesChannels?.map((channel, index) => (
                      <Box sx={{ width: '100%', minWidth: "300px" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ margin: '0 24px 25px 20px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 1.83, textAlign: 'left', color: '#032541', textWrap: 'wrap' }}>Sales by Channel</Typography>
                          <Box sx={{ display: 'flex' }}>
                            <ArrowBackIos sx={{ margin: '4px 10.4px 4.8px 0', color: index === 0 ? '#e0e0e0' : '#707070', cursor: 'pointer' }} onClick={previous} />
                            <Typography sx={{ margin: '0 9px 0 9px', fontFamily: 'Poppins', fontSize: '16px', lineHeight: '2.36', textAlign: 'center', color: '#032541' }}>{channel?._id}</Typography>
                            <ArrowForwardIos sx={{ margin: '4px 10.4px 4.8px 0', color: index === salesChannels?.length - 1 ? '#e0e0e0' : '#707070', cursor: 'pointer' }} onClick={next} />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ width: '50px', height: '50px', margin: '41px 31px 15px 0', padding: '5px', objectFit: 'contain', borderRadius: '4px', backgroundColor: '#e7f7f1' }}>
                            <ShowChart sx={{ color: '#17ae7b', m: '15%', width: '35.7px', height: '36.7px' }} />
                          </Box>
                          <Box sx={{ width: '100%', maxWidth: '360px' }}>
                            <Box>
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ margin: '25px 0px 7px 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#231f20' }}>{channel?.count}</Typography>
                                <Typography sx={{ margin: '25px 70px 7px 5px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#707070' }}>Transactions</Typography>
                              </Box>
                              <Typography sx={{ margin: '0px 0 2px 25px', fontFamily: 'Poppins', fontSize: '27px', fontWeight: 'bold', textAlign: 'left', color: '#231f20' }}>Ksh {channel?.total}</Typography>
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ margin: '2px 10px 0 25px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#dc3545' }}>1.01%</Typography>
                                <TrendingDown sx={{ color: '#dc3545' }} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '100%', maxWidth: '748px', height: 'auto', margin: '16px 9px 0 0', padding: '20px 23px 23px 23px', borderRadius: '10px', boxShadow: ' 3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ flexDirection: 'column', margin: '0 24px 25px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 1.83, textAlign: 'left', color: '#032541' }}>Total Shipping Fees</Typography>
                    <FormControl sx={{ border: 'none' }}>
                      <Select sx={{ width: '144px', height: '48px', margin: '0 1px 30px 177px', padding: '9px 10px 9px 16px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.03)', border: 'none' }} id="filter-select" value="Weekly" onChange={handleChangeFilter} >
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {totalShipping?.map((shipping, index) => (
                    <Box sx={{ mt: '5%' }}>
                      <Typography sx={{ margin: '0 15px 8px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, color: '#707070' }}>{shipping?._id}  Txn</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Box>
                          <Typography sx={{ margin: '30px 20px 0 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 2.06, color: index % 2 === 0 ? '#17ae7b' : '#22409a', textWrap: 'nowrap' }}>Ksh {shipping?.total}</Typography>
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ flexDirection: 'column', margin: '0 203px 9px 0', fontFamily: 'Poppins', fontSize: '16px', lineHeight: 2.86, color: '#707070' }}>{shipping?.count} Transactions</Typography>
                            <Typography sx={{ margin: '0 203px 9px 0', fontFamily: 'Poppins', fontSize: '16px', lineHeight: 2.86, color: '#707070' }}>{shipping?.percentageTotal?.toFixed(2)??0}%</Typography>
                          </Box>
                          <LinearProgress color={index % 2 === 0 ? 'success' : 'primary'} value={shipping?.percentageTotal} variant="determinate" sx={{ width: '341px', height: '5px', margin: '0 85px 0 0', borderRadius: '2.5px' }} />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ width: '100%', maxWidth: '748px', minHeight: '409px', margin: '16px 9px 0 0', padding: '20px 23px 1px 23px', borderRadius: '10px', boxShadow: ' 3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ flexDirection: 'column', margin: '0 24px 25px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, lineHeight: 1.83, textAlign: 'left', color: '#032541' }}>Top 5 Selling Products</Typography>
                    <FormControl sx={{ border: 'none' }}>
                      <Select sx={{ width: '144px', height: '48px', margin: '0 1px 30px 177px', padding: '9px 10px 9px 16px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.03)', border: 'none' }} id="filter-select" value="Monthly" onChange={handleChangeFilter} >
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '96%', height: '40px', margin: '14px 2px 2px', padding: '11px 13px 9px 15px', borderRadius: '4px', backgroundColor: '#f5f6f7' }}>
                    <Typography sx={{ flexDirection: 'column', margin: '8px 0 0 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, color: '#000' }}>Product Name</Typography>
                    <Typography sx={{ flexDirection: 'column', margin: '8px 0 0 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, color: '#000' }}>Amount (KES)</Typography>
                  </Box>
                  {topSellingProducts?.map((product) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '2px 0 2px', padding: '0 24px 0 11px', borderRadius: '4px', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                      <Box sx={{ flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex' }}>
                          <img src={imagePlaceHolder} alt="" style={{ width: '40px', height: '40px', margin: '10px 15px 0 0', objectFit: 'contain', borderRadius: '5px' }} />
                          <Typography sx={{ margin: '15px 135px 10px 15px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, color: '#707070' }}>{product.itemName}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography sx={{ margin: '15px 15px 10px 15px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, color: '#707070' }}>{product.totalSales}</Typography>
                      </Box>
                    </Box>
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography onClick={() => setTabValue('SalesByProducts')} sx={{ margin: '18px 30px 10px 188px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, lineHeight: '2.36', color: '#032541', textWrap: 'nowrap' }}>View More</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="OneTimeCustomers" style={{ marginLeft: '-30px' }}>
            <Grid item>
              <DataGrid
                sx={{ height: 'auto', minHeight: '100px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={customersState?.total}
                pagination
                loading={customersState?.isLoading}
                page={customersState?.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: customersState?.pageSize,
                      page: customersState?.page - 1,
                    },
                  },
                }}
                pageSize={customersState?.pageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setCustomersState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                }}
                paginationMode="server"
                columns={columns}
                rows={customersState?.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="ReturningCustomers" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={customersState?.total}
                pagination
                loading={customersState?.isLoading}
                page={customersState?.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: customersState?.pageSize,
                      page: customersState?.page - 1,
                    },
                  },
                }}
                pageSize={customersState?.pageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setCustomersState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                }}
                paginationMode="server"
                columns={returningColumns}
                rows={customersState?.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="SalesByPaymentMode" style={{ marginLeft: '-30px' }}>
            <Grid item>
              <CustomTable columns={salesPerChannel} data={salesChannels} />

            </Grid>
          </TabPanel>
          <TabPanel value="MonthlySales" style={{ marginLeft: '-30px' }}>
            <Grid item>
              <CustomTable columns={monthlySalesColumns} data={salesData} />
            </Grid>
          </TabPanel>
          <TabPanel value="SalesByProducts" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={salesPerProductState?.total}
                pagination
                loading={salesPerProductState.isLoading}
                page={salesPerProductState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: salesPerProductState.pageSize,
                      page: salesPerProductState.page - 1,
                    },
                  },
                }}
                pageSize={salesPerProductState.pageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setSalesPerProductState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                }}
                paginationMode="server"
                columns={salesPerProductColumns}
                rows={salesPerProductState?.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Transactions" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ height: 'auto', minHeight: '110px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={transactionsState?.total || 0}
                pagination
                loading={transactionsState.isLoading}
                page={transactionsState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: transactionsState.pageSize,
                      page: transactionsState.page - 1,
                    },
                  },
                }}
                pageSize={transactionsState.pageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setTransactionsState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                }}
                paginationMode="server"
                columns={transactionsColumns}
                rows={transactionsState?.data || []}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />

            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}
export default Reports;