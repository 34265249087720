import { Navigate, Outlet } from "react-router-dom";
import AdminSideBar from "./adminSidebar";
import userSlice from "../../features/userSlice";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const environment = process.env.REACT_APP_ENVIRONMENT;
const PrivateRoutes = () => {
  let { isLoggedIn } = useSelector((store) => store.user);
  if (environment === "local") {
    isLoggedIn = true;
  }
  const group = secureLocalStorage.getItem("group");
  
  return (
    <>
      {isLoggedIn ? (
        <>
          {group === "admin" ? (
            <AdminSideBar>
              <Outlet />
            </AdminSideBar>
          ) : (
            <Navigate to="/login" />
          )}
        </>
      ) : (
        <Navigate to="/zed_ecommerce/admin/login" />
      )}
    </>
  );
};

export default PrivateRoutes;
