import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpRequest } from "../HttpRequest";
import { setPackage } from "../../features/cartSlice";
import secureLocalStorage from "react-secure-storage";

const useBestPackage = () => {
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  const fetchAndDetermineBestPackage = async (additionalQuantity = 1) => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    try {
      const response = await HttpRequest({
        method: 'GET',
        url: `/api/v1/ecommerce/packages-in-bs?businessNumber=${businessNumber}`
      });
      const availablePackages = response?.response?.data;
      setPackages(availablePackages);

      const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0) + additionalQuantity;

      if (!availablePackages || availablePackages.length === 0) return null;

      let bestPkg = null;
      for (const pkg of availablePackages) {
        if (pkg?.noOfItemsInPackage >= totalQuantity && (!bestPkg || pkg?.noOfItemsInPackage < bestPkg?.noOfItemsInPackage)) {
          bestPkg = pkg;
        }
      }

      if (bestPkg!==null ) {
        return bestPkg;
      } else {
        return null;
      }

    } catch (error) {
      return null;
    }
  };

  return { fetchAndDetermineBestPackage };
};

export default useBestPackage;
