import {InputAdornment, TextField} from "@mui/material";
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const CustomSearchInput = ({value, onChange,name, placeholder,style ,disabled,sm,...rest }) => {
    return (
        <TextField
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            variant="outlined"
            margin="dense"
            sx={{marginBottom:2}}
            InputProps={{
                sx: {
                    width: sm?"15.438rem":'455px',
                    height: '3.438rem',
                    fontSize:"0.875rem",
                    "& fieldset": {
                        borderColor: "#bec5d1"
                    },
                    mt:'20px'

                },
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
            }}
            {...rest}
        />
    );
};

export default CustomSearchInput;
