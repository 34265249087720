import { Box, Button, Grid, Modal, Tab, Typography } from "@mui/material"
import CustomSearchInput from "../common/CustomSearchInput";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomSelect from "../common/customSelectInput";
import { CustomizedDate } from "../common/customDate";
import { startOfToday, startOfYesterday, endOfToday, endOfYesterday, subDays, startOfMonth, endOfMonth, subMonths, format, formatDate } from 'date-fns';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import ExportData from "../common/exportData";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import CreateEvoucherCampaign from "./addEvoucherCampaign";
import DateFormatter from "../common/dateFormatter";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EvoucherDetails from "./evoucherDetails";
import CurrencyFormatter from "../common/currencyFormatter";
import EditEvoucherCampaign from "./editCampaign";
import warningIcon from '../../images/warning-remove-icn.svg';
import { Circle } from '@mui/icons-material';
import { ErrorAlert } from "../common/snackbar/error";
import { SuccessAlert } from "../common/snackbar/success";

const getLastMonthRange = (date) => {
    const previousMonth = subMonths(date, 1);
    const start = startOfMonth(previousMonth);
    const end = endOfMonth(previousMonth);
    return { start, end };
};

const deleteCategoryModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 102px 165px 126px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}


const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "#fff" }, alignItems: 'center', display: 'flex' });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", maxWidth: 90, height: '100%', [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
    fontSize: "15px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#fff", backgroundColor: '#032541', borderBottom: '', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "none" }, textWrap: 'nowrap',
}));

const EvouchersCampaigns = () => {
    const [search, setSearch] = useState('');
    const [tabValue, setTabValue] = useState('all');
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [range, setRange] = useState('thisMonth');
    const [dataToExport, setDataToExport] = useState([]);
    const [dataGridPageSize, setPageSize] = useState(10);
    const [OpenVoucherDetails, setOpenVoucherDetails] = useState(false);
    const [deleteVoucherDetails, setDeleteVoucherDetails] = useState({});
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const navigate = useNavigate();
    const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleRowClick = (event, rowId) => {
        event.stopPropagation();
        navigate(`/zed_ecommerce/evouchercompaings?ViewDetails?${rowId}`)
    }
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue] = newqueryParamValue.split("?")
    const handleEnableDelete = () => {
        setOpenVoucherDetails(true);
    }
    const handleCloseVoucher = () => {
        setOpenVoucherDetails(false);
    }
    console.log(deleteVoucherDetails , 'deleteVoucherDetails')
    const columns = [
        {
            field: 'name', headerName: 'Campaign Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Campaign Name</Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }} > {params?.row?.name}  </Box>)
            }
        },

        {
            field: 'totalAmount', headerName: 'Evoucher Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Evoucher Amount</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.totalAmount, "KES")}  </Box>) }
        },
        {
            field: 'startDate', headerName: 'Evoucher Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Start</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{DateFormatter(params?.row?.startDate)}  </Box>) }
        },
        {
            field: 'endDate', headerName: 'Evoucher Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>End</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{DateFormatter(params?.row?.endDate)}  </Box>) }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderHeader: (params) => (
                <Box sx={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Status</Box>
            ),
            renderCell: (params) => {
                const color = params?.row?.status === "Upcoming" ? "#f79009" : params?.row?.status === "Ongoing" ? "#17ae7b" : params?.row?.status === "Ended" ? "#ff0000" : "#000000";
                return (
                    <Box onClick={(event) => handleRowClick(event, params?.row?._id)} sx={{ cursor: 'pointer', color, fontSize: "15px", fontWeight: "500" }}>
                        {params?.row?.status}
                    </Box>
                );
            },
        },

        {
            field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => {
                const onClickDelete = (event) => {
                    event.stopPropagation();
                    setDeleteVoucherDetails(params?.row);
                    handleEnableDelete();
                }
                return (
                    <Box component={'div'} alignItems={'center'}>
                        {tabValue === 'Ended' || tabValue ==='Deleted'  ? <RemoveRedEyeIcon /> : <EditIcon onClick={() => navigate(`/zed_ecommerce/evouchercompaings?EditCampaign?${params?.row?._id}`)} sx={{ color: '#032541', cursor: 'pointer', marginLeft: '10px' }} />}
                        {tabValue === 'Ended' || tabValue ==='Deleted' ? '':  <DeleteIcon onClick={onClickDelete} sx={{ color: '#dc3545', cursor: 'pointer', marginLeft: '10px' }} />}
                    </Box>
                )
            }
        },

    ]
    const [tableColumns, setTableColumns] = useState([])


    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px', mr: '20px' }}>
                <ExportData data={dataToExport} tableColumn={tableColumns} title={`Orders Export`} />
            </GridToolbarContainer>
        );
    }

    const ranges = [
        { id: 1, label: 'Select Date', value: '' },
        { id: 2, label: 'Today', value: 'today' },
        { id: 3, label: 'Yesterday', value: 'yesterday' },
        { id: 4, label: 'Last 7 Days', value: 'oneWeek' },
        { id: 5, label: 'This Month', value: 'thisMonth' },
        { id: 6, label: 'Last Month', value: 'lastMonth' },
        { id: 7, label: 'Custom Range', value: 'customDate' },
    ];

    useEffect(() => {
        const updateDates = (range) => {
            const today = new Date();
            let fromDate = null;
            let toDate = null;

            switch (range) {
                case 'today':
                    fromDate = format(startOfToday(), 'MM/dd/yyyy');
                    toDate = format(endOfToday(), 'MM/dd/yyyy');
                    break;
                case 'yesterday':
                    fromDate = format(startOfYesterday(), 'MM/dd/yyyy');
                    toDate = format(endOfYesterday(), 'MM/dd/yyyy');
                    break;
                case 'oneWeek':
                    fromDate = format(subDays(today, 7), 'MM/dd/yyyy');
                    toDate = format(endOfToday(), 'MM/dd/yyyy');
                    break;
                case 'thisMonth':
                    fromDate = format(startOfMonth(today), 'MM/dd/yyyy');
                    toDate = format(endOfMonth(today), 'MM/dd/yyyy');
                    break;
                case 'lastMonth':
                    ({ start: fromDate, end: toDate } = getLastMonthRange(today));
                    fromDate = format(fromDate, 'MM/dd/yyyy');
                    toDate = format(toDate, 'MM/dd/yyyy');
                    break;
                default:
                    fromDate = null;
                    toDate = null;
                    break;
            }
            setFrom(fromDate);
            setTo(toDate);
        };

        updateDates(range);
    }, [range]);

    const sanitizeSearchQuery = (query) => {
        return query.replace(/\D/g, '');
    };



    const getCampaignList = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        let status = tabValue === 'all' ? '' : tabValue;
        const url = `/api/v1/evoucher/list_campaigns?pageNumber=${pageState.page}&pageLimit=${pageState.pageSize}&status=${status}&searchValue=${sanitizeSearchQuery(search)}&startDate=${from ?? ''}&endDate=${to ?? ''}`;
        HttpRequest({
            method: 'GET',
            url: url,
        }).then((data) => {
            setPageState({
                ...pageState,
                data: data?.response?.data,
                total: data?.response?.count,
                isLoading: false
            });
        }).catch((error) => {
        });
    };
    useEffect(() => {
        getCampaignList();
    }, [pageState.page, pageState.pageSize, tabValue, search, from, to, queryParamValue]);

    const handleDeleteVoucherDisabled = (id) => {
        setOpenVoucherDetails(false);
        HttpRequest({
            method: 'PUT',
            url: `/api/v1/evoucher/delete_campaign/${id}`,
        }).then((data) => {
            if (data?.status === 200 || data?.status === 201) {
                setSuccessShow({ state: true, message: data?.response?.message });
                setDeleteVoucherDetails({});
                getCampaignList();
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: error?.message })
        });

    }

    return (
        <Grid container display={'flex'} width={'100%'} direction={'column'} pt={10}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {queryParamValue === 'AddCampaign' ? <CreateEvoucherCampaign /> : queryParamValue === 'ViewDetails' ? <EvoucherDetails /> : queryParamValue === 'EditCampaign' ? <EditEvoucherCampaign /> : <>
                <Grid item width={'100%'}>
                    <Grid container display={'flex'} flexDirection={'row'}>
                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "25px", fontStyle: "normal", fontWeight: "600", }}>
                            Campaigns
                        </Typography>
                        <Button onClick={() => navigate(`/zed_ecommerce/evouchercompaings?AddCampaign`)} variant="contained" sx={{ textTransform: 'inherit', backgroundColor: "#032541", color: "#fff", marginLeft: "auto", marginTop: "10px", marginRight: "20px" }}>
                            Create Campaigns
                        </Button>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: 'flex' }} lg={12}>
                    <CustomSearchInput placeholder="Search" name="search" value={search} onChange={(event) => setSearch(event.target.value)} />
                </Grid>
                <Box component={'div'} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', alignItems: "center" }}>
                    <Box component={'div'} width={'100%'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box component={'div'} sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <TabContext value={tabValue} sx={{ width: '100%' }}>
                                <AntTabs value={tabValue} onChange={handleTabChange}>
                                    <AntTab label="All" value="all" />
                                    <AntTab label="Upcoming" value="Upcoming" style={{ marginLeft: '10px' }} />
                                    <AntTab label="Ongoing" value="Ongoing" style={{ marginLeft: "10px" }} />
                                    <AntTab label="Ended" value="Ended" style={{ marginLeft: "10px" }} />
                                    <AntTab label="Deleted" value="Deleted" style={{ marginLeft: "10px" }} />
                                    <Box component={'div'} sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {range === 'customDate' ? '' :
                                            <CustomSelect
                                                value={range}
                                                options={ranges}
                                                sm={"sm"}
                                                onChange={(e) => setRange(e.target.value)}
                                            />}
                                        {range === 'customDate' && (
                                            <CustomizedDate setDateFrom={setFrom} setDateTo={setTo} style={{ display: "flex", alignItems: "center" }} />
                                        )}
                                    </Box>
                                </AntTabs>
                                <Grid item sx={{ display: 'flex', width: '100%' }}>
                                    <TabPanel value="all" sx={{ width: '100%' }}>
                                        <DataGrid
                                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowCount={pageState.total}
                                            pagination
                                            loading={pageState.isLoading}
                                            page={pageState.page - 1}
                                            pageSizeOptions={[1, 10, 20, 50, 100]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: dataGridPageSize,
                                                        page: pageState.page - 1,
                                                    },
                                                },
                                            }}
                                            pageSize={dataGridPageSize}
                                            onPaginationModelChange={(data) => {
                                                const { pageSize, page } = data;
                                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                                setPageSize(pageSize)
                                            }}
                                            paginationMode="server"
                                            columns={columns}
                                            rows={pageState.data}
                                            getRowId={(row) => row?._id}
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item sx={{ display: 'flex', width: '100%' }}>
                                    <TabPanel value="Upcoming" sx={{ width: '100%' }}>
                                        <DataGrid
                                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowCount={pageState.total}
                                            pagination
                                            loading={pageState.isLoading}
                                            page={pageState.page - 1}
                                            pageSizeOptions={[1, 10, 20, 50, 100]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: dataGridPageSize,
                                                        page: pageState.page - 1,
                                                    },
                                                },
                                            }}
                                            pageSize={dataGridPageSize}
                                            onPaginationModelChange={(data) => {
                                                const { pageSize, page } = data;
                                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                                setPageSize(pageSize)
                                            }}
                                            paginationMode="server"
                                            columns={columns}
                                            rows={pageState.data}
                                            getRowId={(row) => row?._id}
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item sx={{ display: 'flex', width: '100%' }}>
                                    <TabPanel value="Ongoing" sx={{ width: '100%' }}>
                                        <DataGrid
                                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowCount={pageState.total}
                                            pagination
                                            loading={pageState.isLoading}
                                            page={pageState.page - 1}
                                            pageSizeOptions={[1, 10, 20, 50, 100]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: dataGridPageSize,
                                                        page: pageState.page - 1,
                                                    },
                                                },
                                            }}
                                            pageSize={dataGridPageSize}
                                            onPaginationModelChange={(data) => {
                                                const { pageSize, page } = data;
                                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                                setPageSize(pageSize)
                                            }}
                                            paginationMode="server"
                                            columns={columns}
                                            rows={pageState.data}
                                            getRowId={(row) => row?._id}
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item sx={{ display: 'flex', width: '100%' }}>
                                    <TabPanel value="Ended" sx={{ width: '100%' }}>
                                        <DataGrid
                                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowCount={pageState.total}
                                            pagination
                                            loading={pageState.isLoading}
                                            page={pageState.page - 1}
                                            pageSizeOptions={[1, 10, 20, 50, 100]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: dataGridPageSize,
                                                        page: pageState.page - 1,
                                                    },
                                                },
                                            }}
                                            pageSize={dataGridPageSize}
                                            onPaginationModelChange={(data) => {
                                                const { pageSize, page } = data;
                                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                                setPageSize(pageSize)
                                            }}
                                            paginationMode="server"
                                            columns={columns}
                                            rows={pageState.data}
                                            getRowId={(row) => row?._id}
                                        />
                                    </TabPanel>
                                </Grid>
                                <Grid item sx={{ display: 'flex', width: '100%' }}>
                                    <TabPanel value="Deleted" sx={{ width: '100%' }}>
                                        <DataGrid
                                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                                            rowCount={pageState.total}
                                            pagination
                                            loading={pageState.isLoading}
                                            page={pageState.page - 1}
                                            pageSizeOptions={[1, 10, 20, 50, 100]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: dataGridPageSize,
                                                        page: pageState.page - 1,
                                                    },
                                                },
                                            }}
                                            pageSize={dataGridPageSize}
                                            onPaginationModelChange={(data) => {
                                                const { pageSize, page } = data;
                                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                                setPageSize(pageSize)
                                            }}
                                            paginationMode="server"
                                            columns={columns}
                                            rows={pageState.data}
                                            getRowId={(row) => row?._id}
                                        />
                                    </TabPanel>
                                </Grid>
                            </TabContext>
                        </Box>
                    </Box>
                </Box>
            </>}
            <Modal open={OpenVoucherDetails} onClose={handleCloseVoucher} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={deleteCategoryModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
                            <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                                <Typography sx={{ height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Disable Voucher  ?
                                </Typography>
                                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                                    Selected voucher will be disabled. <br /> This action cannot be undone.
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center' }}>
                            <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff", border: "solid 1px #002543" } }} onClick={handleCloseVoucher} >Cancel</Button>
                            <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => handleDeleteVoucherDisabled(deleteVoucherDetails?._id)}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}

export default EvouchersCampaigns;