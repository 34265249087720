import { Box, Grid, Typography, InputAdornment, TextField } from '@mui/material'
import logoImage from "../../images/eastwestLogo.svg"
import { useSelector } from 'react-redux';
import TextIcon from '../common/TextIcon';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const { logo,buttons } = useSelector((state) => state.template);
    const {businessEmail,businessAddress,businessPhone} = useSelector((state) => state.configs);
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const navigate = useNavigate();

    return (
        <Box component={'div'} width={'100%'} className='footer' sx={{ bottom: 0, position: 'relative', minHeight: "391px", flexShrink: 0 }}>
            <Grid container spacing={2} maxWidth="lg" sx={{ marginRight: 'auto', marginLeft: 'auto', height: '100%', alignItems: 'center', marginTop: { lg: '30px' }, padding: { lg: '60px 0px', xs: '10px 10px' } }}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    {logo?.logoType === 'logo' ?
                        <Box component={'img'} src={logo?.logoPath !== '' ? logo?.logoPath : logoImage} alt='logo' sx={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                        :
                        <TextIcon text={logo?.logoText} width={'100px'} height={'100px'} backgroundColor={'#fff'} textColor={primaryColor} />
                    }
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: "100%" }} >
                        <Typography sx={{fontSize: '14px', fontWeight: 600, color: headingsColor }}>Address:</Typography>
                        <Typography sx={{fontSize: '14px', color: bodyColor }}>{businessAddress}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: "100%", alignItems: 'center', gap: 1 }}>
                        <Typography sx={{fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: headingsColor }}>Contacts:</Typography>
                        <Typography sx={{fontSize: '14px', textAlign: 'left', color: bodyColor }}>{businessPhone}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                        <Typography sx={{fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: headingsColor, textWrap: 'nowrap' }}>Email Address:</Typography>
                        <Typography sx={{fontSize: '14px', textAlign: 'left', color: bodyColor }}>{businessEmail}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                        <Typography sx={{fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: headingsColor, textWrap: 'nowrap' }}>Working hours:</Typography>
                        <Typography sx={{fontSize: '14px', textAlign: 'left', color: bodyColor, textWrap: 'nowrap' }}>09:00 - 18:00, Mon - Sat</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography sx={{fontSize: '16px', fontWeight: 600, lineHeight: 2.25, textAlign: 'left', color: headingsColor, textWrap: 'nowrap' }}>Useful Links</Typography>
                    <Typography onClick={()=>navigate('contacts')} sx={{cursor:'pointer',margin: '0 98px 14px 0',fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: bodyColor }}>
                        Contact Us
                    </Typography>
                    <Typography onClick={()=>navigate('contacts')} sx={{cursor:'pointer', margin: '0 98px 14px 0',fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: bodyColor }}>
                        About Us
                    </Typography>
                    <Typography onClick={()=>navigate('terms')} sx={{cursor:'pointer', textWrap: 'nowrap', margin: '0 98px 14px 0',fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: bodyColor }}>
                        Terms & Conditions
                    </Typography>
                    <Typography onClick={()=>navigate('faqs')} sx={{cursor:'pointer', margin: '0 98px 14px 0',fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: bodyColor }}>
                        FAQS
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Box component={'div'} gap={2} display={'flex'} justifyItems={'flex-start'} justifyContent={'flex-start'} flexDirection={'column'}>
                        <Typography onClick={()=>navigate('returns')} sx={{cursor:'pointer',fontSize: '14px', lineHeight: 1.29, color: bodyColor, }}>
                            Returns & Exchanges
                        </Typography>
                        <Typography onClick={()=>navigate('shipping')} sx={{cursor:'pointer',fontSize: '14px', lineHeight: 1.29, color: bodyColor, }}>
                            Shipping & Delivery
                        </Typography>
                        <Typography onClick={()=>navigate('privacy')} sx={{cursor:'pointer',fontSize: '14px', lineHeight: 1.29, color: bodyColor, }}>
                            Privacy Policy
                        </Typography>
                        {/* <Typography onClick={()=>navigate('contacts')} sx={{cursor:'pointer',fontSize: '14px', lineHeight: 1.29, color: bodyColor, }}>
                            Payment Terms
                        </Typography> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography sx={{ margin: '0 91px 14px 0',fontSize: '16px', lineHeight: 2.25, textAlign: 'left', color: headingsColor, fontWeight: 600, textWrap: 'nowrap' }}>
                        Join Our Newsletter Now
                    </Typography>
                    <Typography sx={{ margin: ' 0px 1px 13px 0',fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: bodyColor }}>
                        Get E-mail updates about our latest shop
                        and special offers.
                    </Typography>
                    <Box sx={{ width: '100%', maxWidth: '287px' }}>
                        <TextField name={"Email"} value={""} onChange={() => { }} placeholder="Enter your Email" variant="outlined" sx={{ textAlign: 'center' }}
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                    <button style={{ border: 'none', backgroundColor: primaryColor, height: '55px', margin: "0 -14px 0 0px", width: "100px", borderRadius: '4px', padding: ' 14px 10px 15px 16px' }}>
                                        <Typography sx={{fontSize: '14px', textAlign: 'left', color: '#fff' }}>
                                            Subscribe
                                        </Typography>
                                    </button>
                                </InputAdornment>), width: '287px', height: '48px', textAlign: 'center', margin: '0 1px 0 0', padding: '14.5px 156px 13.5px 20px', borderRadius: '4px', border: `solid 1px ${bodyColor}`
                            }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
}

export default Footer;