import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import CustomSearchInput from "../common/CustomSearchInput";
import { useEffect, useState } from "react";
import CustomSelect from "../common/customSelectInput";
import { CustomizedDate } from "../common/customDate";
import {
  startOfToday,
  startOfYesterday,
  endOfToday,
  endOfYesterday,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
  formatDate,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import DateFormatter, { getDate } from "../common/dateFormatter";
import { TabPanel } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import activeIcon from "../../images/active.svg";
import expiredIcon from "../../images/expired.svg";
import redeemedIcon from "../../images/redeemed.svg";
import CurrencyFormatter from "../common/currencyFormatter";

const PurchasedDetails = () => {
  const [pageState, setPageState] = useState({ isLoading: true, data: [] });
  const queryParams = decodeURIComponent(window.location.search);
  const newqueryParamValue = queryParams.slice(1);
  const [queryParamValue] = newqueryParamValue.split("?");

  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const localCurrency = localStorage.getItem("localCurrency");
  const path = window.location.pathname;
  const voucherId = path.match(/zed_ecommerce\/evoucher\/([a-zA-Z0-9]+)/)?.[1];
  const [paymentDetails, setPaymentDetails] = useState({});
  const [redeemHistory, setredeemHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [voucherStatus, setVoucherStatus] = useState();
  const [recipientDetails, setRecipientDetails] = useState({})

  const getVoucherDetails = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    const url = `/api/v1/evoucher/getEvoucherDetails?voucherId=${voucherId}`;
    HttpRequest({
      method: "GET",
      url: url,
    })
      .then((data) => {
        console.log(data?.response?.data.amount, "response data");
        setAmount(data?.response?.data?.amount);
        setExpiryDate(data?.response?.data?.expiryDate);
        setVoucherNumber(data?.response?.data?.voucherNumber);
        setBalance(data?.response?.data?.balance);
        setPaymentDetails(data?.response?.data?.paymentDetails);
        setredeemHistory(data?.response?.data?.redeemHistory);
        setVoucherStatus(data?.response?.data?.status);
        setRecipientDetails(data?.response?.data?.customerDetails)
        setPageState({
          // ...pageState,
          // data: data?.response?.data,
          // total: data?.response?.count,
          // isLoading: false
        });
      })
      .catch(() => {});
  };
  useEffect(() => {
    getVoucherDetails();
  }, []);

  const daysCount = (date) => {
    const targetDate = new Date(date);
    const today = new Date();
    const timeDiff = targetDate - today;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return Math.abs(daysRemaining);
  };

  const paymentDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const maskPhoneNumber = (phoneNumber) => {
    const phoneStr = String(phoneNumber);
    const maskedPart = phoneStr.slice(0, 7) + "***" + phoneStr.slice(-2);
    return maskedPart;
  };

  return (
    <Grid container display={"flex"} width={"40%"} direction={"column"} pt={10}>
      <Grid item width={"100%"}>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Typography
            variant="h4"
            sx={{
              color: "#032541",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: "600",
            }}
          >
            Evoucher Details
          </Typography>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"}>
          <Typography
            variant="paragraph"
            sx={{
              color: "#032541",
              fontSize: "",
              fontStyle: "normal",
              fontWeight: "",
            }}
          >
            Here is a breakdown of the evoucher.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ display: "flex", width: "40%", marginTop: "30px" }}
      ></Grid>

      <Box
        sx={{
          display: "flex",
          background: "#f5f5f5",
          padding: 2,
          marginBottom: "14px",
          borderRadius: "12px",
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="paragraph"
              fontWeight="bold"
              gutterBottom
              sx={
                {
                  // color: voucherStatus === 'UNUSED' ? 'green' :
                  //        voucherStatus === 'EXPIRED' ? '#ff0000' :
                  //        voucherStatus === 'REDEEMED' ? 'yellow' :
                  //        '',
                }
              }
            >
              Balance:
            </Typography>
          </Box>
          <Box sx={{ marginTop: "7px" }}>
            <Typography
              variant="paragraph"
              color="textSecondary"
              fontWeight="bold"
              sx={{
                fontSize: "20px",
                color:
                  voucherStatus === "UNUSED"
                    ? "#17ae7b"
                    : voucherStatus === "EXPIRED"
                    ? "#ff0000"
                    : voucherStatus === "Redeemed"
                    ? "#f79009"
                    : "",
              }}
              gutterBottom
            >
              {CurrencyFormatter(balance, 'KES')}

            </Typography>
          </Box>

          <Box sx={{ marginTop: "7px" }}>
            <Typography
              variant="body1"
              fontWeight=""
              gutterBottom
              sx={{ color: "#707070" }}
            >
              <span>
                ID: {voucherNumber}
                <Box
                  component="span"
                  sx={{
                    marginLeft: "19px",
                    color: voucherStatus === "EXPIRED" ? "#ff0000" : "",
                  }}
                >
                  {voucherStatus === "UNUSED"
                    ? `Expiring in ${daysCount(expiryDate)} days`
                    : `Expired on ${getDate(expiryDate)}`}
                </Box>
              </span>
            </Typography>
          </Box>
          <Box sx={{ marginTop: "7px" }}>
            <Typography
              variant="paragraph"
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#111927" }}
            >

              Amount :  {CurrencyFormatter(amount, 'KES')}
            
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginLeft: "auto" }}>
          {voucherStatus === "UNUSED" ? (
            <img src={activeIcon} alt="active-icon" />
          ) : voucherStatus === "Redeemed" ? (
            <img src={redeemedIcon} alt="redeemed-icon" />
          ) : voucherStatus === "EXPIRED" ? (
            <img src={expiredIcon} alt="expired-icon" />
          ) : null}
        </Box>
      </Box>
      <Box sx={{ padding: "" }}>
        <Typography
          variant="paragraph"
          sx={{ marginLeft: "10px" }}
          fontWeight="bold"
          gutterBottom
        >
          Recipient Details
        </Typography>
        <br />

        <Box
          sx={{
            display: "flex",
            background: "",
            padding: 2,
            marginBottom: "14px",
            borderRadius: "12px",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant="paragraph"
                fontWeight=""
                gutterBottom
                sx={{ color: "#707070" }}
              >
                {recipientDetails?.name}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "7px" }}>
              <Typography
                variant="body1"
                sx={{ fontSize: "", color: "#707070" }}
              >
                {maskPhoneNumber(recipientDetails?.phone)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            <Box>
              <Typography
                variant="body1"
                fontWeight=""
                sx={{ fontSize: "", color: "#707070", display: "" }}
              ></Typography>
            </Box>
            <Box sx={{ marginTop: "20px", display: "flex" }}>
              <Typography
                variant="paragraph"
                color="textSecondary"
                fontWeight=""
                sx={{
                  fontSize: "",
                  color: "#707070",
                  marginLeft: "7px",
                  marginTop: "1px",
                }}
                gutterBottom
              >
                {recipientDetails?.email}
              </Typography>
            </Box>{" "}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "7px" }} />
      <Box sx={{ padding: "" }}>
        <Typography
          variant="paragraph"
          sx={{ marginLeft: "10px" }}
          fontWeight="bold"
          gutterBottom
        >
          Payment Details
        </Typography>
        <br />

        <Box
          sx={{
            display: "flex",
            background: "",
            padding: 2,
            marginBottom: "14px",
            borderRadius: "12px",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant="paragraph"
                fontWeight="bold"
                gutterBottom
                sx={{ color: "#707070" }}
              >
                {paymentDetails?.referenceNo}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "7px" }}>
              <Typography
                variant="body1"
                sx={{ fontSize: "", color: "#707070" }}
              >
                Paid on {paymentDate(paymentDetails?.paymentDate)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            <Box>
              <Typography
                variant="body1"
                fontWeight=""
                sx={{ fontSize: "", color: "#707070" }}
              >
                {maskPhoneNumber(paymentDetails?.phone)}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "7px", display: "flex" }}>
              <Typography
                variant="body1"
                sx={{ fontSize: "", color: "#707070" }}
              >
                Paid via
              </Typography>
              <Typography
                variant="paragraph"
                color="textSecondary"
                fontWeight="bold"
                sx={{
                  fontSize: "",
                  color: "#707070",
                  marginLeft: "7px",
                  marginTop: "1px",
                }}
                gutterBottom
              >
                {paymentDetails?.paymentChannel}
              </Typography>
            </Box>{" "}
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "7px" }} />
      <Box
        sx={{
          background: "",
          padding: 2,
          marginBottom: "7px",
          borderRadius: "12px",
        }}
      >
        <Typography variant="paragraph" fontWeight="bold" gutterBottom>
          Redeem History
        </Typography>
        <Box sx={{ padding: "2px" }}>
          {redeemHistory.length === 0 ? (
            <Typography
              variant="body1"
              sx={{ color: "#707070", marginTop: "10px" }}
            >
              This voucher has not been redeemed yet.
            </Typography>
          ) : (
            redeemHistory.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    marginRight: "15px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      minWidth: "20px",
                      minHeight: "20px",
                      borderRadius: "50%",
                      padding: 0,
                      borderColor: "lightgray",
                    }}
                  />
                  {index < redeemHistory.length - 1 && (
                    <Box
                      sx={{
                        width: "1px",
                        height: "30px",
                        backgroundColor: "lightgray",
                        marginTop: "5px",
                      }}
                    />
                  )}
                </Box>

                <Typography variant="body1" sx={{ color: "#707070" }}>
                  {localCurrency}{" "}
                  {item.amount
                    ? parseFloat(item.amount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}{" "}
                  redeemed on {getDate(item.createdAt)} {item.invoiceNumber}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default PurchasedDetails;
