import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import "./index.css";
import { CallOutlined, EmailOutlined, MapOutlined, PinDropOutlined } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const Contacts = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [mapSrc, setMapSrc] = useState('');
    const {businessEmail,businessAddress,businessPhone} = useSelector((state) => state.configs);
    const [address, setAddress] = useState(businessAddress);

    const handleAddressSubmit = async () => {

        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
            );
            const data = await response.json();
            if (data.status === 'OK') {
                const { lat, lng } = data.results[0].geometry.location;
                const embedUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.279403594343!2d${lng}!3d${lat}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x0!2sSonalux%20House!5e0!3m2!1sen!2ske!4v1717062323028!5m2!1sen!2ske`;

                setMapSrc(embedUrl);
            } else {
               
            }
        } catch (error) {
        }
    };
    useEffect(()=>{
        handleAddressSubmit();
    },[])
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} sx={{ width: '100%', mb: '30px' }}>
                <iframe
                    src={mapSrc}
                    style={{ border: 0, width: '100%', height: '384px', borderRadius: '5px' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: '30px' }}>
                <Box sx={{ backgroundColor: '#fafafa', borderRadius: '10px', padding: '32px', textAlign: 'left', height: '100%', maxHeight: '479px' }}>
                    <Typography sx={{fontWeight: '500', fontSize: '18px', color: '#010202', mb: '16px' }}>
                        Head Office
                    </Typography>
                    <Box sx={{ display: 'flex', mb: '45px' }}>
                        <PinDropOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                        <Box>
                            <Typography sx={{fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Our Location</Typography>
                            <Typography sx={{fontSize: '14px', color: '#010202' }}>
                                {businessAddress}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: '45px' }}>
                        <CallOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                        <Box>
                            <Typography sx={{fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Mobile Phone</Typography>
                            <Typography sx={{fontSize: '14px', color: '#010202' }}>
                                {businessAddress}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', mb: '45px' }}>
                        <EmailOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                        <Box>
                            <Typography sx={{fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Email</Typography>
                            <Typography sx={{fontSize: '14px', color: '#010202' }}>
                                {businessEmail}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        onClick={() => window.open(mapSrc)}
                        sx={{ display: 'flex', alignItems: 'center', padding: '12px 20px', borderRadius: '4px', backgroundColor: '#fff', cursor: 'pointer', width: 'fit-content' }}
                    >
                        <MapOutlined sx={{ fontSize: '26px', color: '#707070', mr: '10px' }} />
                        <Typography sx={{fontSize: '17px', color: "#707070" }}>View Map</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ backgroundColor: '#fafafa', borderRadius: '10px', textAlign: 'left', height: 'auto', minHeight: '479px',padding: '0 20px 20px 20px', mb: '30px' }}>
                    <Typography sx={{fontWeight: '500', fontSize: '18px', color: '#010202', mb: '16px' }}>
                        Send Us Message
                    </Typography>
                    <Typography sx={{ margin: '13px 22px 6.5px 0',fontSize: '14px', color: '#010202' }}>
                        Drop us a quick mail for any question and we will get back to you as soon as possible.
                    </Typography>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left' } }} value={name} onChange={(e) => { setName(e.target.value) }} placeholder="Your Name" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left' } }} value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Your Email Address" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} placeholder='Phone Number' value={''} onChange={() => { }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left' } }} value={''} onChange={() => { }} placeholder="Subject" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '620px', sm: '340px', md: '600px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                <TextField rows={4} multiline InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left' } }} value={''} onChange={() => { }} placeholder="Your Message" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'end' }}>
                        <Button sx={{ width: '100%', height: '100%', maxWidth: '175px', maxHeight: '50px', margin: '70px 0 0 60px', padding: '13px 25px 13px 26px', borderRadius: '4px', backgroundColor: '#fff' }}>
                            <Typography sx={{fontSize: '17px', fontWeight: 500, color: '#02b3ad', textWrap: 'nowrap', textTransform: 'capitalize' }}>Send Message</Typography>
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Contacts;