
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import CustomerHeader from './customerHeader';
import DateFormatter from '../../utils/dateFormatter';
import Footer from '../customer/footer';
import { HttpRequest } from '../HttpRequest';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyFormatter from '../common/currencyFormatter';
import secureLocalStorage from 'react-secure-storage';

const OrderPaidSuccess = () => {
    const [orderDetails, setOrderDetails] = useState({});
    const [data, setData] = useState({});
    const [phoneNo, setPhoneNo] = useState("");
    const { customerId, orderId } = useParams();
    const navigate = useNavigate();

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';
    
    const getOrderDetails = () => {
        HttpRequest({
            method: 'POST',
            url: `/api/order_payment_status`,
            body: {
                "pushyTransactionId": orderId,
                "businessNo": secureLocalStorage.getItem("businessId"),
            }
        }).then(async (data) => {

            setOrderDetails(data.response.order);
            setData(data.response.data);
            await maskThephone(data?.response?.order?.stkPhone);


        }).catch((error) => {
        });
    }

    useEffect(() => {
        getOrderDetails();
    }, [])
    const maskThephone = (phone) => {
        let maskedPhone = phone;
        maskedPhone = maskedPhone.replace(maskedPhone.substring(0, 3), '*'.repeat(3));
        maskedPhone = maskedPhone.replace(maskedPhone.substring(6, 9), '*'.repeat(3));
        setPhoneNo(maskedPhone);
    }

   

    return (
        <Box>
            {/* <CustomerHeader /> */}
            <Box className="container" style={{ marginTop: "20px" }}>
                <Typography variant='h4' style={{fontWeight: 500, fontSize: '16px', lineHeight: 'normal', color: primaryColor, textAlign: "left" }}>Order Paid Successfully</Typography>
                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left", marginTop: "3px" }}>Estimated Delivery: {DateFormatter(Date.now())}</Typography>

                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <Box style={{ backgroundColor: "#fff", borderRadius: "15px", boxShadow: "3px 0 20px 0 rgba(145, 158, 171, 0.12)", padding: "20px" }}>
                            <Table>
                                <TableBody>
                                    <TableRow sx={{ height: "60px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Merchant</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: headingsColor }}>{orderDetails?.branchName ? orderDetails?.branchName : secureLocalStorage.getItem("businessName")} </TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Merchant Ref</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2',color: headingsColor }}>{orderDetails?.businessId ? orderDetails?.businessId : secureLocalStorage.getItem("businessId")}</TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Amount Paid</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2',color: headingsColor }}>   {CurrencyFormatter(data?.totalPaid, 'KES')}</TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Payment Method</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2',color: headingsColor }}>{orderDetails?.paymentMethod}-KE- {phoneNo}</TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Transaction Date</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2',color: headingsColor }}> {DateFormatter(orderDetails?.transtime)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box style={{ backgroundColor: "#fff", borderRadius: "15px", boxShadow: "3px 0 20px 0 rgba(145, 158, 171, 0.12)", padding: "20px" }}>
                            <Table>
                                <TableBody>
                                    <TableRow sx={{ height: "60px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Order Details </TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: headingsColor }}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left" }}>Order reference: ORDER - {orderDetails?.orderNumber}</Typography>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left" }}>Payment reference: {orderDetails?.transactionId}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Customer Details</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: headingsColor }}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left", textTransform: "uppercase" }}>{orderDetails?.customerName}</Typography>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left" }}>{orderDetails?.stkPhone}</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Shipping Details</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: headingsColor }}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left" }}>P.O BOX 70807-00400,</Typography>
                                                <Typography variant='h4' style={{fontWeight: 500, fontSize: '14px', lineHeight: 'normal', color: bodyColor, textAlign: "left" }}>Kilimani, NAIROBI, Kenya.</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "63px" }}>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: bodyColor }}>Total Amount</TableCell>
                                        <TableCell style={{ padding: '10px 10px', borderBottom: '1px solid #f2f2f2', color: headingsColor, fontSize: "18px", fontWeight: "500" }}>
                                            {CurrencyFormatter(orderDetails?.transamount, 'KES')}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Button onClick={()=>{navigate('/')}} variant="contained" style={{ backgroundColor: '#fff', color: bodyColor, padding: '10px 20px', borderRadius: '5px', border: `solid 1px ${bodyColor}` }}>Back Home</Button>
                            <Button onClick={()=>{navigate('/zed-ecommerce/customer-profile')}} variant="contained" style={{ backgroundColor: primaryColor, color: '#fff', padding: '10px 20px', borderRadius: '5px', marginLeft: "20px" }}>View My Orders</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* <Footer /> */}
        </Box>
    )
}

export default OrderPaidSuccess;
