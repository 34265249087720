import { Grid, Box, Typography, TextField, FormControl, Radio, OutlinedInput, IconButton, InputLabel, RadioGroup, InputAdornment, Button } from '@mui/material'
import logoImage from "../../../images/eastwestLogo.svg"
import { useState } from 'react';
import Footer from '../footer';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import { useDispatch, useSelector } from 'react-redux';
import { PinInputField, PinInput } from '@chakra-ui/pin-input';
import { setResetPin } from '../../../features/forgotPassword'
import { HttpRequest } from '../../HttpRequest';
import AuthHeader from './header';

const PasswordReset = () => {

    const [showPassword, setShowPassword] = useState(true);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [otp, setOtp] = useState("");

    const { method, channel } = useSelector((state) => state.forgotPassword);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';
    const quaternaryColor = 'var(--quaternary)';

    const handleClickShowPassword = () => {
        setShowPassword(true);
    };
    const handleClickHidePassword = () => {
        setShowPassword(false);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const maskValue = (value) => {
        if (value.length <= 6) {
            return value;
        }
        const firstThree = value.slice(0, 3);
        const lastThree = value.slice(-3);
        const masked = firstThree + '*'.repeat(value.length - 6) + lastThree;
        return masked;
    };

    const handleChange = (value) => {
        setOtp(value);
    }
    const handleComplete = (value) => {
        dispatch(setResetPin(value));
    }

    const handleResendCode = () => {
        let payload = {

        }
        if (method === 'email') {
            payload = {
                ...payload,
                userEmail: channel
            };
        } else {
            payload = {
                ...payload,
                phoneNumber: "+254" + channel,
            };
        }
        HttpRequest({
            method: 'POST',
            url: '/posForgotPinVersion2',
            body: payload
        }).then((response) => {
            if (response.response.Status === "SUCCESS") {
                 setSuccessShow({ state: true, message: `Password reset code has been resent. Please check your ${method}.` });
            } else {
                const message = response?.response?.message;
                setErrorShow({ state: true, message: message})
            }

        }).catch((error) => {
            setErrorShow({ state: true, message: 'Password reset failed. Please try again!' });
        })
    }
    return (
        <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 64px - 64px)' }}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <AuthHeader heading={'Reset your password'}  text={`Enter the security code of 4 digits sent to your ${method} in order to proceed with the password reset.  ${maskValue(channel)}`}/>
                <Box sx={{ maxWidth: "350px", marginLeft: 'auto', marginRight: "auto", }} component={'div'} display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} >
                            <FormControl>
                                <Box display="flex">
                                    <PinInput mask={showPassword} size="lg" value={otp} onChange={handleChange} onComplete={handleComplete}>
                                        <PinInputField autoComplete='off' style={{ padding: '28px 24px 27px 30px', width: '20px', height: '28px', margin: '0 10px 0 0', borderRadius: '4px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', color: bodyColor }} />
                                        <PinInputField autoComplete='off' style={{ padding: '28px 24px 27px 30px', width: '20px', height: '28px', margin: '0 10px 0 0', borderRadius: '4px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', color: bodyColor }} />
                                        <PinInputField autoComplete='off' style={{ padding: '28px 24px 27px 30px', width: '20px', height: '28px', margin: '0 10px 0 0', borderRadius: '4px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', color: bodyColor }} />
                                        <PinInputField autoComplete='off' style={{ padding: '28px 24px 27px 30px', width: '20px', height: '28px', margin: '0 10px 0 0', borderRadius: '4px', border: `solid 1px ${bodyColor}`, backgroundColor: '#fff', color: bodyColor }} />
                                    </PinInput>
                                </Box>
                            </FormControl>
                            <Box sx={{ mt: '10px', textAlign: 'center' }}>
                                <IconButton aria-label="toggle password visibility" onClick={showPassword ? handleClickHidePassword : handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" sx={{ width: '25px', height: '25px', margin: '0 9px 0 0', objectFit: 'contain', alignItems: 'center' }} >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}  <Typography sx={{ width: '49px', height: '17px', margin: '4px 0 4px 9px', fontSize: '14px', textAlign: 'center', color: bodyColor, textWrap: 'nowrap' }}>{showPassword ? 'Show PIN' : 'Hide PIN'}</Typography>
                                </IconButton>
                            </Box>
                            <Box sx={{justifyContent:'center',textAlign:'center'}}>
                                <Button onClick={()=> navigate('/password/change')} variant='contained' sx={{ width: '100%', maxWidth: '313px', height: '50px', margin: '31px 18px 14px 0px', padding: '15px 137px 15px 138px', objectFit: 'contain', opacity: otp.length === 4 ? 1 : '0.5', borderRadius: '5px', backgroundColor: primaryColor, fontSize: '16px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: primaryColor } }}>Submit</Button>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', color: bodyColor }}>
                                    Didn’t receive the verification code?
                                </Typography>
                                <Typography style={{ color: quaternaryColor,textDecoration:'underline',fontSize:'14px',cursor:'pointer' }} onClick={handleResendCode}> Request a new code.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}


export default PasswordReset;