import { Grid, Typography } from "@mui/material"
import { CustomerProfileSidebar } from "./profileSidebar";

const CustomerProfile = () => {
    
    const headingsColor = 'var(--headings)';

    return (
        <Grid container justifyContent={"center"} display={'flex'} direction={'column'} width={'100%'} height={'100%'} >
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: headingsColor }}>My Profile</Typography>
                <CustomerProfileSidebar />
            </Grid>
        </Grid>
    )
}

export { CustomerProfile };