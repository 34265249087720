import { Box, Button, Grid, Typography, TextField, Switch, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos} from "@mui/icons-material";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import { useSelector } from "react-redux";
import { setAdditionalServices, setEcommerceUrl, setEvoucherEnabled} from "../../features/configsSlice";
import { useDispatch } from "react-redux";
import BreadCrumbs from "../common/breadcrum";
import { useNavigate } from "react-router-dom";

const domain = process.env.REACT_APP_DOMAIN;

const ConfigurationZed = () => {
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [urlError, setUrlError] = useState(false);
  const [url, setUrl] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [showEcommerceModal, setShowEcommerceModal] = useState(false);
  const [links, setLinks] = useState([]);
  const [skipCustomization,setSkipCustomization] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (showEcommerceModal) {
      setLinks([
        { link: '/zed_ecommerce/configurations', label: 'Configurations' },
        { link: '', label: 'Enable E-commerce URL' },
      ]);
    } else {
      setLinks([
        { link: '', label: 'Configurations' },
      ]);
    }
  }, [showEcommerceModal]);

  const dispatch = useDispatch();
  const { additionalServicesEnabled, ecommerceUrlEnabled, packageFeeEnabled ,evoucherEnabled } = useSelector((store) => store.configs);
  const [additionalServicesStatus, setadditionalServicesStatus] = useState(additionalServicesEnabled ?? false);
  const [evocherStatus, setEvoucherStatus] = useState(evoucherEnabled ?? false);
  const [ecommerceUrlStatus, setEcommerceUrlStatus] = useState(ecommerceUrlEnabled ?? false);
  const [domainType, setDomainType] = useState('');
  const {businessName} =  useSelector((store) => store.configs);


  const handleAdditionalConfig = async () => {
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/enable_disable_additional_services`,
      body: { status: !additionalServicesStatus }
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Additional services status updated successfully" })
        setadditionalServicesStatus(!additionalServicesStatus)
        dispatch(setAdditionalServices(!additionalServicesStatus));
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error updating additional services status" });
    })
  }

  const handleEvoucherConfig = async () => {
    HttpRequest({
      method: 'POST',
      url: `/api/v1/evoucher/setEvoucherConfigStatus`,
      body: { status: !evocherStatus ,evoucherType: 'zedEcommerce'}
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message:data?.response?.message });
        setEvoucherStatus(!evocherStatus)
        dispatch(setEvoucherEnabled(!evocherStatus));
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message:error?.message });
    })
  } 

  const handleChangeName = () => {
    let trimmedName = businessName.trim().toLowerCase().replace(/\s+/g, '');
    const newUrl = `https://${trimmedName}${domain}`;
    setUrl(newUrl);
  }
  const isValidUrl = (url) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    return urlPattern.test(url);
  };


  const handleSubmit = () => {
    let isValid = true;

    setUrlError(false);
    if (domainType === 'zedDomain') {
      if (url === "") {
        setUrlError(true);
        isValid = false;
      }
      if (!isValidUrl(url)) {
        setUrlError(true);
        isValid = false;
      }
    } else {
      if (customUrl === "") {
        setUrlError(true);
        isValid = false;
      }
      if (!isValidUrl(customUrl)) {
        setUrlError(true);
        isValid = false;
      }
    }
    let newUrl = url;
    if (domainType !== 'zedDomain') {
      newUrl = `https://${customUrl}`;
    }
    if (isValid) {
      const payload = {
        "type": domainType,
        "subDomain": newUrl,
      }

      HttpRequest({
        method: 'POST',
        url: `/api/v1/ecommerce/zed-set-domain`,
        body: payload
      }).then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: 'E-commerce URL enabled successfully.' });
          setEcommerceUrlStatus(!ecommerceUrlStatus);
          dispatch(setEcommerceUrl(!ecommerceUrlStatus));
          setTimeout(() => {
            setShowEcommerceModal(false);
          }, 3000);
          checkUrlStatus();
        } else {
          setErrorShow({ state: true, message: data.response.error });
        }
      }).catch((error) => {
        setErrorShow({ state: true, message: 'Failed to enable E-commerce URL. Please try again.' });
      });
    }
  }
  const checkUrlStatus = () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getdomainstatusbymerchant`,
    }).then((data) => {
      if (data?.status === 200) {
        let urlStatus = data?.response?.isDomainUrlSet;
        setEcommerceUrlStatus(urlStatus);
        setSkipCustomization(!urlStatus);
        dispatch(setEcommerceUrl(urlStatus));
        if(urlStatus===true) {
          setDomainType(data?.response?.subDomainType);
          if(data?.response?.subDomainType==='customDomain') {
            setCustomUrl(data?.response?.subDomainUrl);
          }else{
            setUrl(data?.response?.subDomainUrl);
          }
        }
      } else {
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
     
    })
  }

  
  const handleFormData = (e) => {
    setDomainType(e.target.value);
    if (e.target.value === 'zedDomain') {
      handleChangeName();
    }
  }
  useEffect(() => {
    checkUrlStatus();
    buttonBgColor();
  }, []);
  const buttonBgColor = () => {
    if (domainType === '' || (domainType === 'customDomain' && customUrl === '')) {
      return 'rgba(3, 37, 65, 0.2)';
    }
    return '#032541';
  };

  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    let copyUrl = url!==''? url : customUrl
    copyTextToClipboard(copyUrl)
      .then(() => {
        setIsCopied(true);
        setSuccessShow({ state: true, message: 'Copied to clipboard' });
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

 

  return (
    <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
        <Box sx={{ flexDirection: "column" }}>
          <Box sx={{ display: 'flex' }}>
            {showEcommerceModal ?
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain', }} onClick={() => { setShowEcommerceModal(false) }} /> E-commerce URL
              </Typography>
              :
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "25px", fontStyle: "normal", fontWeight: "600", }}>
                Configurations
              </Typography>
            }
          </Box>
          <BreadCrumbs links={links} />
        </Box>
      </Box>
      {showEcommerceModal ?
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: "500px", }}>
            <Box>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: 2.36, color: '#707070', margin: '4px 10px 0px 0' }}>Domain Type.</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 'normal', lineHeight: 2.36, color: '#707070', margin: '0px 10px 11.8px 0' }}>Which domain type would you like to use?</Typography>
            </Box>
            <FormControl>
              <RadioGroup name="domain-types" value={domainType} onChange={handleFormData} sx={{}}>
                <Box sx={{ width: '407px', height: '80px', padding: '20.3px 26px 18.8px 0', border: 'solid 1px #fff', backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel value={'zedDomain'} control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ flexDirection: 'column', textWrap: 'wrap', color: '#b2b4bb', '&.Mui-checked': { color: '#032541', } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '1px 20px 2px 1px', objectFit: 'contain', color: '#032541', fontSize: '14px',}} />
                    <Box sx={{ width: '337px', height: '41px', margin: '0 0 0 20px' }}>
                      <Typography sx={{ color: '#000', fontSize: '14px', textAlign: 'left', fontWeight: '500' }}>Zed Domain</Typography>
                      <Typography sx={{ color: '#032541', fontSize: '14px', lineHeight: 1.14 }}>{url}</Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ width: '406px', height: '0.1px', margin: '19.8px 705.6px 0.3px 0', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
                </Box>
                <Box sx={{ width: '407px', height: '80px', padding: '0px 26px 18.8px 0', border: 'solid 1px #fff', backgroundColor: '#fff' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel value={'customDomain'} control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ flexDirection: 'column', textWrap: 'wrap', color: '#b2b4bb', '&.Mui-checked': { color: '#032541', } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '1px 20px 2px 1px', objectFit: 'contain', color: '#032541', fontSize: '14px',}} />
                    <Box sx={{ width: '337px', height: '41px', margin: '0 0 0 20px' }}>
                      <Typography sx={{ color: '#000', fontSize: '14px', textAlign: 'left', fontWeight: '500' }}>Custom Domain</Typography>
                      <Typography sx={{ color: '#707070', fontSize: '14px', lineHeight: 1.14, margin: '1px 0 0' }}>Use custom domain.</Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ width: '406px', height: '0.5px', margin: '19.8px 705.6px 0.3px 0', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
                </Box>
              </RadioGroup>
            </FormControl>
            {domainType == 'customDomain' && <Box sx={{ width: "100%", marginTop: "10px", marginBottom: '20px' }}>
              <TextField InputLabelProps={{ sx: { fontSize: '14px', color: '#032541', lineHeight: 1.14, letterSpacing: '0.47px', margin: '0 1.5px' } }} error={urlError} value={customUrl} onChange={(e) => setCustomUrl(e.target.value)} placeholder="Enter custom domain e.g eastwest.ke" helperText={urlError ? 'URL is invalid.' : ''} required label="Custom Domain" name="customUrl" InputProps={{ sx: { width: "407px", height: '54px', borderRadius: '4px', fontSize: "14px", "& fieldset": { borderColor: "#bec5d1", }, }, margin: '12px 0 0', padding: '17px 249px 17px 15px', borderRadius: '4px' }} />
            </Box>}
            <Box sx={{ display: "flex", justifyContent: 'flex-end' }}>
              <Button variant="contained" sx={{ backgroundColor: buttonBgColor(), color: "#fff", textTransform: "none", borderRadius: "5px", padding: "12.5px 37.7px 12.5px 39.3px", height: "45px", fontWeight: "600", "&:hover": { backgroundColor: buttonBgColor(), color: "#fff", }, width: '125px', margin: '20px 70px 0 10px' }} onClick={handleSubmit}>
                Enable
              </Button>
            </Box>
          </Box>
          {domainType === 'customDomain' && <Box sx={{ width: "564px", height: '515px', fontSize: '14px', color: '#707070', lineHeight: 2.36, fontWeight: 'normal' }}>
            <Typography sx={{ fontWeight: '500', color: '#032541', fontSize: '14px' }}>How to enable your custom domain on Zed E-commerce</Typography>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Typography sx={{ fontWeight: '500', color: '#032541', lineHeight: 2.36, fontSize: '14px' }}>Step 1. </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}>
                Enter your custom domain in the <b>'Enter Your Domain'</b> field. Click Enable.
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Typography sx={{ fontWeight: '500', color: '#032541', lineHeight: 2.36, fontSize: '14px' }}>Step 2. </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}>
                Go to your domain provider's website and login into your account.
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Typography sx={{ fontWeight: '500', color: '#032541', lineHeight: 2.36, fontSize: '14px' }}>Step 3. </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}> Find the DNS settings or domain management area.</Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Typography sx={{ fontWeight: '500', color: '#032541', lineHeight: 2.36, fontSize: '14px' }}>Step 4. </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}>
                Point the CNAME record with the custom domain name <b>{customUrl}</b> to <b>ecommerce{domain}</b>  Be sure to include the period at the end of the domain.
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Typography sx={{ fontWeight: '500', color: '#032541', lineHeight: 2.36, fontSize: '14px' }}>Step 5. </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}>
                Save your changes.
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#707070', lineHeight: 2.36 }}>
                Please note that it might take up to 48-72 hours before your custom url is active on the internet, depending on your domain registrar.
              </Typography>
            </Box>
          </Box>}
        </Box> :
          <Box>
            <Box style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
              <Box style={{ display: "flex", justifyContent: "" }}>
                <Box>
                  <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>
                    Add E-Commerce URL
                  </Box>
                  <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                    Add  E-Commerce URL to allow customers access <br /> your business online.
                  </Typography>
                  {ecommerceUrlStatus && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:"space-between", m: '10px 0px 10px 0px' }}>
                    <Typography sx={{ color: "#032541", fontSize: "14px", fontWeight: "600", mr: '10px' }}>{url!==''? url : customUrl} </Typography>
                    <Button startIcon={isCopied ? <CircularProgress size={20}/> : null} onClick={()=>handleCopyClick()} style={{textTransform:"inherit" ,backgroundColor: '#032541', color: '#fff'}}>Copy</Button>
                  </Box>
                  }
                  {ecommerceUrlStatus && <Box sx={{ display: 'flex', m: '10px 0px 10px 0px' }}>
                   {skipCustomization && <Button onClick={()=>navigate('/zed_ecommerce/dashboard')} sx={{ textTransform: 'capitalize', m: '13px 10px 0 18px', width: '100%', maxWidth: '159px', height: '45px', padding: '14.5px 20px 13.5px 21px', borderRadius: '4px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', color: '#707070', fontSize: '12px', fontWeight: 600, textAlign: 'center', textWrap: 'nowrap', '&:hover': { backgroundColor: '#fff', color: '#707070', boxShadow: 'none' } }} variant="contained">Skip Customization</Button>}
                    <Button onClick={()=>navigate('/zed_ecommerce/customizeStore')} sx={{ textTransform: 'capitalize', m: '13px 10px 0 18px', width: '100%', maxWidth: '220px', height: '45px', padding: '13.5px 20px 14.5px', borderRadius: '4px', backgroundColor: '#032541', color: '#fff', fontSize: '12px', fontWeight: 600, textAlign: 'center', textWrap: 'nowrap', '&:hover': { backgroundColor: '#032541', color: '#fff', boxShadow: 'none' } }} variant="contained">Customize Store Front</Button>
                    <Button sx={{ textTransform: 'capitalize', m: '13px 10px 0 18px', width: '100%', maxWidth: '109px', height: '45px', padding: '14.5px 20px 13.5px 21px', borderRadius: '4px', border: 'solid 1px #e8e8e8', backgroundColor: '#fff', color: '#707070', fontSize: '12px', fontWeight: 600, textAlign: 'center', textWrap: 'nowrap', '&:hover': { backgroundColor: '#fff', color: '#707070', boxShadow: 'none' } }} onClick={() => { setShowEcommerceModal(true); setLinks(...links, { label: 'Enable E-commerce URL', link: '' }) }} variant="contained">Edit URL</Button>
                  </Box>}
                </Box>
                <Box style={{ marginLeft: "auto" }}>
                  <Switch checked={ecommerceUrlStatus} onClick={() => {
                    if (!ecommerceUrlStatus) {
                      setShowEcommerceModal(true);
                    }
                  }}
                  />
                </Box>
              </Box>
            </Box>
            <Box style={{ padding: '14px 0', borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
              <Box style={{ display: "flex", justifyContent: "" }}>
                <Box>
                  <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>
                    Enable Additional Services
                  </Box>
                  <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                    Add additional services to products for a more <br /> personalised customer purchase.
                  </Typography>
                </Box>
                <Box style={{ marginLeft: "auto" }}>
                  <Switch checked={additionalServicesEnabled} onClick={handleAdditionalConfig} />
                </Box>
              </Box>
            </Box>
            <Box style={{ padding: '14px 0', borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
              <Box style={{ display: "flex", justifyContent: "" }}>
                <Box>
                  <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>
                    Enable Evouchers
                  </Box>
                  <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                    Set evouchers availability of different amount and track their purchases and expenditure
                  </Typography>
                </Box>
                <Box style={{ marginLeft: "auto" }}>
                  <Switch checked={evocherStatus} onClick={handleEvoucherConfig} />
                </Box>
              </Box>
            </Box>
          </Box>
      }
    </div>
  )
}

export default ConfigurationZed