import moment from "moment-timezone/moment-timezone-utils";
import secureLocalStorage from "react-secure-storage";

export default function DateFormatter(value) {
	try {

		const myDate = new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString();
		return myDate
	} catch (e) {
		
	}
}
export function DoMaskValue(value, num = 4, maskCharacter = '*') {
	try {
		const newValue = value.toString();
		if (newValue.length < num * 2) {
			return newValue
		}

		const maskStart = Math.floor((newValue.length - num) / 2);
		const maskEnd = maskStart + num;

		const maskedString = newValue.slice(0, maskStart) + Array(num).fill(maskCharacter).join('') + newValue.slice(maskEnd);
		return maskedString;

	} catch (error) {

	}

}

export const currencyconverter = (value, currency) => {

    let countryCurrency = secureLocalStorage.getItem('localCurrency');
    if((!countryCurrency) || (countryCurrency === undefined) || (countryCurrency === null) || (countryCurrency === "undefined")){
        countryCurrency = currency ? currency : 'KES'
    }
    return new Intl.NumberFormat('en-Us', {
         style:'currency',
         currency:countryCurrency
     }).format(value)
    
}