import { Select, MenuItem, InputLabel } from "@mui/material";
import React from 'react';

const CustomSelect = ({value, onChange, name, placeholder, options,medium, sm, xl, xs, ...rest }) => {
    return (
            <Select
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                variant="outlined"
                margin="dense"
                displayEmpty
                style={{width: sm==='sm' ? '180px' :  sm==='xl' ? "200px" : sm==='xs' ? '150px' : "10.438rem"}}
                sx={{marginBottom:2, marginTop:1}}
                inputProps={{ height: '3.438rem', fontSize:"0.875rem", "& ::placeholder": {color: '#c2cbd1'}, "& fieldset": {borderColor: "#c2cbd1"}}}
                MenuProps={{anchorOrigin: {vertical: "bottom", horizontal: "left"}, transformOrigin: {vertical: "top", horizontal: "left"}, getcontentanchorel: null}}
                selectprops={{
                    renderValue: (value) => {
                        if (value === "") {
                            return <em>{placeholder}</em>;
                        }
                        return value;
                    }
                }}
                {...rest}
            >
                {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
    );
};

export default CustomSelect;
