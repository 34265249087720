import { Button, Grid, TextField, Typography } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomInputLabel from "../common/CustomInputLabel";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import dayjs from 'dayjs';

const EditEvoucherCampaign = () => {
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    const [voucherArrayAmounts, setVoucherArrayAmounts] = useState([{ amount: "" }]);
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, voucherId] = newqueryParamValue.split("?")
    // get evoucher campaign details

    const getEvoucherList = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/evoucher/campaign/${voucherId}`,
        }).then((data) => {
            setName(data?.response?.data?.name)
            setStartDate(dayjs(data?.response?.data?.startDate))
            setEndDate(dayjs(data?.response?.data?.endDate))
            setVoucherArrayAmounts(data?.response?.data?.amounts)
        }).catch((error) => {
        });
    };
    useEffect(() => {
        getEvoucherList();
    }, [voucherId]);

    const disabled = !name || !startDate || !endDate
    const addNewVoucher = () => {
        setVoucherArrayAmounts([...voucherArrayAmounts, { voucherAmount: '' }]);
    };

    const handleAmountChange = (index, value) => {
        const updatedAmounts = [...voucherArrayAmounts];
        updatedAmounts[index].voucherAmount = value;
        setVoucherArrayAmounts(updatedAmounts);
    };

    const amountArray = voucherArrayAmounts?.map(item => Number(item?.voucherAmount));
    const gridFormatDate = (inputDate) => {
        const yyyy = new Date(inputDate)?.getFullYear();
        let mm = new Date(inputDate)?.getMonth() + 1;
        let dd = new Date(inputDate)?.getDate();
        let hrs = new Date(inputDate)?.getHours();
        let min = new Date(inputDate)?.getMinutes();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        if (hrs < 10) hrs = "0" + hrs;
        if (min < 10) min = "0" + min;

        const formattedToday = yyyy + "-" + mm + "-" + dd + " " + hrs + ":" + min;

        return formattedToday;
    };

    const handleSubmit = async () => {
        if (!name || !startDate || !endDate) {
            setErrorShow({ state: true, message: 'Please fill all the fields' })
            return
        }
        try {
            await HttpRequest({
                method: 'PUT',
                url: `/api/v1/evoucher/update_campaign/${voucherId}`,
                body: {
                    name,
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    amounts: amountArray
                },
            }).then(res => {
                if (res?.status === 200 || res?.status === 201) {
                    setSuccessShow({ state: true, message: res?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000)
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error?.message })
        }
    }
    return (
        <Grid container display={'flex'} width={'100%'} direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item width={'100%'}>
                <Grid container display={'flex'} alignItems={'center'} flexDirection={'row'}>
                    <ArrowBackIosIcon  sx={{ color: '#032541', fontSize: "16px", fontWeight: "600", }} />
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>
                        Edit Evoucher Campaigns
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sx={{ display: 'flex' }} lg={12} flexDirection={'column'} width={'35%'}>
                <CustomInputLabel label="Name" placeholder="Name *" />
                <TextField name={"name"} value={name} onChange={(e) => setName(e.target.value)} placeholder={"Name"} variant="outlined" InputProps={{ sx: { width: '100%', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
            </Grid>
            <Grid item sx={{ display: 'flex' }} lg={12} py={2} gap={2} flexDirection={'column'} width={'35%'}>
                <CustomInputLabel label="Start Date" placeholder="Start Date *" />
                <DateTimePicker label="Start Date" value={startDate} onChange={(newValue) => {
                    setStartDate(newValue);
                }} />
            </Grid>
            <Grid item sx={{ display: 'flex' }} lg={12} py={2} gap={2} flexDirection={'column'} width={'35%'}>
                <CustomInputLabel label="End Date" placeholder="End Date *" />
                <DateTimePicker label="End Date" value={endDate} onChange={(newValue) => {
                    setEndDate(newValue);
                }} />
            </Grid>
            <Grid item sx={{ display: 'flex' }} lg={12} py={2} flexDirection={'column'} width={'35%'}>
                <Typography variant="h4" sx={{ color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "600" }}>
                    Evoucher Amounts
                </Typography>
                <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal" }}>
                    Create  evoucher amounts
                </Typography>
            </Grid>
            {voucherArrayAmounts?.map((voucher, index) => (
                <Grid
                    item
                    key={index}
                    sx={{ display: "flex" }}
                    lg={12}
                    flexDirection={"column"}
                    width={"35%"}
                >
                    <CustomInputLabel label="Amount" placeholder="Amount *" />
                    <TextField
                        name={`amount-${index}`}
                        value={voucher.voucherAmount}
                        onChange={(e) => handleAmountChange(index, e.target.value)}
                        placeholder={"Amount"}
                        variant="outlined"
                        InputProps={{
                            sx: {
                                width: "100%",
                                fontSize: "0.875rem",
                                "& fieldset": { borderColor: "#bec5d1" },
                            },
                        }}
                    />
                </Grid>
            ))}
            <Grid item sx={{ display: 'flex' }} mt={2} lg={12} flexDirection={'column'} width={'35%'}>
                <Typography onClick={addNewVoucher} variant="h4" sx={{ color: "#000", fontSize: "14px", fontStyle: "normal", alignItems: 'center', cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', fontWeight: "600" }}>
                    <AddIcon />Add Another
                </Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'35%'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                <Button onClick={() => handleSubmit()} disabled={disabled} style={disabled ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>Update</Button>
            </Grid>
        </Grid>
    )
}

export default EditEvoucherCampaign;