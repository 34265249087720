
const PreorderTypes = () => {
    const primaryColor = 'var(--primary)';
    const types = {
        'normal': {  width: '100%', maxWidth: '113px', height: '100%', maxHeight: '20px', margin: '0 0 0px 100px', padding: '7px 11px 6px 12px', borderRadius: '3px', backgroundColor: primaryColor, alignItems: 'center', verticalAlign: 'middle',position:'absolute',zIndex: 1,textAlign: 'center'},
        'circular': {  width: '54px', height: '54px',margin: '0 1px 0px 0px', padding: '7px', backgroundColor: primaryColor, alignItems: 'center', verticalAlign: 'middle',borderRadius:'50%',alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex', position: 'absolute',zIndex:1 },
        'transformed': {  width: '100%', maxWidth: '93px', height: '100%', maxHeight: '20px', margin: '0px 152px 0 0px', padding: '7px 11px 6px 12px', borderRadius: '3px', backgroundColor: primaryColor, alignItems: 'center', verticalAlign: 'middle',transform: 'rotate(-30deg)',zIndex:1,position:'absolute',overflow:'hidden',textAlign:'center' },
        'outOfStock': { width: '100%', maxWidth: '93px', height: '100%', maxHeight: '20px', margin: '10px 152px 0 0px', padding: '7px 11px 6px 12px', borderRadius: '3px', backgroundColor:'red', alignItems: 'center', verticalAlign: 'middle',transform: 'rotate(-30deg)',zIndex:1,position:'absolute',overflow:'hidden',textAlign:'center' }

    }

    return types;
}

export default PreorderTypes;