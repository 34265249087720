import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography, Modal, TextField, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CustomSearchInput from '../common/CustomSearchInput';
import CustomTable from '../common/CustomTable';
import imgPlaceholder from '../../images/img-placeholder.svg';
import editIcon from '../../images/edit-icn.svg';
import deleteIcon from '../../images/delete-icn.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import warningIcon from '../../images/warning-remove-icn.svg';
import { Circle } from '@mui/icons-material';
import { HttpRequest } from '../HttpRequest';
import { SuccessAlert } from '../common/snackbar/success';
import { ErrorAlert } from '../common/snackbar/error';
import secureLocalStorage from 'react-secure-storage';

const deleteCategoryModal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 102px 165px 126px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const Categories = () => {
  const [search, setSearch] = useState('');
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false)
  const handleOpenDeleteCategory = () => { setOpenDeleteCategory(true) }
  const handleCloseDeleteCategory = () => { setOpenDeleteCategory(false) }
  const [parentCategories, setParentCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState("");
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

  const navigate = useNavigate();
  const rows = parentCategories;
  const businessNumber = secureLocalStorage.getItem("businessId");
  const handleDeleteCategory = (id) => {
    setOpenDeleteCategory(false);
    HttpRequest({
      method: 'PUT',
      url: `/api/updateCategory`,
      body: {
        categoryState: "Deactivation",
        categoryId: [id]
      },
    }).then((data) => {
      if (data.status === 202) {
        setSuccessShow({ state: true, message: "Category deleted successfully!" })
        getCategories();
        setCategoryToDelete({});
      } else {
        const message = data.response.message;
        setErrorShow({ state: true, message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: 'Failed to delete category!' })
    });
  }
  const handleRowClick = (event, params) => {
    event.stopPropagation();
    navigate(`/zed_ecommerce/categories/${params?.row?._id}/edit`)
  }
  const columns = [
    {
      field: 'name', headerName: 'Name', flex: 1,
      renderHeader: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Name </Box>)
      },
      renderCell: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <img src={params?.row?.thumbnail ?? imgPlaceholder} alt='' style={{ width: '40px', height: '40px', margin: '20px 20px 20px 0', objectFit: 'contain' }} />
            <Typography style={{ margin: '20px',fontWeight:params?.row?.parentCategoryName!==null ? 'normal' : '600' }}> {params?.row?.parentCategoryName!==null ? '---' : ''} {params?.row?.categoryName}</Typography>
          </Box>)
      },
    },
    {
      field: 'categoryDescription', headerName: 'Description', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Description  </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <Typography style={{ margin: '20px' }}>{params?.row?.categoryDescription}</Typography>
          </Box>)
      }
    },
    {
      field: 'parentCategoryName', headerName: 'Parent Category', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Parent Category  </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <Typography style={{ margin: '20px' }}>{params?.row?.parentCategoryName}</Typography>
          </Box>)
      }
    },
    {
      field: 'items', headerName: 'Items', flex: 1,
      renderHeader: (params) => { return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Items  </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <Typography style={{ margin: '20px' }}>-</Typography>
          </Box>)
      }
    },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderHeader: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Actions </Box>)
      },
      renderCell: (params) => {
        const onClickDelete = (event) => {
          event.stopPropagation();
          setCategoryToDelete(params?.row);
          handleOpenDeleteCategory();
        }
        return (
          <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <Button onClick={(event)=>handleRowClick(event,params)} style={{ textDecoration: 'none' }}> <img src={editIcon} alt='' style={{ width: 20, height: 20, margin: ' 0 21px 0 0', objectFit: 'contain' }} /> </Button>
            <Button onClick={onClickDelete} style={{ textDecoration: 'none' }}> <img src={deleteIcon} alt='' style={{ width: 20, height: 20, margin: ' 0 21px 0 0', objectFit: 'contain' }} /> </Button>
          </Box>)
      },
    }
  ];
  const getCategories = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}&page=${pageState.page}&limit=${dataGridPageSize}&categoryName=${search}`
    }).then((data) => {
      const categories = data.response.data;
      const flattenRows = [];

      const flattenCategories = (categories) => {
        const rows = [];

        categories.forEach(category => {
          const row = {
            _id: category._id,
            categoryName: category.categoryName.trim(),
            categoryDescription: category.categoryDescription,
            parentCategoryName: category.parentCategoryName || null,
            items: category.items || '_',
            categoryState: category.categoryState,
            thumbnail: category.thumbnail ?? imgPlaceholder,
            subCategories:category?.subCategories
          };
          rows.push(row);
          if (category.subCategories && category.subCategories.length > 0) {
            const subRows = flattenCategories(category.subCategories);
            rows.push(...subRows);
          }
        });

        return rows;
      };
      categories.forEach(category => {
        const row = {
          _id: category._id,
          categoryName: category.categoryName.trim(),
          categoryDescription: category.categoryDescription,
          parentCategoryName: category.parentCategoryName || null,
          items: category.items || '_',
          categoryState: category.categoryState,
          subCategories:category?.subCategories
        };
        flattenRows.push(row);
        if (category.subCategories && category.subCategories.length > 0) {
          const subRows = flattenCategories(category.subCategories);
          flattenRows.push(...subRows);
        }
      });
      const activeCategories = flattenRows.filter(category => category.categoryState === "Active");
      setParentCategories(activeCategories);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        total: data?.response.count,
        data: activeCategories,
      }));
    }).catch((error) => {
      
    }).finally(() => {
      setLoading(false);

    });
  };


  useEffect(() => {
    getCategories()
  }, [dataGridPageSize, pageState.page, businessNumber,search])
  return (
    <>
      <Box component="main" sx={{ margin: '3% 0 0 22px', padding: '0px 34px 136px 5px' }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Grid container spacing={2} columns={2}>
          <Grid item sx={{ display: 'flex' }} lg={12}>
            <Typography sx={{ width: '136px', fontFamily: 'Poppins', height: '20px', margin: '30px 823px 1px 0', fontSize: '28px', fontWeight: 600 }}>
              Categories
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex' }} lg={12}>
            <Typography sx={{ margin: '10px 675px 19.5px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', color: '#707070' }}>
              View and manage your product categories.
            </Typography>
            <Button variant='contained' sx={{ margin: '0px 0.5px 36.5px 400px', backgroundColor: '#032541', textTransform: 'capitalize', textWrap: 'nowrap', "&:hover": { backgroundColor: '#032541' } }}>
              <Link to='/zed_ecommerce/addCategories' style={{ textDecoration: 'none', color: '#fff' }}>Add Category</Link>
            </Button>
          </Grid>
          <Grid item lg={12}>
            <Divider sx={{ width: '100%', height: '1px', margin: '-39.5px 0 19.5px 0.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sx={{ display: 'flex' }} lg={12}>
            <CustomSearchInput placeholder="Search" name="search" value={search} onChange={(event)=>setSearch(event.target.value)}/>
          </Grid>
          <Grid item lg={12}>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Modal open={openDeleteCategory} onClose={handleCloseDeleteCategory} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={deleteCategoryModal}>
          <Box sx={{ padding: "10px" }}>
            <Box sx={{ display: 'flex' }}>
              <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
              <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                <Typography sx={{ height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                  Delete Category?
                </Typography>
                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                  Selected category will be deleted. <br /> This action cannot be undone.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Circle sx={{ width: '8px', height: '8px', margin: '6px 8px 6px 0', backgroundColor: '#0c253f' }} />
                  <Typography sx={{ width: '121px', height: '20px', margin: "0 0 0 8px", fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#032541' }}>
                    {categoryToDelete.categoryName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
              <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff", border: "solid 1px #002543" } }} onClick={handleCloseDeleteCategory} >Cancel</Button>
              <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => handleDeleteCategory(categoryToDelete._id)}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Categories;
