import React from 'react';
import NotFoundImage from '../images/4042.png'; // Import your custom 404 illustration

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <img src={NotFoundImage} alt="404 Not Found" style={styles.image} />
      <h1 style={styles.heading}>Oops!</h1>
      <p style={styles.text}>The page you're looking for doesn't exist.</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
    padding: '20px',
    background: '#f0f0f0', // Custom background color
  },
  image: {
    width: '60%',
    maxWidth: '400px',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#333',
  },
  text: {
    fontSize: '18px',
    color: '#666',
  },
};

export default NotFoundPage;
