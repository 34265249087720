import { Box, Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


const EvoucherBannerCounter = ({ days, hours, mins, secs }) => {
    const theme = useTheme();
    const isMuiMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation()
    const [showBuyButton , setShowBuyButton] = useState(false);
    useEffect(()=>{
        if(location.pathname === '/evouchers'){
            setShowBuyButton(false)
        }else{
            setShowBuyButton(true)
        }
    },[location])
    const navigate = useNavigate();
    return (
        <Grid container width={'100%'}>
            <Grid item xs={12}>
                <Paper sx={{ backgroundColor: '#fbfbfb', color: '#fff', height:isMuiMobile ? '200px': '100px' }}>
                    <Box component="div" sx={{ display: 'flex', width: '100%', flexDirection:isMuiMobile ? "column":"row", alignItems: 'center', height: '100%'  }}>
                        <Box component="div" sx={{ display: 'flex', flex:isMuiMobile ? 1: 0.5 , gap:1, flexDirection:"column" , pl: 2, pr: showBuyButton ? 2:0  }}>
                            <Typography variant="h5" sx={{ color: '#ef0b0a', fontSize: '24px', fontWeight: 'bold' }}>
                                Don’t Miss out!
                            </Typography>
                            <Typography variant="h6" sx={{ color: '#000000', fontSize: '14px' }}>
                                For a limited time, grab our exclusive eVouchers before they’re gone.
                            </Typography>
                        </Box>
                        <Box component="div" sx={{ display: 'flex', width:"100%", flex: isMuiMobile ? 1: 0.5 , flexDirection:"row" , gap: 8 , justifyContent: isMuiMobile ? 'center': 'flex-end', alignItems: 'center', pr:2 }}>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1}>
                                <Typography variant="h5" sx={{ color: '#02b3ad', fontSize: '24px', fontWeight: 'bold' }}>{days}</Typography>
                                <Typography variant="p" sx={{ color: '#000000', fontSize: '15px' }}>days</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}  gap={1}>
                                <Typography variant="h5" sx={{ color: '#02b3ad', fontSize: '24px', fontWeight: 'bold' }}>{hours}</Typography>
                                <Typography variant="p" sx={{ color: '#000000', fontSize: '15px' }}>Hrs</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}  gap={1}>
                                <Typography variant="h5" sx={{ color: '#02b3ad', fontSize: '24px', fontWeight: 'bold' }}>{mins}</Typography>
                                <Typography variant="p" sx={{ color: '#000000', fontSize: '15px' }}>Mins</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}  gap={1}>
                                <Typography variant="h5" sx={{ color: '#02b3ad', fontSize: '24px', fontWeight: 'bold' }}>{secs}</Typography>
                                <Typography variant="p" sx={{ color: '#000000', fontSize: '15px' }}>Secs</Typography>
                            </Box>
                        </Box>
                        {showBuyButton &&
                        <Box component="div" sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', pr: 2 }}>
                            <Button onClick={() =>navigate(`/evouchers`)} variant="contained" sx={{ backgroundColor: '#02b3ad', color: '#fff', fontSize: '14px', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px' , textTransform:'inherit' }}>Buy Now</Button>
                        </Box>}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default EvoucherBannerCounter