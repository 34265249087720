import secureLocalStorage from "react-secure-storage";

export default function generateImageUrl(selectedFile) {
  return new Promise((resolve, reject) => {
    try {
      const base_url = process.env.REACT_APP_BASE_URL;
      const url = `/api/v1/ecommerce/generate_file_system_url`;
      const finalUrl = `${base_url}${url}`;
      let token = secureLocalStorage.getItem('token');

      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", finalUrl);
      xhr.setRequestHeader("X-Authorization", token);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            if (xhr.status === 200) {
              const response = JSON.parse(xhr.response);
              const imageUrl = response.data;
              const thumbnailUrl = response.thumbnailurl;
              resolve({ imageUrl, thumbnailUrl }); 
            } else {
              const response = JSON.parse(xhr.response);
              reject(new Error("XHR request failed")); 
            }
          } catch (error) {
            const errorMessage = extractErrorMessage(xhr.responseText);
            reject(new Error(`Unexpected response format: ${errorMessage}`));
          }
        }
      };

      xhr.send(formData);
    } catch (error) {
      reject(error);
    }
  });
}

function extractErrorMessage(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const pre = doc.querySelector('pre');
  return pre ? pre.textContent : 'Unknown error';
}
