import React from 'react';
import { Box } from '@mui/material';

const OrderStatus = ({ orderStatus = 'Pending', deliveryMethod = 'ship' }) => {
    console.log(orderStatus, deliveryMethod  , 'props')
    const statuses = {
        ship: {
            Received: { label: 'Received', color: '#707070' },
            Processing: { label: 'Processing', color: '#032541' },
            ReadyToShip: { label: 'Ready to Ship', color: '#032541' },
            Shipped: { label: 'Shipped', color: '#000000' },
            Delivered: { label: 'Delivered', color: '#17ae7b' },
            Pending: { label: 'Pending', color: '#dc3545' },
        },
        pickup: {
            Received: { label: 'Received', color: '#707070' },
            Processing: { label: 'Processing', color: '#032541' },
            ReadyToShip: { label: 'Ready for Pickup', color: '#032541' },
            Shipped: { label: 'Picked Up', color: '#000000' },
            Delivered: { label: 'Picked Up', color: '#17ae7b' },
            Pending: { label: 'Pending', color: '#dc3545' },
        }
    };

    const deliveryStatuses = statuses[deliveryMethod];
    const statusInfo = deliveryStatuses?.[orderStatus] || { label: 'Pending', color: '#dc3545' };

    return (
        <Box sx={{ color: statusInfo.color, fontWeight: 600, fontSize: '16px' }}>
            {statusInfo.label}
            {/* {deliveryMethod} */}
        </Box>
    );
};

export default OrderStatus;
