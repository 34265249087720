import { createSlice } from "@reduxjs/toolkit"

let initialState = {
    method: '',
    pin: '',
    channel:''
}

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        setResetMethod: (state, action) => {
            state.method = action.payload;
        },
        setResetPin: (state, action) => {
            state.pin = action.payload
        },
        setResetChannel: (state, action) => {
            state.channel = action.payload
        }
    }
});

export const {setResetMethod,setResetPin,setResetChannel} = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;