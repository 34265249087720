import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EvoucherImg from '../../images/evoc.svg'
import { useEffect, useState } from "react";
import { HttpRequest } from "../HttpRequest";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import DateFormatter from "../common/dateFormatter";
import CurrencyFormatter from "../common/currencyFormatter";
import ExportData from "../common/exportData";
import CustomSearchInput from "../common/CustomSearchInput";
import CustomSelect from "../common/customSelectInput";
import { CustomizedDate } from "../common/customDate";
import { startOfToday, startOfYesterday, endOfToday, endOfYesterday, subDays, startOfMonth, endOfMonth, subMonths, format, parse } from 'date-fns';

const EvoucherDetails = () => {
    const navigate = useNavigate()
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamValue, voucherId] = newqueryParamValue.split("?")
    const [voucherSummarry, setVoucerSummary] = useState(null)
    const [voucherData, setVoucherData] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [vouchersToExport , setVouchersToExport] = useState([])
    const [range, setRange] = useState('');
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const handleRowClick = (event, rowId) => {
        event.stopPropagation();
        navigate(`/zed_ecommerce/evoucher/${rowId}`)
    }

    const getLastMonthRange = (date) => {
        const previousMonth = subMonths(date, 1);
        const start = startOfMonth(previousMonth);
        const end = endOfMonth(previousMonth);
        return { start, end };
      };

    const ranges = [
        { id: 1, label: 'Select Date', value: '' },
        { id: 2, label: 'Today', value: 'today' },
        { id: 3, label: 'Yesterday', value: 'yesterday' },
        { id: 4, label: 'Last 7 Days', value: 'oneWeek' },
        { id: 5, label: 'This Month', value: 'thisMonth' },
        { id: 6, label: 'Last Month', value: 'lastMonth' },
        { id: 7, label: 'Custom Range', value: 'customDate' },
      ];
      useEffect(() => {
        const updateDates = (range) => {
          const today = new Date();
          let fromDate = null;
          let toDate = null;
    
          switch (range) {
            case 'today':
              fromDate = format(startOfToday(), 'MM/dd/yyyy');
              toDate = format(endOfToday(), 'MM/dd/yyyy');
              break;
            case 'yesterday':
              fromDate = format(startOfYesterday(), 'MM/dd/yyyy');
              toDate = format(endOfYesterday(), 'MM/dd/yyyy');
              break;
            case 'oneWeek':
              fromDate = format(subDays(today, 7), 'MM/dd/yyyy');
              toDate = format(endOfToday(), 'MM/dd/yyyy');
              break;
            case 'thisMonth':
              fromDate = format(startOfMonth(today), 'MM/dd/yyyy');
              toDate = format(endOfMonth(today), 'MM/dd/yyyy');
              break;
            case 'lastMonth':
              ({ start: fromDate, end: toDate } = getLastMonthRange(today));
              fromDate = format(fromDate, 'MM/dd/yyyy');
              toDate = format(toDate, 'MM/dd/yyyy');
              break;
            default:
              fromDate = null;
              toDate = null;
              break;
          }
    
          setFrom(fromDate);
          setTo(toDate);
        };
    
        updateDates(range);
      }, [range]);

    const [tableColumns, setTableColumns] = useState([
        {
          field: 'voucherNumber',
          headerName: 'Voucher Number',
        },
        {
          field: 'name',
          headerName: 'Recipient Name',
        },
        {
          field: 'phone',
          headerName: 'Phone',
        },
        {
          field: 'createdAt',
          headerName: 'Date Purchased',
        },
        {
          field: 'campaignName',
          headerName: 'Campaign Name',
        },
        {
            field: 'amount',
            headerName: 'Amount',
          },
          {
            field: 'redeemed',
            headerName: 'Redeemed',
          },
          {
            field: 'balance',
            headerName: 'Balance',
          }
      ]);

    const EvoucherDetailsColumn = [
        {
            field: 'voucherNumber', headerName: 'Voucher Number', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Voucher Number</Box>) },
            renderCell: (params) => {
                return (
                    <Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }} > {params?.row?.voucherNumber}  </Box>)
            }
        },

        {
            field: 'name', headerName: 'Recipient Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Recipient Name</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.name}  </Box>) }
        },

        {
            field: 'phone', headerName: 'Phone', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Phone Number</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.phone}  </Box>) }
        },
        {
            field: 'createdAt', headerName: 'Date Purchased', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Date Purchased</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{DateFormatter(params?.row?.createdAt)}  </Box>) }
        },
        {
            field: 'campaignName', headerName: 'Campaign Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Campaign Name</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.campaignName ?? 'N/A'}  </Box>) }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Amount</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.amount, 'KES')}</Box>) }
        },
        {
            field: 'redeemed', headerName: 'Redeemed', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Redeemed</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#17ae7b", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.redeemed, 'KES')}</Box>) }
        },
        {
            field: 'balance', headerName: 'Balance', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "700" }}>Balance</Box>) },
            renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?.voucherId)} style={{ cursor: 'pointer', color: "#f79009", fontSize: "15px", fontWeight: "500" }}>{CurrencyFormatter(params?.row?.balance)}</Box>) }
        }

    ];


    // export data
    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px',verticalAlign:'middle' }}>
            <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <Box sx={{ mr: '2%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {range !== 'customDate' &&
            <CustomSelect
              sx={{ mr: '10px', mt: '8px' }}
              value={range}
              options={ranges}
              sm={"lg"}
              onChange={(e) => setRange(e.target.value)}
            />}
            {range === 'customDate' && (
              <CustomizedDate setDateFrom={setFrom} setDateTo={setTo} style={{ display: "flex", alignItems: "center" }} />
            )}
          </Box>
            <ExportData title={'Evoucher Transactions'} data={vouchersToExport} tableColumn={tableColumns} />
          </GridToolbarContainer>
        );
      }



    const getEvoucherList = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpRequest({
            method: 'GET',
            url: `/api/v1/evoucher/campaign/${voucherId}?page=${pageState?.page}&limit=${dataGridPageSize}&searchValue=${searchValue}&startDate=${from == null ? '':from}&endDate=${to == null ? '':to}`,
        }).then((data) => {
            setVoucerSummary(data?.response?.voucherSummary)
            setVoucherData(data?.response?.data)
            setPageState({
                ...pageState,
                data: data?.response?.vouchers,
                total: data?.response?.count,
                isLoading: false
            });
            const filteredData = data?.response?.vouchers?.map(({ _id,createdAt,amount, ...rest})=> ({ ...rest , createdAt:DateFormatter(createdAt) , amount:CurrencyFormatter(amount) }));
            setVouchersToExport(filteredData);
        }).catch((error) => {
        });
    };
    useEffect(() => {
        getEvoucherList();
    }, [voucherId, pageState.page, dataGridPageSize , from , to , searchValue]);



    return (
        <Grid container width={'100%'} display={'flex'} direction={'column'}>
            <Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item width={'100%'} display={'flex'} alignItems={'center'} flexDirection={'row'}>
                    <ArrowBackIosIcon onClick={() => navigate(-1)} sx={{ color: '#032541', fontSize: "16px", fontWeight: "600", }} />
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>
                        Evoucher  Details
                    </Typography>
                </Grid>
                {/* <Grid item width={'100%'} display={'flex'} gap={1} alignItems={'center'} justifyContent={'flex-end'} flexDirection={'row'}>
                    <Button variant="contained" sx={{ textTransform: 'inherit', backgroundColor: "#032541", color: "#fff", width: '150px' }}>  Edit </Button>
                    <Button sx={{ textTransform: 'inherit', color: "#dc3545", border: '1px solid #dc3545', width: '150px' }}>  Delete </Button>
                </Grid> */}
            </Grid>
            <Grid item mt={2} xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
                <Grid container width={'100%'} display={'flex'} spacing={1} justifyContent={'space-between'}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '100%', padding: '20px', height: '80px' }}>
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box component={'div'} sx={{ display: 'flex' }}>
                                    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box component={'img'} src={EvoucherImg} />
                                        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                            <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>
                                                {voucherData?.name}
                                            </Typography>
                                            <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>
                                                Created on {DateFormatter(voucherData?.createdAt)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box component={'div'} sx={{ display: 'flex' }}>
                                    <Box component="div" sx={{ display: 'flex' }}>
                                        {voucherData?.status === 'Upcoming' ? (
                                            <Chip
                                                label={voucherData?.status}
                                                sx={{
                                                    backgroundColor: '#fcf6ef',
                                                    color: '#f79009',
                                                    fontSize: '12px',
                                                    width: '100px',
                                                    fontWeight: '600',
                                                }}
                                            />
                                        ) : voucherData?.status === 'Ended' ? (
                                            <Chip
                                                label={voucherData?.status}
                                                sx={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#ffffff',
                                                    fontSize: '12px',
                                                    width: '100px',
                                                    fontWeight: '600',
                                                }}
                                            />
                                        ) : voucherData?.status === 'Ongoing' ? (
                                            <Chip
                                                label={voucherData?.status}
                                                sx={{
                                                    backgroundColor: '#f0f8f5',
                                                    color: '#17ae7b',
                                                    fontSize: '12px',
                                                    width: '100px',
                                                    fontWeight: '600',
                                                }}
                                            />
                                        ) : null}
                                    </Box>

                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} gap={2} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '50%', padding: '20px', height: '80px' }}>
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', height: "100%", alignItems: 'center' }}>
                                <Box component={'div'} sx={{ display: 'flex', width: '100%', height: "100%", alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', widthh: '100%' }}>
                                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Starts</Typography>
                                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{DateFormatter(voucherData?.startDate)}</Typography>
                                    </Box>
                                    <CalendarMonthIcon />
                                </Box>
                            </Box>
                        </Paper>
                        <Paper elevation={1} sx={{ width: '50%', padding: '20px', height: '80px' }}>
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', height: "100%", alignItems: 'center' }}>
                                <Box component={'div'} sx={{ display: 'flex', width: '100%', height: "100%", alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column', widthh: '100%' }}>
                                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Ends</Typography>
                                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{DateFormatter(voucherData?.endDate)}</Typography>
                                    </Box>
                                    <CalendarMonthIcon />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} xs={12} sm={12} md={12} lg={12} xl={12} width={'100%'}>
                <Grid container width={'100%'} display={'flex'} spacing={1} justifyContent={'space-between'}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '100%', padding: '20px', height: '80px' }}>
                            <Box component={'div'} sx={{ display: 'flex', flexDirection: "column", gap: 2 }}>
                                <Typography variant="h6" sx={{ color: "#000", fontWeight: 'bold' }}>Statistics Based on Quantity</Typography>
                                <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', height: "100%", alignItems: 'center' }}>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Qty Purchased</Typography>
                                        <Typography variant="h4" sx={{ color: "#3da2f8", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.qtyPurchased}</Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Qty Redeemed</Typography>
                                        <Typography variant="h4" sx={{ color: "#17ae7b", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.qtyRedeemed}</Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Qty Unused</Typography>
                                        <Typography variant="h4" sx={{ color: "#f79009", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.qtyUnused}</Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Qty Expired</Typography>
                                        <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.qtyExpired} </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} display={'flex'} justifyContent={'space-between'}>
                        <Paper elevation={1} sx={{ width: '100%', padding: '20px', height: '80px' }}>
                            <Box component={'div'} sx={{ display: 'flex', flexDirection: "column", gap: 2 }}>
                                <Typography variant="h6" sx={{ color: "#000", fontWeight: 'bold' }}>Statistics based on Amount</Typography>
                                <Box component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', height: "100%", alignItems: 'center' }}>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Total Purchased</Typography>
                                        <Typography variant="h4" sx={{ color: "#3da2f8", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.totalPurchased.toFixed(2)} </Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Total Redeemed</Typography>
                                        <Typography variant="h4" sx={{ color: "#17ae7b", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.totalRedeemed.toFixed(2)}</Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Total Unused</Typography>
                                        <Typography variant="h4" sx={{ color: "#f79009", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.totalUnused.toFixed(2)} </Typography>
                                    </Box>
                                    <Box component={'div'} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>Total Expired</Typography>
                                        <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "16px", fontStyle: "normal", fontWeight: "600", }}>{voucherSummarry?.totalExpired.toFixed(2)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item width={'100%'} mt={2}>
                <Grid display={'flex'} flexDirection={'column'} container spacing={2} sx={{ width: '100%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'space-between'}>
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'}>
                        <DataGrid
                            sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                            rowCount={pageState.total}
                            pagination
                            loading={pageState.isLoading}
                            page={pageState.page - 1}
                            pageSizeOptions={[1, 5, 10, 20, 50, 100]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: dataGridPageSize,
                                        page: pageState.page - 1,
                                    },
                                },
                            }}
                            pageSize={dataGridPageSize}
                            onPaginationModelChange={(data) => {
                                const { pageSize, page } = data;
                                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                                setPageSize(pageSize)
                            }}
                            paginationMode="server"
                            columns={EvoucherDetailsColumn}
                            rows={pageState.data}
                            getRowId={(row) => row?._id}
                            slots={{
                                toolbar: CustomToolbar,
                              }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EvoucherDetails