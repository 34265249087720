import React, { useState, useEffect } from 'react';
import { Box, TextField, InputAdornment, Button, IconButton, Typography, Paper, Stack, Grid, ListItem, ListItemButton, ListItemText, List, Divider, Drawer, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Person2Icon from '@mui/icons-material/Person2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HttpRequest } from '../HttpRequest';
import logoImage from '../../images/eastwestLogo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import secureLocalStorage from 'react-secure-storage';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import TextIcon from '../common/TextIcon';

const drawerWidth = 240;
const navItems = [{
  name: 'Home',
  url: '/',
},
{
  name: 'Products',
  url: '/products',
},
{
  name: 'Evouchers',
  url: '/evouchers',
},
{
  name: 'Contact Us',
  url: '/contacts',
},
{
  name: 'Blogs',
  url: '/blogs',
},
{
  name: 'FAQs',
  url: '/faqs',
}];

const SearchNavBar = (props) => {
  const stateCart = useSelector((state) => state.cart);
  let cart = stateCart.cart;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { logo } = useSelector((state) => state.template);
  const {evoucherEnabled} = useSelector((state) => state.configs);


  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const primaryColor = 'var(--primary)';
  const secondaryColor = 'var(--secondary)';
  const bodyColor = 'var(--body)';


  let { isLoggedIn } = useSelector((store) => store?.user);
  let firstName = secureLocalStorage.getItem('username');
  const [showAllCategories, setShowAllCategories] = useState(false);

  const handleToggleShowAllCategories = () => {
    setShowAllCategories(!showAllCategories);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const location = useLocation();
  const handleProfileRedirect = () => {
    if (isLoggedIn) {
      navigate('/zed-ecommerce/customer-profile');
    } else {
      navigate('/login' ,{state:{previousPath:location.pathname }});
    }
  }
  const getCategories = () => {
    setLoading(true);
    let businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}`
    }).then(async (data) => {
      const filteredCategories = data.response.data.filter(category => category.subCategories.length > 0);
      let subcategories = [];
      filteredCategories.forEach((item) => {
        subcategories = subcategories.concat(item.subCategories);
      });
      setCategories(subcategories);
    }).catch((error) => {
    }).finally(() => {
      setLoading(false);
    });
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleSearchClick = (query) => {
    setSearchResult([]);
    navigate(`/products?q=${query}`)
  }
  const handleCategoryClick = (query) => {
    setSearchResult([]);
    handleDrawerToggle();
    navigate(`/products?categoryName=${query}`)
  }
  const handleMenuClick = (url) => {
    setSearchResult([]);
    handleDrawerToggle();
    navigate(url)
  }
  useEffect(() => {
    getCategories();
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', justifyContent: 'center' }}>
      <Grid container spacing={2} sx={{ alignItems: 'center', spacing: 2 }}>
        <Grid item xs={12} sx={{ justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: '20px' }}>
            <Box sx={{ maxWidth: '99.1px', ml: '10%', cursor: 'pointer' }} onClick={() => navigate('/')}>
              {logo?.logoType === 'logo' ?
                <img src={logo?.logoPath ?? logoImage} alt="logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                :
                <TextIcon text={logo?.logoText} width={'100px'} height={'100px'} backgroundColor={'#fff'} textColor={primaryColor} />
              }
            </Box>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { sm: 'block' } }}>
              <CloseIcon size='large' sx={{ width: '100%', objectFit: 'contain' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h6" sx={{ ml: 2, textAlign: 'left', fontWeight: 500 }}>
        Menu
      </Typography>
      <List>
        {navItems?.map((item) => (
          <ListItem onClick={() => handleMenuClick(item.url)} key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
               <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h6" sx={{ ml: 2, textAlign: 'left', fontWeight: 500 }}>
        Categories
      </Typography>
      <Box>
        <List>
          {(showAllCategories ? categories : categories.slice(0, 5)).map((item) => (
            <ListItem
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCategoryClick(item?.categoryName)}
              key={item?._id}
              disablePadding
            >
              <ListItemButton sx={{ textAlign: 'left' }}>
                <ListItemText primary={item?.categoryName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {categories?.length > 5 && (
          <Box onClick={handleToggleShowAllCategories} sx={{ color: '#808285', fontSize: '14px', textTransform: 'capitalize', textAlign: 'center', m: '10px 0' }}>
            {showAllCategories ? (
              <Box sx={{ display: 'flex', pl: '10px' }}>
                Show Less <KeyboardArrowUp sx={{ marginLeft: '5px' }} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', pl: '10px' }}>
                Show More <KeyboardArrowDown sx={{ marginLeft: '5px' }} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
  const SearchBarMobile = () => {
    return (
      <Box sx={{ alignItems: 'center', justifyContent: 'center', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }, width: '100%' }}>
        <TextField name="search" value={searchQuery} onInput={(e) => { setSearchQuery(e.target.value); setIsFocused(true) }} placeholder="Search …" variant="outlined" margin="dense" autoFocus={isFocused} sx={{
          width: '100%', maxWidth: { xs: '100%', sm: '712px' }, ml: '1%', mr: '1%', border: `solid 1px ${secondaryColor}`,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: `solid 1px ${secondaryColor}`,
            },
            '&:hover fieldset': {
              border: `solid 1px ${secondaryColor}`,
            },
            '&.Mui-focused fieldset': {
              border: `solid 1px ${secondaryColor}`,
            },
          },
          '& .MuiInputBase-input': {
            color: bodyColor,
          },
          '& .MuiInputBase-input::placeholder': {
            color: bodyColor,
            opacity: 1,
          },
        }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button edge="end" style={{ width: '100%' }} onClick={() => { handleSearchClick(searchQuery); }}>
                  <SearchIcon sx={{ color: primaryColor }} />
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <Stack sx={{ position: 'absolute', zIndex: 10, maxWidth: { xs: '330px', sm: '700px' }, width: '100%' }}>
          <Paper>
            {searchResult.map((item) => (
              <Box onClick={() => { handleSearchClick(item.productName); }} key={item.id}
                sx={{ textAlign: 'left', p: 1 }}>
                <Typography>{item.productName}</Typography>
              </Box>
            ))}
          </Paper>
        </Stack>
      </Box>
    );
  }


  const fetchProducts = async () => {
    const businessNumber = secureLocalStorage.getItem('businessId');
    try {
      const data = await HttpRequest({
        method: 'GET',
        url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&search=${searchQuery}`,
      });
      setSearchResult(data.response.data);
      setOpen(true);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      fetchProducts();
    } else {
      setSearchResult([]);
    }
  }, [searchQuery]);
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <Box container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '61.4px', margin: '21px 0px 24.6px 0px' }}>
        <Box sx={{width:'100%', maxWidth: '99.1px', cursor: "pointer", height: '100%' }} onClick={() => navigate('/')}>
          {logo?.logoType === 'logo' ?
            <img src={logo?.logoPath ?? logoImage} alt="logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
            :
            <TextIcon text={logo?.logoText} width={'100%'} height={'100%'} backgroundColor={'#fff'} textColor={primaryColor} />
          }
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
          <OutlinedInput
            name="search"
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            placeholder="What do you need?…"
            variant="outlined"
            margin="dense"
            autoFocus={isFocused}
            onFocus={handleFocus}
            fullWidth
            sx={{
              width: '596px',
              paddingRight: '0px',
              fontSize: '14px',
              color: bodyColor,
              fontWeight: 'normal',
              border: `solid 1px ${secondaryColor}`,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: `solid 1px ${secondaryColor}`,
                },
                '&:hover fieldset': {
                  border: `solid 1px ${secondaryColor}`,
                },
                '&.Mui-focused fieldset': {
                  border: `solid 1px ${secondaryColor}`,
                },
              },
              '& .MuiInputBase-input': {
                color: bodyColor,
              },
              '& .MuiInputBase-input::placeholder': {
                color: bodyColor,
                opacity: 1,
              },
            }}
            endAdornment={(
              <InputAdornment position="end">
                <Button
                  edge="end"
                  style={{
                    backgroundColor: secondaryColor,
                    height: 'auto',
                    padding: '14px 16px 14px 15px',
                    width: '51px',
                  }}
                  onClick={() => { handleSearchClick(searchQuery) }}
                >
                  <SearchIcon sx={{ color: primaryColor }} />
                </Button>
              </InputAdornment>
            )}
          />

          <Stack sx={{ position: 'absolute', zIndex: 10, width: '650px' }}>
            <Paper>
              {searchResult.map((item) => (
                <Box onClick={() => { handleSearchClick(item.productName) }} key={item.id} sx={{ textAlign: 'left', p: 2, }}>
                  <Typography sx={{ cursor: 'pointer' }}>{item.productName}</Typography>
                </Box>
              ))}
            </Paper>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex',justifyContent:'space-between' }}>
          <Box onClick={handleProfileRedirect} sx={{ cursor: 'pointer', display: 'flex', maxWidth: { xs: 'auto', sm: '130px' }, height: '100%', maxHeight: '40px', borderRadius: '4px', border: `solid 1px ${secondaryColor}`, justifyContent: 'center', alignItems: 'center', padding: '3.5px 12.5px 4.5px 9.5px',margin:'0px 10px 0px 10.5px' }}>
            <IconButton size="medium">
              <Person2Icon sx={{ fontSize: '1.7rem', color: primaryColor, '&:hover': { backgroundColor: '#fff' } }} />
            </IconButton>
            <Typography sx={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: primaryColor, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>{isLoggedIn ? `Hi , ${firstName}` : 'Account'}</Typography>
          </Box>
          <Box onClick={() => navigate('/cart')} sx={{ display: 'flex', maxWidth: { xs: 'auto', sm: '130px' }, height: '100%', maxHeight: '40px', borderRadius: '4px', border: `solid 1px ${secondaryColor}`, justifyContent: 'center', alignItems: 'center', padding: '3.5px 14.5px 4.5px 13.5px' }}>
            <IconButton size="large" sx={{ '& .badge-container': { position: 'absolute', top: '5px', right: '0px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: `solid 1px ${primaryColor}` } }}>
              <ShoppingCartIcon sx={{ fontSize: '1.7rem', color: primaryColor }} />
              <div className="badge-container">
                <Typography component="sup" style={{ color: primaryColor, fontWeight: 'bold', padding: '10px 0px', fontSize: '14px' }}>{cart?.length}</Typography>
              </div>
            </IconButton>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'left', color: primaryColor, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>Cart</Typography>
          </Box>
          <IconButton size="large" color="inherit" aria-label="menu" sx={{ maxWidth: { xs: 'auto', sm: '130px' }, height: '100%', maxHeight: '48px', borderRadius: '4px', justifyContent: 'center', alignItems: 'center', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }} onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: primaryColor }} />
            <nav>
              <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }} sx={{ display: { xs: 'block', sm: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}>
                {drawer}
              </Drawer>
            </nav>
          </IconButton>
        </Box>

      </Box>
      <SearchBarMobile />
    </Box>
  );
};

export default SearchNavBar;
