import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import { HttpRequest } from "../../HttpRequest";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DateFormatter, { currencyconverter } from "../../../utils/dateFormatter";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import OrderStatus from "../../Orders/orderStatus";

const MyCustomerVouchers = () => {
    let customerId = secureLocalStorage.getItem('customerId');
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, });
    const [activeTab, setActiveTab] = useState('');

    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    const handleTableChange = (tabValue) => {
        setActiveTab(tabValue);
    }
    const getCustomerVouchers = () => {
        setPageState({ ...pageState, isLoading: true });
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommercecustomerorder?customerId=${customerId}&page=${pageState.page}&limit=${pageState.pageSize}`
        }).then((data) => {
            if (data?.status === 200) {

                const ascOrders = data?.response.customerEvouchers;
                setPageState({ ...pageState, isLoading: false, data: ascOrders, total: data?.response?.vouchersCount, });
            } else {
                setPageState({ ...pageState, isLoading: false })
            }
        }).catch((error) => {
        })
    }
    useEffect(() => {
        getCustomerVouchers();
    }, [customerId, dataGridPageSize, pageState.page,activeTab])

    const columns = [
        {
            field: "voucherNumber", headerName: "Voucher N0.", flex: 0.5,
            renderHeader: (params) => { return (<Box sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Voucher No.</Box>) },
            renderCell: (params) => {
                return <Typography sx={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: bodyColor, alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px',cursor:'pointer' }}>{params?.row?.voucherNumber}</Typography>
            }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 0.5,
            renderHeader: (params) => { return (<Box sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Amount</Box>) },
            renderCell: (params) => {
                return <Typography sx={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: bodyColor, alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{currencyconverter(params?.row?.amount)}</Typography>
            }
        },
        !isSmallScreen && {
            field: 'campaignName', headerName: 'Campaign Name', flex: 1,
            renderHeader: (params) => { return (<Box sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Campaign Name</Box>) },
            renderCell: (params) => {
                return <Typography sx={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: bodyColor, alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{params?.row?.campaignName}</Typography>
            }
        },
        {
            field: "status", headerName: "Status", flex: 1,
            renderHeader: (params) => { return (<Box sx={{ color: headingsColor, fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Status</Box>) },
            renderCell: (params) => {
                return <Box>
                    <Typography  sx={{fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: params?.row.status === 'ACTIVE' ? '#17ae7b':'#3da2f8', alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{params?.row?.status}</Typography>
                </Box>
            }
        }
    ].filter(Boolean);

    return (

        <Box sx={{ width: '100%' }}>
            <Typography sx={{ margin: '0 0 16px',fontSize: '16px', fontWeight: 600, textAlign: 'left', color:headingsColor  }}>My Vouchers</Typography>
            <DataGrid sx={{ maxHeight: '500px', height: '100%', width: '100%', maxWidth: '676px',fontSize: '12px', color: bodyColor, boxShadow: 0, border: 0,overflowX:'auto' }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: dataGridPageSize,
                            page: pageState.page - 1,
                        },
                    },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                    const { pageSize, page } = data;
                    setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                    setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
            />
        </Box>
    )
}

export { MyCustomerVouchers }