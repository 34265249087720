import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    productTypeData:' ',
    productName: ' ',
    productCategory: ' ',
    productDescription: ' ',
    productIsPackage:false,
    maxNoOfItems:0,
    featuredImage: ' ',
    thumbnail: ' ',
    additionalImages: [],
    variations: [],
    pricingData: [],
    tagsData:[],
    additionalServicesData:[]
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductTypeData: (state, action) => {
            state.productTypeData = action.payload;
        },
        setProductName: (state, action) => {
            state.productName = action.payload;
        },
        setProductCategory: (state, action) => {
            state.productCategory = action.payload;
        },
        setProductDescription: (state, action) => {
            state.productDescription = action.payload;
        },
        setProductIsPackage: (state, action) => {
            state.productIsPackage = action.payload;
        },
        setMaxNoOfItems: (state, action) => {
            state.maxNoOfItems = action.payload;
        },
        setFeaturedImage: (state, action) => {
            state.featuredImage = action.payload;
        },
        setThumbnailImage: (state, action) => {
            state.thumbnail = action.payload;
        },
        setAdditionalImages: (state, action) => {
            state.additionalImages = action.payload;
        },
        setVariations: (state, action) => {
            state.variations = action.payload;
        },
        setPricingData: (state, action) => {
            state.pricingData = action.payload;
        },
        setTagsData:(state,action)=>{
            state.tagsData = action.payload;
        },
        setAdditionalServicesData:(state,action)=>{
            state.additionalServicesData = action.payload;
        }
    }
});


export const {setProductTypeData,setProductName, setProductCategory, setProductDescription,setProductIsPackage,setMaxNoOfItems, setFeaturedImage,setThumbnailImage, setAdditionalImages, setVariations, setPricingData,setTagsData,setAdditionalServicesData} = productSlice.actions;
export default productSlice.reducer;


